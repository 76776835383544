import React, { useState } from "react";
import {
  FormControlLabel,
  Checkbox,
  Grid,
  Button,
  makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import StoreSelector from "./StoreSelector";
import LocationSelector from "./LocationSelector";
import {
  useGetStoresByLocation,
  useGetVerifiedStores,
} from "../hooks/use-fetch";
import useMount from "../hooks/use-mount";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
  checkbox: {
    color: "rgba(255, 255, 255, 0.74)",
    fontSize: "12px",
    "& .grey": {
      color: "rgba(255, 255, 255, 0.74)",
    },
  },
  inputContainer: {
    maxWidth: "330px",
    flex: 2,
    marginRight: "15px",
    "@media (max-width: 1200px)": {
      width: "100%",
      maxWidth: "unset",
      marginRight: "0px",
      marginBottom: "15px",
    },
  },
  allInputs: {
    display: "flex",
    alignItems: "flex-start",
    "@media (max-width: 1200px)": {
      flexDirection: "column",
    },
  },
  flexButton: {
    flex: 1,
    padding: "14px 22px",
    "@media (max-width: 1200px)": {
      width: "100%",
    },
  },
}));

export const emptySearch = {
  location: {
    remember: false,
    data: "",
  },
  stores: {
    remember: false,
    data: [],
  },
};

export default function SearchStoresForm({
  onClick,
  defaultSearch = emptySearch,
}) {
  const [search, setSearch] = useState(defaultSearch);
  const [stores, setStores] = useState([]);
  const [
    { loading: storesLoading, data: storesByLocation },
    getStores,
  ] = useGetStoresByLocation();
  const [
    { data: allStores, loading: allStoresLoading },
    getAllStores,
  ] = useGetVerifiedStores();
  const classes = useStyles();
  const history = useHistory();

  useMount(() => {
    if (defaultSearch.location.data) {
      const { lat, lon } = defaultSearch.location.data;
      getStores({ params: { lat, long: lon } });
    } else {
      getAllStores();
    }
  });

  const handleStoresChange = (data) => {
    setSearch({ ...search, stores: { ...search.stores, data } });
  };

  const handleStoresRemember = (e) => {
    setSearch({
      ...search,
      stores: { ...search.stores, remember: e.target.checked },
    });
  };

  const handleLocationRemember = (e) => {
    setSearch({
      ...search,
      location: { ...search.location, remember: e.target.checked },
    });
  };

  const handleLocationChange = (data) => {
    setSearch({
      stores: { ...search.stores, data: [] },
      location: { ...search.location, data },
    });
    if (data) {
      const { lat, lon } = data;
      getStores({ params: { lat, long: lon } });
    } else {
      getAllStores();
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (onClick) {
      const searchObj = {
        ...search,
        stores: {
          ...search.stores,
          data: search.stores.data.filter((store) => store.store_id !== "-1"),
        },
      };
      onClick(searchObj);
    } else {
      history.push("/search", search);
    }
  };

  useEffect(() => {
    if (!allStoresLoading && !storesLoading) {
      if (search.location.data) {
        setStores(storesByLocation);
      } else {
        setStores(allStores);
      }
    }
  }, [
    search.location.data,
    allStoresLoading,
    storesLoading,
    allStores,
    storesByLocation,
  ]);

  return (
    <form onSubmit={handleFormSubmit}>
      <Grid container spacing={2} justify="center" alignItems="flex-start">
        <Grid item className={classes.allInputs} xs={12} md={9}>
          <div className={classes.inputContainer}>
            <LocationSelector
              value={search.location.data}
              onChange={handleLocationChange}
            />
            <FormControlLabel
              className={classes.checkbox}
              value="rememberLocation"
              control={
                <Checkbox
                  onChange={handleLocationRemember}
                  value={search.location.remember}
                  className="grey"
                />
              }
              label="Remember this"
              labelPlacement="end"
            />
          </div>
          <div className={classes.inputContainer}>
            <StoreSelector
              loading={storesLoading || allStoresLoading}
              options={[
                {
                  name: "All businesses",
                  store_id: "-1",
                },
              ].concat(stores)}
              values={search.stores.data}
              onChange={handleStoresChange}
            />
            <FormControlLabel
              className={classes.checkbox}
              value="rememberStore"
              control={
                <Checkbox
                  onChange={handleStoresRemember}
                  value={search.stores.remember}
                  className="grey"
                />
              }
              label="Remember this"
              labelPlacement="end"
            />
          </div>
          <Button
            className={classes.flexButton}
            variant="contained"
            size="large"
            color="primary"
            type="submit"
          >
            Check Availability
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
