import useMount from "./use-mount"
import { MANAGER_BASE_URL, API_VERSION, CUSTOMER_BASE_URL } from "./use-fetch"

export default function useSession(setUser) {
  useMount(() => {
    async function checkUser() {
      const [AccessToken, RefreshToken, keepMeLoggedIn, username, userType] = [
        window.localStorage.getItem('AccessToken'),
        window.localStorage.getItem('RefreshToken'),
        window.localStorage.getItem('keepMeLoggedIn'),
        window.localStorage.getItem('username'),
        window.localStorage.getItem('userType')
      ]

      if (keepMeLoggedIn && AccessToken && RefreshToken && username) {
        const params = {
          AccessToken,
          RefreshToken,
          Username: username
        }

        const store = userType === "store"
        const endpoint = store ? `${MANAGER_BASE_URL}/${API_VERSION}/manager/check` : `${CUSTOMER_BASE_URL}/${API_VERSION}/customer/check`
        const res = await fetch(endpoint, {
          method: "POST",
          body: JSON.stringify(params)
        })
        .then((blob) => blob.json())
        .catch((e) => {
          console.log(e)
        });

        if (res && res.sessionResponse && res.sessionResponse.newAccessToken) {
          setUser({ ...params, AccessToken: res.sessionResponse.newAccessToken, store })
        } else if (res && res.sessionResponse && res.sessionResponse.message === "ok") {
          setUser({ ...params, store })
        }
      }
    }

    checkUser()
  })
}
