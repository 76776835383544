import * as React from 'react';
import { ReservationsContext } from '../../context/ReservationsContext';
import { Grid, makeStyles, Container, Paper, Typography, Avatar, Button, CircularProgress } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { ReservationItem } from './ReservationItem';
import { NavBar } from '../../components/NavBar';
import { Instructions } from '../../components/Church/instructions';
import { CurrentUserContext } from '../../context/CurrentUserContext';
import { Link } from 'react-router-dom';

const useClasses = makeStyles(theme => ({
    booking: {
        borderBottom: `1px solid ${grey[300]}`,
        marginBottom: 10
    },
    header: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#7FDCEB',
        fontSize: 48,
        height: 120
    },
    subheader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#E0F6FA',
        alignItems: 'center',
        fontSize: 34,
        height: 80
    },
    loginRequired: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 34,
        height: 300,
        marginBottom: 10
    },
    logo: {
        height: 100,
        width: 100
    }
}))
export const Reservations = () => {
    const bookingService = React.useContext(ReservationsContext);
    const classes = useClasses();
    const auth = React.useContext(CurrentUserContext);

    return (
        <>
            <NavBar context='reservations' />
            <Paper className={classes.header} elevation={2} square>
                {!!bookingService.how.loaded && !!bookingService.how.data ?
                    <Link to={`/church/${bookingService.how.data.profile_name}`}>
                        <Avatar className={classes.logo} src={bookingService.how.data.logo} />
                    </Link>

                    : <Typography variant='h4'>Reservations</Typography>
                }
            </Paper>
            {auth && auth.profile && <Paper className={classes.subheader} elevation={2} square>
                <Typography variant='h4'>{auth.profile.name}</Typography>
            </Paper>}
            {!!auth.user ? (<section>
                { bookingService.initializing ? <CircularProgress/> : <Container maxWidth='xs'>

                    <Grid container spacing={2}>
                        {
                            bookingService.reservations.length > 0 ? bookingService.reservations.map(reservation => (
                                <Grid item xs={12} key={reservation.booking_id} className={classes.booking}>
                                    <ReservationItem reservation={reservation} />
                                </Grid>
                            )) :

                                <Typography variant="h4" color="primary" style={{ textAlign: 'center' }}>
                                    There are no reservations.
                                </Typography>
                        }
                    </Grid>
                    </Container> }
            </section>) : <Paper component="section" elevation={4} className={classes.loginRequired}>
                    <Container maxWidth="md">
                        <Typography>You need to login to view your reservations</Typography>
                        <Button color='primary' variant='outlined' href='/login'>Login</Button>
                    </Container>
                </Paper>}
            <Instructions />
        </>
    )
}