import React, {  } from "react";
import {
  makeStyles,
} from "@material-ui/core";
import { ProfileEditForm } from "./ProfileTabForm";


const useStyles = makeStyles((theme) => ({
  profile: {
    "& .MuiFormControl-root.MuiTextField-root": {
      marginBottom: 16,
    },
    "& .MuiInputBase-root.MuiFilledInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl": {
      backgroundColor: "rgb(255, 255, 255)",
      borderRadius: 4,
      border: "1px solid transparent",
      "&:focus-within": {
        borderColor: theme.palette.secondary.main,
      },
      "&.MuiInputBase-root.Mui-disabled": {
        color: "currentColor",
        cursor: "not-allowed",
      },
      "& input": {
        padding: "16px 16px 19px",
      },
    },
    "& .MuiInputBase-root.MuiFilledInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl.Mui-disabled": {
      backgroundColor: "rgba(255, 255, 255, 0.4)",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiChip-root.Mui-disabled": {
      opacity: 1,
    },
    "& .centered-spinner": {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .MuiAlert-root": {
      marginTop: 20,
    },
  },
}));

export default function ProfileTab() {
  const classes = useStyles();

  return (
    <section className={classes.profile}>
      <ProfileEditForm/>
    </section>
  );
}
