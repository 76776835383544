import React, { useState, useEffect, useContext } from "react";
import {
  makeStyles,
  Typography,
  Box,
  Button,
  Container,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import Header from "../components/Header";
import CustomerLoginDialog from "../components/CustomerLoginDialog";
import useHandlePhone from "../hooks/use-handlephone";
import { useCreateCustomer, useSendEmailVerification } from "../hooks/use-fetch";
import { UserContext } from "../context/UserContext"
import AccessibleIcon from '@material-ui/icons/Accessible';
import { useRouteParam } from "../hooks/query";
import { setHOW, loadHOW } from '../helpers/localcache';

const useStyles = makeStyles((theme) => ({
  signup: {
    "& .top-section": {
      paddingBottom: 38,
      paddingTop: 31,
      "& > div": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
    },
    "& .flex": {
      display: "flex",
      "&.align-center": {
        alignItems: "center",
      },
      "& .mb0": {
        marginBottom: "0px !important",
      },
      "& .mr5": {
        marginRight: 5,
      },
      "& .logo": {
        width: 100,
        height: 100,
        objectFit: "contain",
        marginRight: 40,
      },
      "&.columns": {
        flexDirection: "column",
        "& h5": {
          marginBottom: 15,
        },
        "& p": {
          marginTop: 0,
          marginBottom: 8,
        },
        "& .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1": {
          fontSize: 12,
        },
      },
    },
    "& p": {
      fontSize: 16,
    },
    "& .fs12": {
      fontSize: 12,
    },
    "& .uppercase": {
      textTransform: "uppercase",
    },
    "& .spacing156": {
      letterSpacing: "156%",
    },
    "& .m0": {
      margin: 0,
    },
    "& .mb35": {
      marginBottom: 35,
    },
    "& .mr16": {
      marginRight: 16,
    },
    "& .mb16": {
      marginBottom: 16,
    },
    "& .mt8": {
      marginTop: 8,
    },
    "& .mt12": {
      marginTop: 12,
    },
    "& .text-medium": {
      color: theme.palette.text.medium,
    },
    "& .form-container": {
      padding: "50px 0",
      backgroundColor: "#ffffff",
    },
    "& .light-blue": {
      backgroundColor: theme.palette.surface.lightBlue,
    },
    "& .fullwidth": {
      width: '100%',
    },
    "& .MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth": {
      marginBottom: 16,
    },
    "& .MuiInputBase-root.MuiFilledInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl": {
      backgroundColor: "#FFFFFF",
      borderRadius: 4,
      border: "1px solid transparent",
      "& input": {
        padding: "16px 16px 19px",
      },
      "&:focus-within": {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  titleContainer: {
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.light,
    paddingTop: 32,
    paddingBottom: 32,
  }
}));

export default function Signup() {
  const classes = useStyles();
  const history = useHistory();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [phone, handlePhone] = useHandlePhone();
  const [senior, setSenior] = useState(false)
  const [
    { loading: signupLoading, error: signupError, data: signupData },
    signup,
  ] = useCreateCustomer();
  const [, sendEmail] = useSendEmailVerification()
  const [, setUser] = useContext(UserContext);
  const churchProfileName = useRouteParam(ps => ps.church);

  React.useEffect(() => {
    setHOW(churchProfileName);
  }, [churchProfileName]);

  useEffect(() => {
    if (!signupLoading && (signupData || signupError)) {
      setSnackbarOpen(true);
      setSnackbarMessage(
        signupError ? signupError.message : "Account created successfully"
      );
      setSnackbarSeverity(signupError ? "error" : "success");
    }
  }, [signupError, signupLoading, signupData]);

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (e.target.password.value !== e.target.confirmPassword.value) {
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setSnackbarMessage("Passwords do not match.");
      return;
    }

    const user = {
      Username: e.target.email.value,
      Password: e.target.password.value,
      name: `${e.target.firstName.value} ${e.target.lastName.value}`,
      phone_number: e.target.phone.value.split(" ").join(""),
      zip_code: e.target.zipCode.value,
      senior_citizen: senior,
      profile_name: churchProfileName || loadHOW()
    };

    const res = await signup({ params: user });

    if (!res.error) {
      await sendEmail({
        params: {
          email: user.Username,
          name: user.name
        }
      })

      history.push(`/verify`, { email: user.Username });
    }
  };

  const handleUserLogin = (data) => {
    setUser({ ...data })
    history.push('/reservation')
  }

  return (
    <>
      <Header>
        <Button onClick={() => history.goBack()} variant="outlined" color="primary">Cancel</Button>
      </Header>
      <main className={classes.signup}>
        <div className={classes.titleContainer}>
          <Container>
            <Typography variant="h3">
              Sign up
            </Typography>
          </Container>
        </div>
        <section className="form-container">
          <Snackbar autoHideDuration={6000} open={snackbarOpen}>
            <Alert
              onClose={() => setSnackbarOpen(false)}
              severity={snackbarSeverity}
              elevation={6}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
          <Container className="light-blue" maxWidth="md">
            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <form onSubmit={handleSignUp}>
                  <TextField
                    InputProps={{ disableUnderline: true }}
                    name="firstName"
                    fullWidth
                    variant="filled"
                    placeholder="First name*"
                    required
                  />
                  <TextField
                    InputProps={{ disableUnderline: true }}
                    name="lastName"
                    fullWidth
                    variant="filled"
                    placeholder="Last name*"
                    required
                  />
                  <TextField
                    InputProps={{ disableUnderline: true }}
                    name="email"
                    type="email"
                    fullWidth
                    variant="filled"
                    placeholder="Email*"
                    required
                  />
                  <TextField
                    InputProps={{ disableUnderline: true }}
                    name="phone"
                    type="phone"
                    value={phone}
                    onChange={handlePhone}
                    fullWidth
                    variant="filled"
                    placeholder="Mobile number*"
                    required
                  />
                  <TextField
                    InputProps={{ disableUnderline: true }}
                    name="zipCode"
                    type="number"
                    fullWidth
                    variant="filled"
                    placeholder="Zip Code (optional but this speeds up the search)"
                  />
                  <TextField
                    InputProps={{ disableUnderline: true }}
                    name="password"
                    type="password"
                    fullWidth
                    variant="filled"
                    placeholder="Create password*"
                    required
                  />
                  <TextField
                    InputProps={{ disableUnderline: true }}
                    name="confirmPassword"
                    type="password"
                    fullWidth
                    variant="filled"
                    placeholder="Re-type new password*"
                    required
                  />
                  <p className="text-medium">Password must contain:</p>
                  <p className="text-medium">- Minimum eight characters</p>
                  <p className="text-medium">- Upper and lowercase letter</p>
                  <p className="text-medium">
                    - One numeric and one special character (%#$&@?!)
                    </p>
                  <Button
                    type="submit"
                    className="fullwidth"
                    endIcon={
                      signupLoading && (
                        <CircularProgress
                          style={{ height: 20, width: 20, color: "#ffffff" }}
                        />
                      )
                    }
                    color="primary"
                    variant="contained"
                  >
                    Submit
                    </Button>
                </form>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid style={{ marginBottom: 20 }} container alignItems="center">
                  <Grid item xs={1} >
                    <AccessibleIcon />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography variant="h5">
                      <Box fontWeight="fontWeightLight" component="span">
                        Do you require extra time or assistance?
                        </Box>
                    </Typography>
                  </Grid>
                </Grid>
                <FormControlLabel
                  control={<Checkbox checked={senior} onChange={e => setSenior(e.target.checked)} name="seniorCitizen" />}
                  label="Check this box if you need assistance or additional time to enter and exit the premises. This will give you access to dedicated entry time slots designated to assist you with your needs."
                  labelPlacement="end"
                  className="mt8 mb16 text-medium"
                />
                <hr style={{ marginTop: 30, marginBottom: 30 }} />
                <div className="flex align-center mb35">
                  <Typography variant="h5" className="mr16">
                    <Box fontWeight="fontWeightLight" component="span">
                      Already signed up?
                      </Box>
                  </Typography>
                  <CustomerLoginDialog
                    label="log in"
                    variant="outlined"
                    color="primary"
                    onLogin={handleUserLogin}
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
      </main>
    </>
  );
}
