import * as React from "react";
import { ReservationsProvider } from "../../context/ReservationsContext";
import { Reservations } from "./Reservations";


export const ReservationPage = ({bookings}) => {
  return (
    <>
      <ReservationsProvider bookings={bookings}>
        <Reservations/>
      </ReservationsProvider>
    </>
  );
}
