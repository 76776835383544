import React, { useEffect, useState } from "react";
import { makeStyles, IconButton, Button } from "@material-ui/core";
import { CloudUpload as CloudUploadIcon } from "@material-ui/icons";
import { useSubmitImage } from "../hooks/use-fetch";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "flex",
    alignItems: "center",
    "& p": {
      fontSize: "16px",
      "&.bold": {
        fontWeight: "bold",
      },
      "&.image-name": {
        maxWidth: 150,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
    "& .icon-label": {
      height: "100px",
      width: "100px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      backgroundColor: "#ffffff",
      marginRight: 16,
      flexShrink: 0,
      cursor: "pointer",
      "&:hover": {
        "& button": {
          backgroundColor: theme.palette.secondary.main,
        },
      },
      "& .thumbnail": {
        position: "absolute",
        top: 0,
        left: 0,
        objectFit: "contain",
        objectPosition: "center",
        width: "100%",
        height: "100%",
        zIndex: 10,
      },
      "& button": {
        backgroundColor: theme.palette.secondary.light,
        color: "#000000",
        pointerEvents: "none",
      },
    },
    "& input": {
      position: "absolute",
      height: "1px",
      width: "1px",
      overflow: "hidden",
      clip: "rect(1px 1px 1px 1px)",
      whiteSpace: "nowrap",
    },
  },
}));

const ImageInput = ({ id, text, name, storeName, onChange, defaultValue }) => {
  const classes = useStyles();
  const [image, setImage] = useState(undefined);
  const [placeholderImage, setPlaceholderImage] = useState(defaultValue);
  const [, submitImage] = useSubmitImage(image);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    setImage(file);
    if (!!file && !!storeName) {
      const params = {
        store_name: storeName,
        contentType: file.type,
        filename: file.name,
      };

      const { data } = await submitImage({ params });
      onChange({ ...data, logo: file });
    }
  };

  const readFile = (file) => {
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      function () {
        setPlaceholderImage(reader.result);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    readFile(image);
  }, [image]);

  return (
    <div className={classes.input}>
      <label className="icon-label" htmlFor={id}>
        {!placeholderImage ? (
          <IconButton>
            <CloudUploadIcon />
          </IconButton>
        ) : (
          <img
            className="thumbnail"
            src={placeholderImage}
            alt={image && image.name}
          />
        )}
      </label>
      <div>
        <p className="bold image-name">{`${
          image && image.name ? image.name : text ? text : "Upload a store logo"
        }`}</p>
        <p>(100px x 100px recommended)</p>
      </div>
      <input
        type="file"
        accept="image/*"
        name={name}
        onChangeCapture={handleImageChange}
        id={id}
      />
      <Button variant="outlined" color="primary">
        <label htmlFor={id}>browse</label>
      </Button>
    </div>
  );
};

export default ImageInput;
