import React from "react";
import { makeStyles, Typography, Box, Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  whatHappens: {
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 255,
    textAlign: "center",
    "& h4": {
      marginBottom: 35,
    },
  },
}));

export default function WhatHappens() {
  const classes = useStyles();
  return (
    <section className={classes.whatHappens}>
      <Container>
        <Typography variant="h4">
          What happens if I don’t arrive within my 1 hour time slot?
        </Typography>
        <Typography variant="h5">
          <Box fontWeight="fontWeightLight">
            If you can’t make it in time: WE ARE BUMMED FOR YOU but alas, we
            can’t remedy this. Ask the door staff and they may let you in late –
            but this is at the store’s discretion. Otherwise, you can join the
            walk-in queue.
          </Box>
        </Typography>
      </Container>
    </section>
  );
}
