import * as React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler';
import {
    Scheduler,
    DayView,
    WeekView,
    MonthView,
    Appointments,
    EditRecurrenceMenu,
    AppointmentForm,
    ConfirmationDialog,
    AppointmentTooltip,
    Toolbar,
    ViewSwitcher,
    TodayButton,
    DateNavigator
} from '@devexpress/dx-react-scheduler-material-ui';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { PerfectlyDistancedAppointmentForm, EventTextEditor, CustomDateTimePicker, PerfectlyDistancedAppointmentFormCommands } from './event-form';
import { getAppointmentTooltipHeader } from './appointment-tooltip';
import PropTypes from 'prop-types';
import { Grid, Toolbar as MuiToolbar, Button, Hidden } from '@material-ui/core';
import moment from 'moment';
import classNames from 'classnames';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment'; import { RRule } from 'rrule';
import { grey } from '@material-ui/core/colors';
import { MakeReservation } from './MakeReservation';
import { ConfirmDeleteOrUpdate } from './BeforeDeleteOrUpdateConfirm';
import { SchedulerStore } from '../../context/SchedulerContext';

const style = (theme) => ({
    todayCell: {
        backgroundColor: fade(theme.palette.primary.main, 0.1),
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.14),
        },
        '&:focus': {
            backgroundColor: fade(theme.palette.primary.main, 0.16),
        },
    },
    weekendCell: {
        backgroundColor: fade(theme.palette.action.disabledBackground, 0.04),
        '&:hover': {
            backgroundColor: fade(theme.palette.action.disabledBackground, 0.04),
        },
        '&:focus': {
            backgroundColor: fade(theme.palette.action.disabledBackground, 0.04),
        },
    },
    today: {
        backgroundColor: fade(theme.palette.primary.main, 0.16),
    },
    weekend: {
        backgroundColor: fade(theme.palette.action.disabledBackground, 0.06),
    },
});

const TimeTableCellBase = ({ classes, ...restProps }) => {
    const { startDate } = restProps;
    const date = new Date(startDate);
    const render = () => {
        if (date.getDate() === new Date().getDate()) {
            return <WeekView.TimeTableCell {...restProps} className={classes.todayCell} />;
        } if (date.getDay() === 0 || date.getDay() === 6) {
            return <WeekView.TimeTableCell {...restProps} className={classes.weekendCell} />;

        } return <WeekView.TimeTableCell {...restProps} />;
    }

    return render();
};

const TimeTableCell = withStyles(style, { name: 'TimeTableCell' })(TimeTableCellBase);

const DayScaleCellBase = ({ classes, ...restProps }) => {
    const { startDate, today } = restProps;
    if (today) {
        return <WeekView.DayScaleCell {...restProps} className={classes.today} />;
    } if (startDate.getDay() === 0 || startDate.getDay() === 6) {
        return <WeekView.DayScaleCell {...restProps} className={classes.weekend} />;
    } return <WeekView.DayScaleCell {...restProps} />;
};

const DayScaleCell = withStyles(style, { name: 'DayScaleCell' })(DayScaleCellBase);

const schedulerStyle = (theme) => createStyles({
    root: theme.mixins.gutters({}),
    sidebar: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 200,
    },
    toolbar: {
        width: '100%',
        borderBottom: `1px solid ${grey[300]}`,
        paddingLeft: 20,
        '&.create': {
            height: 57,
            display: 'flex',
            alignItems: 'center',
        }
    },
    createButton: {
        color: '#FFF',
        backgroundColor: theme.palette.secondary.main
    }
});


const PerfectlyDistancedScheduler = withStyles(schedulerStyle, { withTheme: true })(({ store, classes, readonly }) => {
    const [editingAppointment, setEditingAppointment] = React.useState(undefined);
    const [addedAppointment, setAddedAppointment] = React.useState(undefined);
    const [appointmentChanges, setAppointmentChanges] = React.useState(undefined);
    const [snackBar, setSnackBar] = React.useState({});
    const [date, setDate] = React.useState(moment())
    const [edit, setEdit] = React.useState(false);
    const [reservationEvent, setReservationEvent] = React.useState();
    const [confirmDelete, setConfirmDelete] = React.useState();
    const [externalCreate, setExternalCreate] = React.useState(false);
    const schedule = React.useContext(SchedulerStore);
    React.useEffect(() => setEdit(externalCreate), [externalCreate]);

    React.useEffect(() => {
        if (!edit) {
            setExternalCreate(false);
            setAddedAppointment(undefined);
            setEditingAppointment(undefined);
            setAppointmentChanges(undefined);
        }
    }, [edit]);

    const changeAddedAppointment = (addedAppointment) => {
        if (!addedAppointment.status) {
            addedAppointment.status = 'inactive';
        }

        setAddedAppointment(addedAppointment);
    }

    const changeAppointmentChanges = (appointmentChanges) => {
        setAppointmentChanges(appointmentChanges);
    }

    const changeEditingAppointmentId = (editingAppointmentId) => {
        setEditingAppointment(editingAppointmentId);
    }

    const showErrorMessage = (message) => {
        setSnackBar({ open: true, error: true, message });
    }

    const toApiModel = (input) => {
        input.store_id = Number(store.store_id);
        input.name = input.title;
        input.all_day = input.allDay;
        input.startDate = moment(input.startDate).format("YYYY-MM-DDTHH:mm:ss");
        input.endDate = moment(input.endDate).format("YYYY-MM-DDTHH:mm:ss");

        if (input.rRule) {
            input.rRuleObject = RRule.parseString(input.rRule);
        }

        return input;
    }

    const beforeDeleteOrUpdate = async (id, date, message, onConfirm) => {
        let confirm = await schedule.hasBookings(id, date);

        if (confirm) {
            setConfirmDelete({
                id, onConfirm: async () => {
                    await onConfirm();
                    setConfirmDelete(undefined);
                }, message
            });
        } else {
            await onConfirm();
        }
    }

    const transformAdded = (event) => {
        const params = toApiModel(event.added);
        if (event.added.event_id) { // event added as a result of updating a recurring series
            params.original_event_id = event.added.event_id

            if (event.changed[event.added.event_id].exDate) {
                params.original_start_date = event.changed[event.added.event_id].exDate;
            }
        }
        params.status = 'active';

        return params;
    }

    const transformChanged = (event) => {
        const updatedId = Object.keys(event.changed).map(Number)[0];
        let original = schedule.events.find(x => x.id === updatedId);

        const changes = event.changed[original.event_id];

        const exDateChanged = !!event.changed[updatedId].exDate && original.exDate !== event.changed[updatedId].exDate;
        original = { ...original, ...changes };
        const noApptAdded = !event.added;
        if (!!original.rRule) {
            original.is_delete = (noApptAdded && exDateChanged);
        }
        original.status = 'active';
        return toApiModel(original);
    }

    const getDeletingOccurenceDate = (event) => {
        const updatedId = Object.keys(event.changed).map(Number)[0];
        let original = schedule.events.find(x => x.id === updatedId);
        const originalExDate = (original.exDate || '').split(',')
        const newExDate = (event.changed[original.event_id].exDate || '').split(',');
        const skipDate = newExDate.find(x => originalExDate.indexOf(x) === -1);
        return skipDate;
    }

    const commitChanges = async (event) => {
        try {
            if (event.added && !event.changed) {
                const proceed = async () => {
                    const params = transformAdded(event)
                    schedule.create(params);
                }
                if (!event.added.id) {
                    await proceed();
                } else {
                    const exDate = event.changed[event.added.id].exDate;
                    beforeDeleteOrUpdate(event.added.id, moment(exDate).toDate(), 'Are you that you want to update this event?', proceed);
                }
            }

            if (event.changed && !event.added) {
                const params = transformChanged(event);
                if (params.is_delete) {
                    const skipDate = getDeletingOccurenceDate(event);
                    if (skipDate) {
                        beforeDeleteOrUpdate(params.event_id, moment(skipDate).format('YYYY-MM-DD'), 
                        'Are you sure that you want to deleted this event', async () => {
                            await schedule.update(params);
                        } )
                    } else {
                        await schedule.update(params);
                    }
                } else {
                    await schedule.update(params);
                }
            }

            if (event.changed && event.added) {
                await schedule.updateOneOccurence(transformAdded(event), transformChanged(event))
            }

            if (event.deleted !== undefined) {
                const original = schedule.events.find(x => x.id === Number(event.deleted));
                if (original) {
                    beforeDeleteOrUpdate(original.id, null, 'Are you sure that you want to deleted this event', async () => {
                        await schedule.cancel(event.deleted);
                    })
                }
            }


        } catch (error) {
            showErrorMessage('Ooooops!!, failed to update calendar. Check your schedule and try again.');
        }
    }

    const onCurrentDateChange = (value) => {
        setDate(moment(value));
    }

    const handleOpenFullscreen = (data) => {
        setReservationEvent(data);
    }

    const setAddedEvent = () => {
        setExternalCreate(true);
    }

    const onAppointmentFormVisibilityChange = visibility => {
        setEdit(visibility);
    }

    const renderSheduler = () => (<Scheduler data={schedule.events} height={660}>
        <ViewState defaultCurrentViewName='Week' currentDate={date.toDate()} onCurrentDateChange={onCurrentDateChange} />
        {!readonly && <EditingState
            onCommitChanges={commitChanges}
            addedAppointment={addedAppointment}
            onAddedAppointmentChange={changeAddedAppointment}
            appointmentChanges={appointmentChanges}
            onAppointmentChangesChange={changeAppointmentChanges}
            editingAppointment={editingAppointment}
            onEditingAppointmentChange={changeEditingAppointmentId}
        />
        }
        <WeekView startDayHour={5} endDayHour={23} timeTableCellComponent={TimeTableCell} dayScaleCellComponent={DayScaleCell} />
        <DayView startDayHour={5} endDayHour={23} />
        <MonthView />
        <Toolbar />
        <DateNavigator />
        <TodayButton />
        <ViewSwitcher />
        {!readonly && <EditRecurrenceMenu messages={{ menuDeletingTitle: 'Deleting a recurring event', menuEditingTitle: 'Editing a recurring event' }} layoutComponent={PDRecurrenceMenuLayout} />}
        {!readonly && <ConfirmationDialog
            messages={{
                confirmDeleteMessage: 'Are you sure you want to delete this event?'
            }}
        />}
        <Appointments />
        <AppointmentTooltip showOpenButton={!readonly} showDeleteButton={!readonly} headerComponent={getAppointmentTooltipHeader(handleOpenFullscreen, readonly)} />
        {!readonly && <AppointmentForm
            appointmentData={externalCreate ? {} : addedAppointment || editingAppointment}
            messages={{ moreInformationLabel: '', commitCommand: 'Publish' }}
            dateEditorComponent={CustomDateTimePicker}
            onVisibilityChange={onAppointmentFormVisibilityChange}
            visible={edit || externalCreate}
            commandLayoutComponent={PerfectlyDistancedAppointmentFormCommands}
            basicLayoutComponent={PerfectlyDistancedAppointmentForm}
            textEditorComponent={EventTextEditor} />}
    </Scheduler>);

    return (

        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid container>
                {!!confirmDelete && <ConfirmDeleteOrUpdate {...confirmDelete} open={!!confirmDelete} onClose={() => setConfirmDelete(undefined)} />}
                {!!reservationEvent && <MakeReservation store={store} open={!!reservationEvent} onClose={() => setReservationEvent(undefined)} event={reservationEvent} />}
                <Grid item xs={12} md={edit ? 12 : 9} xl={edit ? 12 : 10}>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={snackBar.open}
                        onClose={() => setSnackBar({})}
                        autoHideDuration={1000}
                        message={snackBar.message}
                    />
                    {renderSheduler()}
                </Grid>
                {!edit &&
                    <Hidden smDown>

                        <Grid item xs={4} md={3} xl={2} className={classes.sidebar}>
                            <MuiToolbar className={classes.toolbar}>
                                {false && <Button size='small' color='primary' variant='contained'>Capacity control</Button>}
                            </MuiToolbar>
                            {!readonly && <div className={classNames(classes.toolbar, 'create')}>
                                <Button onClick={setAddedEvent} className={classes.createButton} color='secondary' variant='contained'>+ Create new event</Button>
                            </div>}
                            <DatePicker
                                autoOk
                                orientation="portrait"
                                variant="static"
                                openTo="date"
                                disableToolbar
                                value={date}
                                onChange={setDate}
                            />
                        </Grid>
                    </Hidden>}
            </Grid>
        </MuiPickersUtilsProvider>
    );
});


const PDRecurrenceMenuLayout = (props) => {
    return <EditRecurrenceMenu.Layout {...props} availableOperations={[{ value: 'current', title: 'This event' }, { value: 'all', title: 'All events' }]} />

}

PerfectlyDistancedScheduler.propTypes = {
    readonly: PropTypes.bool,
    wrapper: PropTypes.any,
    toggleSidebar: PropTypes.func,
}

export default PerfectlyDistancedScheduler;
