import * as React from 'react';
import { Formik, Form, Field } from 'formik';
import { CurrentUserContext } from '../../context/CurrentUserContext';
import { Grid, Button, CircularProgress, Snackbar } from '@material-ui/core';
import * as Yup from 'yup';
import { FullWidthTextField, CheckboxField } from '../../components/common/FormikFields';
import { useLoginCustomer, useLoginManager } from '../../hooks/use-fetch';
import Alert from "@material-ui/lab/Alert";
import { setHOW, loadHOW } from "../../helpers/localcache";
import { useHistory } from 'react-router-dom';
import { useRouteParam } from '../../hooks/query';
import { store } from '../../helpers/use-context';

export const LoginForm = () => {
    const [, customerLogin] = useLoginCustomer();
    const [, managerLogin] = useLoginManager();
    const [snackbar, setSnackbar] = React.useState({})
    const history = useHistory();
    const context = useRouteParam(x => x.context);
    const forgotPasswordUrl = React.useMemo(() => {
        return context === store ? '/store/forgot-password' : '/customer/forgot-password';
    }, [context]);

    const login = async (params) => {
        if (context === store) {
            return await managerLogin({params});
        }

        return await customerLogin({params});
    }
    const handleLogin = async (onLogin, values) => {
        const params = {
            Username: values.email,
            Password: values.password,
        };

        const {loading, data, error} = await login(params);

        if (!loading && !error) {
            window.localStorage.setItem("AccessToken", data.AccessToken);
            window.localStorage.setItem("RefreshToken", data.RefreshToken);
            window.localStorage.setItem("username", values.email);
            window.localStorage.setItem("keepMeLoggedIn", values.keepMeLoggedIn);
            window.localStorage.setItem("userType", "customer");

            if (context !== store) {
                const profileName = !!data.profile_name ? data.profile_name : loadHOW();
                if (profileName) {
                    setHOW(profileName);
                    if (onLogin) {
                        onLogin({ ...data, username: values.email, store: false });
                    }
                } else {
                    history.replace(`/reservation`);
                }
            } else {
                history.replace('/space/details')
            }

        } else {
            setSnackbar({
                open: true,
                severity: "error",
                message: error.ErrorMessage
                    ? error.ErrorMessage
                    : error.message,
            });
        }
    }

    return (
        <>
            <CurrentUserContext.Consumer>
                {({ login }) => (< Formik
                    initialValues={{
                        email: '',
                        password: '',
                        keepMeLoggedIn: true
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email().required('Email address is required'),
                        password: Yup.string().required('Password is required'),
                    })}
                    onSubmit={async (values) => await handleLogin(login, values)}
                >
                    {({ submitForm, isSubmitting, isValid, dirty }) => (

                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Field
                                        component={FullWidthTextField}
                                        name='email'
                                        type='email'
                                        label='Email Address'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        component={FullWidthTextField}
                                        name='password'
                                        type='password'
                                        label='Password'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        component={CheckboxField}
                                        name='keepMeLoggedIn'
                                        label='Keep me logged in'
                                        type='checkbox'
                                    />

                                </Grid>
                                <Grid item xs={12}>
                                    <Button disabled={!isValid} variant='contained' color='primary' type='submit' fullWidth>
                                        Login&nbsp; {isSubmitting && <CircularProgress size={20} />}
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant='text' color='primary' href={forgotPasswordUrl} fullWidth>
                                        Forgot your password?
                                </Button>
                                </Grid>

                            </Grid>

                        </Form>)}

                </Formik>)

                }

            </CurrentUserContext.Consumer >
            <Snackbar autoHideDuration={6000} onClose={() => setSnackbar({})} open={snackbar.open}>
                <Alert onClose={() => setSnackbar({})} variant="filled" severity={snackbar.severity} elevation={6}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
}