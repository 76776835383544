import * as React from 'react';
import { ReservationsContext } from '../../context/ReservationsContext';
import { Grid, Typography, Button } from '@material-ui/core';
import AccessibleIcon from '@material-ui/icons/Accessible';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import {range} from 'lodash';

export const HeadCount = ({ value, need_assistance }) => {
    return (
        <>
            {
                range(0, value).map(i => {
                    return need_assistance ? <AccessibleIcon key={i} /> : <AccessibilityIcon key={i} />
                })
            }
        </>
    );
}
export const ReservationItem = ({ reservation }) => {
    const service = React.useContext(ReservationsContext);

    return (
        <Grid container spacing={2}>
            <Grid item align='center' xs={12}>
                <Typography variant='h5'>
                    {reservation.event_name}
                </Typography>
            </Grid>
            <Grid item align='center' xs={12}>
                <Typography variant='h6'>
                    {`${reservation.date}, ${reservation.start_time} - ${reservation.end_time}`}
                </Typography>
            </Grid>
            <Grid item align='center' xs={12}>
                <HeadCount value={reservation.head_count} need_assistance={reservation.need_assistance} />
            </Grid>
            <Grid item align='center' xs={12}>
                <Typography variant='body2'>
                    Notify us when you have arrived and are ready to enter:
                </Typography>
            </Grid>
            <Grid item align='center' xs={12}>
                {
                    !reservation.arrived
                        ?
                        <Button disabled={!reservation.canNotify} onClick={() => service.notifyArrived(reservation.booking_id)} color="primary" variant='contained'>I have arrived</Button>
                        : reservation.notified ?
                            <Button startIcon={<NotificationsActiveIcon />} color="primary" variant="text">Approach now</Button>
                            :
                            <Button startIcon={<DriveEtaIcon />} color="secondary" variant="text">Please wait</Button>
                }
            </Grid>
            <Grid item align='center' xs={12}>
                <Typography variant='body2'>
                    We will alert you when you can safely approach. Show this QR code to the usher at the door.
                </Typography>
            </Grid>
            <Grid item align='center' xs={12}>
                <img src={reservation.qr_check_in} alt="Check In" />
            </Grid>
            <Grid item align='center' xs={12}>
                <Button color='primary' variant='outlined' onClick={() => service.cancel(reservation.booking_id)} >Cancel Reservation</Button>
            </Grid>
        </Grid>
    );
}