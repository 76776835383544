import { API_VERSION, EVENTS_BASE_URL } from "../../hooks/use-fetch";
import axios from 'axios';

export const loadChurchBeforeRouteEnter = async (to, from, next) => {
    const { id: churchName } = to.match.params;
    try {
      const {data} = await axios.get(`${EVENTS_BASE_URL}/${API_VERSION}/event/storeinfo/${churchName}`);
      next.props({
        church: data
      });
    } catch {
      throw new Error('Church does not exist.');
    }
  };