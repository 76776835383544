import * as React from 'react';
import { loadHOW } from '../helpers/localcache';

export const HOWContext = React.createContext(loadHOW());

export const HOWProvider = ({children}) => {
    const [how, setHow] = React.useState(loadHOW());

    React.useEffect(() => {
        const interval = setInterval(() => {
            const how = loadHOW();
            setHow(how)
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <HOWContext.Provider value={{how}}>
            {children}
        </HOWContext.Provider>
    );
}
