import * as React from 'react';
import { Formik, Field, Form } from "formik";
import { useContext } from "react";
import * as Yup from 'yup';
import { Grid, Button, Typography, Container, TextField, CircularProgress, Snackbar } from "@material-ui/core";
import { FullWidthTextField } from "../common/FormikFields";
import Alert from "@material-ui/lab/Alert";
import { ManagerContext } from '../../context/ManagerContext';

export const ProfileEditForm = () => {
    const [readonly, setReadonly] = React.useState(true);
    const [snackbar, setSnackbar] = React.useState({})
    const auth = useContext(ManagerContext);

    return (
        <Container maxWidth="xs">
            { auth.profileLoading || !auth.profile ? <CircularProgress/> : <>
                <Typography variant="h4">{auth.profile && auth.profile.name}</Typography>
                <TextField
                    defaultValue={auth.profile.email}
                    name="email"
                    fullWidth
                    variant="outlined"
                    required
                    disabled
                    style={{ marginBottom: 15 }}
                />

                <Formik
                    initialValues={{
                        phone_number: (auth.profile.phone_number || '').replace('+1', ''),
                    }}

                    validationSchema={Yup.object().shape({
                        phone_number: Yup.string().required('Mobile number is required'),
                    })}

                    onSubmit={async values => {
                        const response = await auth.update(values);
                        if (response.error) {
                            setSnackbar({ message: response.error.message, open: true, severity: 'error' })
                        } else {
                            setSnackbar({ message: 'Successfully updated.', open: true, severity: 'success' })
                        }
                    }}
                >

                    {
                        ({ isSubmitting, isValid, dirty }) =>
                            (
                                <Form>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Field
                                                component={FullWidthTextField}
                                                name='phone_number'
                                                label='Mobile number'
                                                disabled={readonly}
                                                type='text'
                                            />
                                        </Grid>
                                       
                                        <Grid item xs={12} style={{marginBottom: 20}}>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    <Button variant='contained' color='secondary' onClick={() => setReadonly(!readonly)} type='button' >{readonly ? 'Edit' : 'Cancel'}</Button>
                                                </Grid>

                                                {!readonly &&
                                                    <>
                                                        <Grid item xs={4}>
                                                            <Button disabled={!dirty} variant='contained' color='default' type='reset'>Reset</Button>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Button disabled={!dirty || !isValid} variant='contained' color='primary' type='submit'>Submit&nbsp; {
                                                                isSubmitting && <CircularProgress size={20} />
                                                            }</Button>
                                                        </Grid>
                                                    </>}
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                    }
                </Formik>
            </> }

            <Snackbar
                open={snackbar.open}
                autoHideDuration={5000}
                onClose={() => setSnackbar({})}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={() => setSnackbar({})}
                    severity={snackbar.severity}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
}