import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { useRouteParam } from '../hooks/query';
import { ChurchProfile } from '../components/Church/profile';
import Error from './Error';
import { setHOW } from '../helpers/localcache';
import { NavBar } from '../components/NavBar';

const useStyles = makeStyles(() => ({
    content: {

    },
    title: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 30,
        paddingBottom: 30,
        backgroundColor: '#7FDCEB'
    }
}));

export default ({ church }) => {
    const classes = useStyles();
    const storeId = useRouteParam(params => params.id);

    React.useEffect(() => {
        setHOW(storeId);
    }, [storeId]);


    return (
        <>
            <NavBar context='about' />
            {
                !church ? <Error /> :
                    <section className={classes.content}>
                        <ChurchProfile church={church} readonly={true} />
                    </section>
            }
        </>
    );
}
