import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  makeStyles,
  Container,
  Typography,
  Paper,
  Button,
  Avatar,
  Grid
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import StoreIcon from "@material-ui/icons/Store";
import CheckIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close"
import Header from "../components/Header";
import { useCheckIn, useCheckOut } from "../hooks/use-fetch";
import useMount from "../hooks/use-mount"
import FullPageSpinner from "../components/FullPageSpinner"
import { HeadCount } from './reservations/ReservationItem';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#7FDCEB',
    fontSize: 48,
    height: 120
  },
  subheader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#E0F6FA',
    alignItems: 'center',
    fontSize: 34,
    height: 80
  },
  logo: {
    height: 100,
    width: 100
  },
  status: {
    minHeight: 20
  },
  pass: {
    color: '#00AA41',
    fontSize: 150
  },
  fail: {
    color: '#D51915',
    fontSize: 150
  }
}));

export default function Reservation() {
  const classes = useStyles();
  const { way, hash } = useParams()
  const [{ data: checkInData, loading: checkInLoading }, checkIn] = useCheckIn()
  const [{ data: checkOutData, loading: checkOutLoading }, checkOut] = useCheckOut()
  const [data, setData] = useState({})

  useMount(() => {
    if (way === "in") {
      checkIn({ params: { hash } })
    } else {
      checkOut({ params: { hash } })
    }
  })

  useEffect(() => {
    if (checkInData || checkOutData) {
      const { message } = way === 'in' ? checkInData : checkOutData;
      message.start_time = moment(message.start_time, 'HH:mm:ss').format('HH:mma');
      message.end_time = moment(message.end_time, 'HH:mm:ss').format('HH:mma');
      message.timestamp = `${message.date} ${message.start_time} - ${message.end_time}`;
      setData(message)
    }
  }, [checkInData, checkOutData, way])

  if (checkInLoading || checkOutLoading) return <FullPageSpinner message="" />

  return (
    <>
      <Header />
      <Paper className={classes.header} elevation={2} square>
        {data.logo ? (
          <Avatar className={classes.logo} src={data.logo} />
        ) : (
            <StoreIcon className="logo" fontSize="large" color="primary" />
          )}
      </Paper>
      <Paper className={classes.subheader} elevation={2} square>
        <Typography variant='h4'>{data.name}</Typography>
      </Paper>
      <Container maxWidth="xs">
        <Grid container spacing={2}>
          <Grid align='center' item xs={12}>
            <Typography variant='h5'>
              {data.event_name}
            </Typography>
          </Grid>
          <Grid align='center' item xs={12}>
            <Typography variant='h6'>
              {data.timestamp}
            </Typography>
          </Grid>
          <Grid align='center' item xs={12}>
            <HeadCount value={data.head_count} need_assistance={data.need_assistance} />
          </Grid>
          <Grid className={classes.status} align='center' item xs={12}>
            {
              data.result ?
                <CheckIcon className={classes.pass} />
                :
                <CancelIcon className={classes.fail} />
            }
          </Grid>
          <Grid align='center' item xs={12} style={{marginBottom: 10}}>
            <Button
              variant='contained'
              color='primary'
              startIcon={<CloseIcon />}
              href='/'
            >
              Dismiss
            </Button>
          </Grid>

        </Grid>
      </Container>
    </>
  );
}
