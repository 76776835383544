import React from "react";
import InputBase from "@material-ui/core/InputBase";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import RoomIcon from "@material-ui/icons/Room";
import useDebounce from "../hooks/use-debounce";

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ffffff",
    "&::before": {
      borderBottom: 0,
    },
    position: "relative",
  },
  inputRoot: {
    fontSize: "16px",
    width: "100%",
    paddingRight: "1em",
  },
  icon: {
    color: theme.palette.primary.main,
    margin: "16px",
  },
  fakeDefault: {
    position: "absolute",
    left: 0,
    color: theme.palette.text.primary,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    paddingLeft: 55,
  },
  unstyledBtn: {
    margin: 0,
    padding: 0,
    background: "none",
    border: 0,
    fontSize: 16,
    textAlign: "left",
  },
}));

const getLabel = (address) => {
  if (!address) return "";

  let name = [];
  const keys = Object.keys(address).filter(
    (key) => !["country_code", "suburb", "county"].includes(key)
  );
  keys.forEach((key) => {
    name.push(address[key]);
  });

  return name.join(", ");
};

export default function LocationSelector({ onChange, value }) {
  const classes = useStyles();
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState(getLabel(value.address));
  const debouncedInput = useDebounce(inputValue, 300);

  const handleInputChange = (e) => {
    if (e && e.target === e.currentTarget) {
      setInputValue(e.target.value);
      if (e.target.value === "") {
        onChange("");
      }
    }
  };

  React.useEffect(() => {
    fetch(
      `https://nominatim.openstreetmap.org/search/${debouncedInput}?format=json&addressdetails=1&countrycodes=us`
    )
      .then((blob) => blob.json())
      .then((res) => setOptions(res))
      .catch((e) => console.error(e));
  }, [debouncedInput]);

  return (
    <Autocomplete
      getOptionLabel={(option) => {
        return getLabel(option.address);
      }}
      getOptionSelected={(option, _) => {
        return getLabel(option);
      }}
      options={options}
      autoComplete
      onInputChange={handleInputChange}
      inputValue={inputValue}
      renderInput={(params) => (
        <div className={classes.autocomplete}>
          <InputBase
            startAdornment={<RoomIcon className={classes.icon} />}
            className={classes.inputRoot}
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            placeholder="What location?"
          />
        </div>
      )}
      renderOption={(option) => {
        const original = getLabel(option.address);
        const regex = new RegExp(debouncedInput, "gi");
        const name = original.split(regex);
        return (
          <button
            className={classes.unstyledBtn}
            onClick={() => {
              setInputValue(original);
              onChange(option);
            }}
          >
            {name.map((part, index) => (
              <React.Fragment key={index}>
                {part}
                {index !== name.length - 1 && (
                  <span style={{ fontWeight: 700 }}>{debouncedInput}</span>
                )}
              </React.Fragment>
            ))}
          </button>
        );
      }}
    />
  );
}
