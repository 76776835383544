import React from "react";
import { Link } from "react-router-dom";
import { makeStyles, Grid, Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.surface.blueDark,
    minHeight: 272,
    paddingTop: 32,
    paddingBottom: 32,
    display: "flex",
    alignItems: "center",
    color: "#ffffff",
    '& a': {
      display: 'block',
      color: 'currentColor',
      textDecoration: 'none'
    }
  },
  list: {
    padding: 0,
    margin: 0,
    listStyleType: "none",
    "& li": {
      marginRight: "1em",
      "&:last-of-type": {
        marginRight: 0,
      },
    },
    flexDirection: "column",
    justifyContent: "center",
    "@media(min-width: 420px)": {
      flexDirection: "row",
      justifyContent: "flex-start",
    },
  },
  link: {
    color: "#ffffff",
    fontSize: 14,
    lineHeight: 1.8,
    fontWeight: 500,
  },
  socialLink: {
    width: 28,
    lineHeight: 2,
  },
  logo: {
    '& svg': {
      height: 30
    }
  },
  logoText: {
    marginLeft: "20px",
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <a
              className={classes.logo}
              href="https://www.tersuslabs.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 270 50"
                xmlSpace="preserve"
              >
                <g>
                  <polygon
                    fill="#ffffff"
                    points="75,15.5 87.2,15.5 87.2,39.4 92.1,39.4 92.1,15.5 104.2,15.5 104.2,10.8 75,10.8 	"
                  />
                  <path
                    fill="#ffffff"
                    d="M114.7,26.7h4.5c0.9,1.6,2.6,2.6,4.5,2.6c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2c-2.1,0-3.9,1.2-4.7,3h-4.3
      v-6.3h19v-4.8h-24.1v28.6h24.9v-5h-19.9L114.7,26.7L114.7,26.7z M123.8,21.8c1.3,0,2.3,1,2.3,2.3s-1,2.3-2.3,2.3s-2.3-1-2.3-2.3
      S122.5,21.8,123.8,21.8z"
                  />
                  <path
                    fill="#ffffff"
                    d="M166.1,24.2c1-1.5,1.6-3.2,1.6-5.1c0-2.2-0.6-4.2-1.8-5.8c-1.3-1.8-3.1-2.6-5.3-2.6h-19.4v28.6h5.1V27.6h10
      l6.4,11.8h5.6l-6.4-12.2C163.6,26.7,165,25.7,166.1,24.2z M159.1,22.7h-13v-7.1h13.5c0.8,0,1.6,0.4,2.3,1.2
      c0.7,0.8,1.1,1.6,1.1,2.3c0,1-0.4,1.8-1.1,2.5C161,22.4,160.2,22.7,159.1,22.7z"
                  />
                  <path
                    fill="#ffffff"
                    d="M198.6,24.4c-1.7-1.1-3.9-1.8-6.5-2.1l-9.7-1.1c-1.2-0.1-2.2-0.5-3.1-1.1c-0.9-0.6-1.3-1.4-1.3-2.3
      c0-0.8,0.8-1.6,2.3-2.2c1.5-0.7,3.1-1,4.6-1c1.9,0,4,0.4,6.3,1.2c1.9,0.7,3.7,1.5,5.4,2.5l3.6-3.5c-2-1.5-4-2.6-6-3.4
      c-2.3-0.9-4.5-1.3-6.8-1.3c-3.8,0-6.9,0.6-9.5,1.7c-3.2,1.5-4.8,3.6-4.8,6.5c0,1.9,0.7,3.5,2.1,5c1.4,1.4,3.1,2.3,5.2,2.7l14.1,2.1
      c0.7,0.1,1.3,0.5,1.6,1c0.3,0.6,0.5,1.2,0.5,1.9c0,1.4-1,2.5-2.9,3.3c-1.6,0.7-3.4,1-5.4,1c-2.3,0-4.7-0.5-7.2-1.5
      s-4.6-2.2-6.5-3.7l-3.3,3.8c0.7,0.6,1.5,1.1,2.2,1.7c1.8,1.2,3.9,2.1,6.1,3c3,1.1,6,1.6,9,1.6c4.3,0,7.7-1.1,10.1-3.4
      c2.1-1.9,3.1-4.1,3.1-6.6C201.8,27.7,200.7,25.8,198.6,24.4z"
                  />
                  <path
                    fill="#ffffff"
                    d="M228.9,30c0,1.5-0.9,2.7-2.6,3.7c-1.6,0.9-3.4,1.3-5.4,1.3c-2.3,0-4.1-0.4-5.4-1.2c-1.5-0.9-2.3-2.3-2.3-4.2
      V10.5h-4.9v19.2c0,3.2,1.2,5.7,3.7,7.6c2.3,1.8,5.2,2.6,8.6,2.6c3.4,0,6.3-0.7,8.8-2.2c3-1.8,4.4-4.4,4.4-7.7V10.5h-5L228.9,30
      L228.9,30z"
                  />
                  <path
                    fill="#ffffff"
                    d="M266.6,24.4c-1.7-1.1-3.9-1.8-6.5-2.1l-9.7-1.1c-1.2-0.1-2.2-0.5-3.1-1.1c-0.9-0.6-1.3-1.4-1.3-2.3
      c0-0.8,0.8-1.6,2.3-2.2c1.5-0.7,3.1-1,4.6-1c1.9,0,4,0.4,6.3,1.2c1.9,0.7,3.7,1.5,5.4,2.5l3.6-3.5c-2-1.5-4-2.6-6-3.4
      c-2.3-0.9-4.5-1.3-6.8-1.3c-3.8,0-6.9,0.6-9.5,1.7c-3.2,1.5-4.8,3.6-4.8,6.5c0,1.9,0.7,3.5,2.1,5c1.4,1.4,3.1,2.3,5.2,2.7l14.1,2.1
      c0.7,0.1,1.3,0.5,1.6,1c0.3,0.6,0.5,1.2,0.5,1.9c0,1.4-1,2.5-2.9,3.3c-1.6,0.7-3.4,1-5.4,1c-2.3,0-4.7-0.5-7.2-1.5
      s-4.6-2.2-6.5-3.7l-3.3,3.8c0.7,0.6,1.5,1.1,2.2,1.7c1.8,1.2,3.9,2.1,6.1,3c3,1.1,6,1.6,9,1.6c4.3,0,7.7-1.1,10.1-3.4
      c2.1-1.9,3.1-4.1,3.1-6.6C269.8,27.7,268.7,25.8,266.6,24.4z"
                  />
                </g>
                <g>
                  <g>
                    <ellipse fill="#ffffff" cx="25" cy="11.9" rx="4.2" ry="4.1" />
                    <path
                      fill="#FFCC00"
                      d="M23.9,44.2c-1.4-1.7-2-3.8-2-6.5V21c-2.9-1-5.1-3.3-5.9-6.2H0V24h14.2v26h21.7v-3.3h-6.1
        C27.2,46.7,25.2,45.8,23.9,44.2z"
                    />
                    <path
                      fill="#FFCC00"
                      d="M28.2,21v17c0,1.1,0.3,1.9,0.8,2.3c0.5,0.5,1.4,0.7,2.5,0.7h4.2V24H50v-9.2H34.1C33.2,17.7,31,20,28.2,21z"
                    />
                    <path
                      fill="#FFCC00"
                      d="M0,0v9.2h15.9c1.2-3.9,4.9-6.6,9.1-6.6s7.9,2.8,9.1,6.6H50V0H0z"
                    />
                  </g>
                </g>
              </svg>
            </a>
            <p>Our social responsibility commitment:</p>
            <p>
              We pledge to donate  10% of profits to <a href="https://feedingamerica.org" target="_blank" rel="noopener noreferrer">feedingamerica.org</a>
              The remainder will fund ongoing development of our ground-breaking new healthcare application.
            </p>
          </Grid>
          
          <Grid item container direction="column" justify="center" xs={12} md={4}>
            <a className={classes.link} href="mailto:sales@perfectlydistanced.com?subject=PD sales inquiry">Sales Inquiries</a>
            <a className={classes.link} href="mailto:sales@perfectlydistanced.com?subject=PD for Business inquiry">Perfectly Distanced for Businesses</a>
            <a className={classes.link} href="mailto:support@perfectlydistanced.com?subject=PD Support">Technical Support</a>
          </Grid>
          <Grid xs={12} md={4} item container direction="column" justify="center" >
            <ul className={classes.list}>
              <li>
                <Link to="/terms-of-use" className={classes.link}>
                  Terms of Use
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy" className={classes.link}>
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/cookie-policy" className={classes.link}>
                  Cookie Policy
                </Link>
              </li>
              <li>
                <small className={classes.link}>
                  Copyright Tersus Labs, Inc., 2020
                </small>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}
