import React from "react";
import StaticPagesHeader from "../components/StaticPagesHeader";
import { makeStyles, Container, Typography, Box } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  section: {
    padding: "85px 0",
  },
  link: {
    color: "#000000",
  },
  container: {
    paddingleft: 40,
    paddingright: 40,
    paddingTop: 40,
    paddingBottom: 40,
    "& h5": {
      margin: "20px 0",
      fontWeight: 300,
    },
  },
}));

export default function CookiePolicy() {
  const classes = useStyles();

  return (
    <>
      <StaticPagesHeader />
      <section>
        <Container className={classes.container}>
          <Typography variant="h4" color="primary">
            Cookie Policy for Perfectly Distanced
          </Typography>

          <Typography variant="h5">
            This is the Cookie Policy for Perfectly Distanced, accessible from
            www.perfectlydistanced.com
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              What Are Cookies
            </Box>
          </Typography>

          <Typography variant="h5">
            As is common practice with almost all professional websites this
            site uses cookies, which are tiny files that are downloaded to your
            computer, to improve your experience. This page describes what
            information they gather, how we use it and why we sometimes need to
            store these cookies. We will also share how you can prevent these
            cookies from being stored however this may downgrade or 'break'
            certain elements of the site’s functionality
          </Typography>

          <Typography variant="h5">
            For more general information on cookies, please read{" "}
            <a
              className={classes.link}
              href="https://www.cookieconsent.com/what-are-cookies/"
              target="_blank"
              rel="noopener noreferrer"
            >
              "What Are Cookies"
            </a>
            .
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              How We Use Cookies
            </Box>
          </Typography>

          <Typography variant="h5">
            We use cookies for a variety of reasons detailed below.
            Unfortunately in most cases there are no industry standard options
            for disabling cookies without completely disabling the functionality
            and features they add to this site. It is recommended that you leave
            on all cookies if you are not sure whether you need them or not in
            case they are used to provide a service that you use.
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              Disabling Cookies
            </Box>
          </Typography>

          <Typography variant="h5">
            You can prevent the setting of cookies by adjusting the settings on
            your browser (see your browser Help for how to do this). Be aware
            that disabling cookies will affect the functionality of this and
            many other websites that you visit. Disabling cookies will usually
            result in also disabling certain functionality and features of this
            site. Therefore it is recommended that you do not disable cookies.
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              The Cookies We Set
            </Box>
          </Typography>

          <Typography variant="h5">
            <ul>
              <li>Account related cookies</li>
            </ul>
            If you create an account with us then we will use cookies for the
            management of the signup process and general administration. These
            cookies will usually be deleted when you log out however in some
            cases they may remain afterwards to remember your site preferences
            when logged out
            <ul>
              <li>Login related cookies</li>
            </ul>
            We use cookies when you are logged in so that we can remember this
            fact. This prevents you from having to log in every single time you
            visit a new page. These cookies are typically removed or cleared
            when you log out to ensure that you can only access restricted
            features and areas when logged in.
            <ul>
              <li>Email newsletters related cookies</li>
            </ul>
            This site offers newsletter or email subscription services and
            cookies may be used to remember if you are already registered and
            whether to show certain notifications which might only be valid to
            subscribed/unsubscribed users.
            <ul>
              <li>Orders processing related cookies</li>
            </ul>
            This site offers e-commerce or payment facilities and some cookies
            are essential to ensure that your order is remembered between pages
            so that we can process it properly.
            <ul>
              <li>Surveys related cookies</li>
            </ul>
            From time to time we offer user surveys and questionnaires to
            provide you with interesting insights, helpful tools, or to
            understand our user base more accurately. These surveys may use
            cookies to remember who has already taken part in a survey or to
            provide you with accurate results after you change pages.
            <ul>
              <li>Forms related cookies</li>
            </ul>
            When you submit data through a form such as those found on contact
            pages or comment forms cookies may be set to remember your user
            details for future correspondence.
            <ul>
              <li>Site preferences cookies</li>
            </ul>
            In order to provide you with a great experience on this site we
            provide the functionality to set your preferences for how this site
            runs when you use it. In order to remember your preferences we need
            to set cookies so that this information can be called whenever you
            interact with a page is affected by your preferences.
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              Third Party Cookies
            </Box>
          </Typography>

          <Typography variant="h5">
            In some special cases we also use cookies provided by trusted third
            parties. The following section details which third party cookies you
            might encounter through this site.
            <ul>
              <li>
                From time to time we test new features and make subtle changes
                to the way that the site is delivered. When we are still testing
                new features these cookies may be used to ensure that you
                receive a consistent experience whilst on the site whilst
                ensuring we understand which optimizations our users appreciate
                the most.
              </li>
              <li>
                As we sell products it's important for us to understand
                statistics about how many of the visitors to our site actually
                make a purchase and as such this is the kind of data that these
                cookies will track. This is important to you as it means that we
                can accurately make business predictions that allow us to
                monitor our advertising and product costs to ensure the best
                possible price.
              </li>
            </ul>
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              More Information
            </Box>
          </Typography>

          <Typography variant="h5">
            Hopefully that has clarified things for you and as was previously
            mentioned if there is something that you aren't sure whether you
            need or not it's usually safer to leave cookies enabled in case it
            does interact with one of the features you use on our site. This
            Cookies Policy was created with the help of the{" "}
            <a
              className={classes.link}
              href="https://www.cookiepolicygenerator.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cookies Policy Template Generator
            </a>{" "}
            and the{" "}
            <a
              className={classes.link}
              href="https://www.privacypolicytemplate.net/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy Template Generator.
            </a>
          </Typography>

          <Typography variant="h5">
            However if you are still looking for more information you can
            contact us through one of our preferred contact methods:
            <ul>
              <li>
                Email:{" "}
                <a
                  className={classes.link}
                  href="mailto:legal@perfectlydistanced.com"
                >
                  legal@perfectlydistanced.com
                </a>
              </li>
            </ul>
          </Typography>
        </Container>
      </section>
    </>
  );
}
