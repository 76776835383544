import * as React from 'react';
import * as PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

// const useClasses = makeStyles(() => ({
//     root: {
//         width: 200,
//         height: 250
//     }
// }));

const BusinessLocationMap = ({ lat, lng, address, google }) => {

    return (
        <Map google={google} initialCenter={{ lat, lng }} zoom={14}
            containerStyle={{
                position: 'relative',
                width: '100%',
                height: 200
            }}
            style={{width: '100%'}}>

            <Marker
                mapCenter={{ lat, lng }}
                name={address} />
        </Map>
    );
}

BusinessLocationMap.propTypes = {
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    LoadingContainer: () => <CircularProgress />
})(BusinessLocationMap)