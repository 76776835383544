import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Slide from '@material-ui/core/Slide';
import { IconButton, withStyles, List, ListItem, ListItemIcon, Snackbar, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import EventIcon from '@material-ui/icons/Event';
import ScheduleIcon from '@material-ui/icons/Schedule';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import AccessibleIcon from '@material-ui/icons/Accessible';
import GroupIcon from '@material-ui/icons/Group';
import { Formik, Form, Field } from 'formik';
import { FullWidthTextField, CheckboxField } from '../common/FormikFields';
import moment from 'moment';
import * as _ from 'lodash';
import { Select } from 'material-ui-formik-components/Select'
import Alert from "@material-ui/lab/Alert";
import { ReserveButton } from './reserve-button';
import { CurrentUserContext } from '../../context/CurrentUserContext';
import NotesIcon from '@material-ui/icons/Notes';
import { isInThePast } from '../../helpers/date';
import { useHistory } from 'react-router-dom';
import { SchedulerStore } from '../../context/SchedulerContext';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const MakeReservation = ({ event, open, onClose, store }) => {
    const [snackbar, setSnackbar] = React.useState();
    const history = useHistory();
    const schedule = React.useContext(SchedulerStore);

    const reserve = async (values) => {
        const params = {
            store_id: store.store_id,
            profile_name: store.profile_name,
            event_id: event.event_id,
            startDate: moment(event.startDate).format("YYYY-MM-DDTHH:mm:ss"),
            endDate: moment(event.startDate).format("YYYY-MM-DDTHH:mm:ss"),
            head_count: values.count,
            need_assistance: values.needAssistance
        }

        const response = await schedule.reserve({ params });
        if (response) {
            history.push('/reservation')
        }
    }

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle onClose={onClose} />
                <Formik initialValues={{
                    count: 1,
                    needAssistance: false
                }}
                    onSubmit={reserve}
                >
                    {({ isSubmitting, isValid, dirty }) => (
                        <Form >
                            <DialogContent style={{ minWidth: 400 }}>
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <FiberManualRecordIcon color='secondary' />
                                        </ListItemIcon>
                                        {event.title}
                                    </ListItem>
                                    {!!event.description && <ListItem>
                                        <ListItemIcon>
                                            <NotesIcon />
                                        </ListItemIcon>
                                        {event.description}
                                    </ListItem>}
                                    <ListItem>
                                        <ListItemIcon>
                                            <EventIcon />
                                        </ListItemIcon>
                                        {moment(event.startDate).format('dddd, MMMM DD, YYYY')}
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <ScheduleIcon />
                                        </ListItemIcon>
                                        {moment(event.startDate).format('LT')} - {moment(event.endDate).format('LT')}
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <EventAvailableIcon />
                                        </ListItemIcon>
                                    Number of slots available:&nbsp; <b>{schedule.getAvailability(event)}</b>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <GroupIcon />
                                        </ListItemIcon>
                                        {
                                            event.group_size_limit && event.group_size_limit > 0 ?

                                                <Field
                                                    component={Select}
                                                    name="count"
                                                    label="How many people in your group? *"
                                                    variant='outlined'
                                                    disabled={!schedule.isAvailable(event)}
                                                    options={
                                                        _.range(0, event.group_size_limit).filter(x => x <= schedule.getAvailability(event) - 1).map(x =>
                                                            ({ value: x + 1, label: `${x + 1}` })
                                                        )
                                                    }
                                                /> :

                                                <Field
                                                    component={FullWidthTextField}
                                                    name="count"
                                                    type="number"
                                                    disabled={!schedule.isAvailable(event)}
                                                    label="How many people in your group? *"
                                                />
                                        }
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AccessibleIcon />
                                        </ListItemIcon>
                                        <Field
                                            component={CheckboxField}
                                            name="needAssistance"
                                            label="Need assistance?"
                                            type='checkbox'
                                            disabled={!schedule.isAvailable(event)}
                                        />
                                    </ListItem>
                                </List>
                                <CurrentUserContext.Consumer>
                                    {
                                        ({ user }) => !user && <Typography variant='subtitle2' style={{ fontStyle: 'italic', padding: 5 }}>You need to login to make a reservation</Typography>
                                    }
                                </CurrentUserContext.Consumer>
                            </DialogContent>
                            <DialogActions>
                                <ReserveButton type='submit' disabled={!isValid || !schedule.isAvailable(event) || isInThePast(event.startDate)} />
                            </DialogActions>

                        </Form>
                    )}

                </Formik>

                {!!snackbar && <Snackbar
                    open={!!snackbar}
                    autoHideDuration={5000}
                    onClose={() => setSnackbar(undefined)}
                    action={<Button>Got to Reservations</Button>}>
                    <Alert variant="filled" severity={snackbar.severity} elevation={6}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>}
            </Dialog>
        </>
    );
}

MakeReservation.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    event: PropTypes.object.isRequired,
}

export { MakeReservation };