import * as React from "react";
import SignUpCard from "./SignUpCard";
import {
  TextField,
  // makeStyles,
} from "@material-ui/core";
import useHandlePhone from "../../hooks/use-handlephone";

// const useStyles = makeStyles((theme) => ({
//   placeholder: {
//     color: theme.palette.text.disabled,
//   },
// }));

export default function YourDetailsCard({ error }) {
  const [mobile, handlePhone] = useHandlePhone();

  return (
    <SignUpCard title="Contact">
      <TextField
        InputProps={{ disableUnderline: true }}
        name="firstName"
        fullWidth
        variant="filled"
        placeholder="First Name*"
        required
      />
      <TextField
        InputProps={{ disableUnderline: true }}
        name="lastName"
        fullWidth
        variant="filled"
        placeholder="Last Name*"
        required
      />
      <TextField
        InputProps={{ disableUnderline: true }}
        placeholder="Email*"
        name="email"
        fullWidth
        type="email"
        variant="filled"
        required
      />
      <TextField
        InputProps={{ disableUnderline: true }}
        placeholder="Mobile number*"
        name="mobile"
        fullWidth
        value={mobile}
        onChange={handlePhone}
        variant="filled"
        required
      />
      <TextField
        InputProps={{ disableUnderline: true }}
        placeholder="Password*"
        name="password"
        fullWidth
        type="password"
        variant="filled"
        className="mb0"
        FormHelperTextProps={{ className: "helper-text" }}
        helperText="Password must contain: Minimum eight characters, Upper and Lowercase letter, One Numeric, and One special character (%#$&@?!)"
        required
      />
      <TextField
        InputProps={{ disableUnderline: true }}
        placeholder="Re-type password*"
        name="confirmPassword"
        fullWidth
        type="password"
        variant="filled"
        required
        error={error && error.field === "confirmPassword"}
        helperText={error && error.message}
      />
    </SignUpCard>
  );
}
