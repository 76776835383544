import React, { useState } from "react";
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  Container,
  Typography,
  makeStyles,
  Grid,
  Button,
  Snackbar,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import Header, { CancelButton } from "../components/Header";
import YourDetailsCard from "../components/StoreSignup/YourDetailsCard";
import StoreDetailsCard from "../components/StoreSignup/StoreDetailsCard";
import PaymentDetailsCard from "../components/StoreSignup/PaymentDetailsCard";
import {
  useCreateManager,
  useCreateStore,
  useGetClientSecret,
} from "../hooks/use-fetch";

const stripePromise = loadStripe(
  !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === "development"
    ? "pk_test_4zpggN8fEtWYqXirNkVVEVla00DvwwI0jq"
    : "pk_live_beL1S51JxMyOIyFGtzafI4rA00TGiNcwhi"
);

const useStyles = makeStyles((theme) => ({
  formContainer: {
    paddingTop: "45px",
    paddingBottom: "45px",
    "& .white": {
      color: "#ffffff",
    },
    "& h2": {
      marginBottom: 30,
      textAlign: "center",
    },
    "& form": {
      paddingLeft: 12,
      paddingRight: 12,
    },
    "& .link": {
      textAlign: "center",
      color: theme.palette.text.medium,
      fontSize: "16px",
      "& a": {
        color: "currentColor",
      },
    },
    "& .button": {
      display: "flex",
      margin: "0 auto",
    },
    "& .checkbox": {
      color: theme.palette.text.medium,
      margin: "0 auto 30px",
      display: "block",
      textAlign: "center",
    },
  },
  grid: {
    marginBottom: 40,
  },
  titleContainer: {
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.light,
    paddingTop: 32,
    paddingBottom: 32,
  }
}));

function StoreSignup() {
  const classes = useStyles();
  const history = useHistory();
  const [accepted, setAccepted] = useState(false);
  const [paymentAck, setPaymentAck] = useState(false);
  const [logoResponse, setLogoResponse] = useState("");
  const [signUp, setSignUp] = useState({
    data: null,
    loading: false,
    error: null,
  });
  const [, createManager] = useCreateManager();
  const [, createStore] = useCreateStore();
  const [, getClientSecret] = useGetClientSecret();
  const stripe = useStripe();
  const elements = useElements();

  const handleLogo = (logoData) => {
    setLogoResponse(logoData);
  };

  const handleAccepted = () => {
    setAccepted(!accepted);
  };

  const handleAlertClose = () => {
    setSignUp({ ...signUp, error: null });
  };

  const handleForm = async (e) => {
    e.preventDefault();

    const email = e.target.email.value.toLowerCase();

    const createManagerPayload = {
      Username: email,
      Password: e.target.password.value,
      First_Name: e.target.firstName.value,
      Last_Name: e.target.lastName.value,
      Mobile: e.target.mobile.value.replace(" ", ""),
      Role: [], //e.target.role.value.split(", "),
      Store_Name: e.target.storeName.value,
    };

    const createStorePayload = {
      email: email,
      name: e.target.storeName.value,
      street: e.target.storeStreet.value,
      city: e.target.storeCity.value,
      state: e.target.storeState.value,
      zip: e.target.storeZipCode.value,
      phone: e.target.storeContactTelephoneNumber.value.replace(" ", ""),
      //website: e.target.website.value,
      //description: e.target.description.value,
      logo: logoResponse.url,
    };

    const logo = e.target.storeLogo.files[0];

    const billingDetails = {
      name: e.target.nameOnCard.value,
      email: email,
      address: {
        postal_code: e.target.cardZipCode.value,
      },
    };

    setSignUp({ ...signUp, loading: true });

    if (e.target.password.value !== e.target.confirmPassword.value) {
      setSignUp({
        ...signUp,
        loading: false,
        error: { field: "confirmPassword", message: "Passwords don't match" },
      });
      return;
    }

    // Stripe not loaded yet
    if (!stripe || !elements) return;

    // 1. Publish img
    if (logoResponse && logoResponse["presigned-url"] && logo) {
      await fetch(logoResponse["presigned-url"], {
        method: "PUT",
        body: logo,
      });
    }

    // 2. Create the manager
    const managerRes = await createManager({ params: createManagerPayload });

    if (managerRes.error) {
      setSignUp({ ...signUp, error: managerRes.error, loading: false });
      return;
    }

    // 3. Create the store
    const storeRes = await createStore({
      params: createStorePayload,
    });

    if (storeRes.error) {
      setSignUp({ ...signUp, error: storeRes.error, loading: false });
      return;
    }

    // 4. Get client secret for stripe
    const clientSecret = await getClientSecret({
      params: { email },
    });

    if (
      clientSecret.error ||
      !clientSecret.data ||
      !clientSecret.data.client_secret
    ) {
      setSignUp({
        ...signUp,
        loading: false,
        error: {
          message: clientSecret.error.message,
        },
      });
      return;
    }

    // 5. Create card for client
    const result = await stripe.confirmCardSetup(
      clientSecret.data.client_secret,
      {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: billingDetails,
        },
      }
    );

    if (result.error) {
      setSignUp({
        ...signUp,
        loading: false,
        error: result.error,
      });
      return;
    }

    history.push(`/verify`, { email: createManagerPayload.Username });
  };

  const goHome = () => {
    history.push("/");
  };

  return (
    <>
      <Header>
        <CancelButton onClick={goHome} />
      </Header>
      <div className={classes.titleContainer}>
        <Container>
          <Typography variant="h3">
            Sign up
          </Typography>
        </Container>
      </div>
      <Container className={classes.formContainer}>
        <Snackbar
          open={!!signUp.error}
          autoHideDuration={6000}
          onClose={handleAlertClose}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleAlertClose}
            severity="error"
          >
            {signUp.error && signUp.error.message}
            {signUp.error && signUp.error.ErrorMessage}
          </Alert>
        </Snackbar>
        <form onSubmit={handleForm}>
          <Grid spacing={6} container className={classes.grid}>
            <Grid item xs={12} lg={4}>
              <YourDetailsCard error={signUp.error} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <StoreDetailsCard handleLogo={handleLogo} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <PaymentDetailsCard
                setPaymentAck={setPaymentAck}
                paymentAck={paymentAck}
              />
            </Grid>
          </Grid>
          <p className="link">
            Read our <a href="/terms-of-use" target="_blank">Terms of Use</a>
          </p>
          <FormControlLabel
            className="checkbox"
            onChange={handleAccepted}
            value={accepted}
            control={<Checkbox />}
            label="I accept Perfectly Distanced’s Terms of Use"
            labelPlacement="end"
          />
          <Button
            type="submit"
            className="button"
            variant="contained"
            color="primary"
            disabled={!accepted || !paymentAck}
            startIcon={
              signUp.loading && (
                <CircularProgress
                  style={{ width: 20, height: 20 }}
                  className="white"
                />
              )
            }
          >
            Save and continue
          </Button>
        </form>
      </Container>
    </>
  );
}

export default function StoreSignupWithElements() {
  return (
    <Elements stripe={stripePromise}>
      <StoreSignup />
    </Elements>
  );
}
