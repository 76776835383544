import { useLocation, useRouteMatch } from "react-router-dom"
import querystring from 'query-string';

export const useQueryString = () => {
    const location = useLocation();
    
    return querystring.parse(location.search);
}

export const useRouteParams = () => {
    const match = useRouteMatch();
    return match.params;
}

export const useRouteParam = (selector) => {
    const match = useRouteMatch();
    return match && selector(match.params);
}