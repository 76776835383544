import React, { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Typography,
  makeStyles,
  Container,
  Paper,
  Button,
  Grid,
  IconButton,
  CircularProgress
} from "@material-ui/core";
import { days, months } from "../helpers/date";
import Header, { SignOutButton } from "../components/Header";
import {
  ArrowBack as ArrowBackIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Accessible as AccessibleIcon,
  Print as PrintIcon,
} from "@material-ui/icons";
import { getParsedTime, getIntParsedTime } from "../helpers/time";
import { UserContext } from "../context/UserContext";
import { useGetBookingCustomers } from "../hooks/use-fetch"
import useMount from "../hooks/use-mount"

const useStyles = makeStyles((theme) => ({
  bookings: {
    minHeight: 975,
    "& .title": {
      paddingTop: 43,
      paddingBottom: 43,
    },
    "& h4": {
      textAlign: "center",
    },
    "& .mb0": {
      marginBottom: 0,
    },
    "& .nowrap": {
      whiteSpace: "nowrap",
      flexWrap: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    "& .item-container": {
      flex: 1,
      position: "relative",
      maxWidth: 150,
    },
    "& .mt0": {
      marginTop: 0,
    },
    "& .mb35": {
      marginBottom: 35,
    },
    "& .mb18": {
      marginBottom: 18,
    },
    "& .mb45": {
      marginBottom: 45,
    },
    "& .text-medium": {
      color: theme.palette.text.medium,
    },
    "& .absolute-icon": {
      color: theme.palette.text.medium,
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      right: -12,
    },
    "& .flex": {
      display: "flex",
      "&.center": {
        alignItems: "center",
        justifyContent: "center",
      },
      "&.align-center": {
        alignItems: "center",
      },
    },
    "& .bottom-container": {
      padding: 20,
    },
    "& .fs12": {
      fontSize: 12,
    },
  },
}));

export default function StoreBookings({ match }) {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [, setUser] = useContext(UserContext);
  const [selectedTime, setSelectedTime] = useState(
    match.params && match.params.time ? parseInt(match.params.time) : 0
  );
  const [{ data, loading }, getBookings] = useGetBookingCustomers()
  const capacity = location.state && location.state.capacity_max

  useMount(() => {
    const time = selectedTime > 9 ? `${selectedTime}:00` : `0${selectedTime}:00`
    getBookings({ url: `store/bookings/${time}` })
  })

  if (!match.params) {
    return history.goBack();
  }
  const { date } = match.params;
  const day = new Date(date);

  const addToTime = () => {
    if (selectedTime < 23) {
      setSelectedTime(selectedTime + 1);
      const time = selectedTime + 1 > 9 ? `${selectedTime + 1}:00` : `0${selectedTime + 1}:00`
      getBookings({ url: `store/bookings/${time}` })
    }
  };

  const removeFromTime = () => {
    if (selectedTime > 0) {
      const time = selectedTime - 1 > 9 ? `${selectedTime - 1}:00` : `0${selectedTime - 1}:00`
      getBookings({ url: `store/bookings/${time}` })
      setSelectedTime(selectedTime - 1);
    }
  };

  const logoutUser = () => {
    setUser(null);
    //window.localStorage.removeItem("keepMeLoggedIn")
    window.localStorage.clear()
    history.push("/");
  };

  const goBack = () => {
    history.push("/space/details", { index: 0 });
  };

  return (
    <>
      <Header blue>
        <SignOutButton onClick={logoutUser} />
      </Header>
      <section className={classes.bookings}>
        <Paper component="div" elevation={2} className="title" square>
          <Container>
            <Typography variant="h4" color="primary">
              Customers booked
            </Typography>
            <Typography variant="h4">
              {days[day.getDay()]}, {months[day.getMonth()]} {day.getDate()}
            </Typography>
          </Container>
        </Paper>
        <Container className="bottom-container">
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <div className="flex align-center text-medium mb45">
                <AccessibleIcon />
                <span className="fs12">Denotes disabled or senior</span>
              </div>
              <p className="text-medium mb0">Slots booked for this hour:</p>
              <Typography className="mb35" variant="h3">
                {loading ? <CircularProgress color="primary" /> : 
                  data && data.bookings && data.bookings.length
                }
              </Typography>
              <p className="text-medium mb0">Slots available for this hour:</p>
              <Typography className="mb35" variant="h3">
                {loading ? <CircularProgress color="primary" /> : 
                  data && data.bookings && (capacity - data.bookings.length)
                }
              </Typography>

              <Button
                startIcon={<ArrowBackIcon />}
                variant="contained"
                color="primary"
                onClick={goBack}
              >
                Back to bookings
              </Button>
            </Grid>
            <Grid item xs={12} md={8}>
              <div className="flex mb18 center">
                <IconButton onClick={removeFromTime}>
                  <ChevronLeftIcon />
                </IconButton>
                <Typography variant="h3">
                  {getIntParsedTime(selectedTime)} -{" "}
                  {getParsedTime(selectedTime + 1)}{" "}
                </Typography>
                <IconButton onClick={addToTime}>
                  <ChevronRightIcon />
                </IconButton>
              </div>
                <Grid
                  alignItems={loading ? "center" : "flex-start"}
                  justify={loading ? "center" : "space-between"}
                  spacing={2}
                  container
                >
                  {loading ? (
                    <CircularProgress color="primary"  />
                  ) :
                    data && data.bookings.map((booking, index) => (
                    <Grid
                      key={`client-${index}`}
                      item
                      className="mb16 item-container"
                    >
                      <p className="nowrap mb0 mt0 text-medium">
                        {index}. {booking.name}{" "}
                      </p>
                      {booking.senior && (
                        <AccessibleIcon className="absolute-icon" />
                      )}
                    </Grid>
                  ))}
                </Grid>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button endIcon={<PrintIcon />} className="text-medium" onClick={() => window.print()}>
                Print
              </Button>
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
}
