import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../components/Header";
import { CircularProgress, Snackbar, Typography, makeStyles, Container, Button, TextField } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert"
import { useParams } from "react-router-dom";
import useMount from "../hooks/use-mount"
import { useVerifyEmail, useSendPhoneVerification, useVerifyPhone } from "../hooks/use-fetch"

const useStyles = makeStyles((theme) => ({
  section: {
    minHeight: "calc(100vh - 164px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& h5, & h2": {
      textAlign: "center",
      marginTop: 30,
    },
  },
}));

export default function VerifyCustomer() {
  const { email, email_verification_code } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const [, verifyEmail] = useVerifyEmail()
  const [, sendPhoneVerification] = useSendPhoneVerification()
  const [{ loading: phoneLoading, error: phoneError }, verifyPhone] = useVerifyPhone()
  const [digits, setDigits] = useState("")
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'error',
    message: ''
  })

  useMount(() => {
    verifyEmail({
      params: {
        email,
        email_verification_code
      }
    })

    sendPhoneVerification({ params: { email }})
  })

  useEffect(() => {
    if (phoneError) {
      setSnackbar({ open: true, severity: 'error', message: phoneError.message })
    }
  }, [phoneError])

  const handleDigits =(e) => {
      if (isNaN(e.target.value)) return;
    if (e.target.value.length <= 6) {
      setDigits(e.target.value)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (digits.length === 6 && !isNaN(digits)) {
      const res = await verifyPhone({
        params: {
          email,
          phone_verification_code: digits
        }
      })

      if (!res.error) {
          setSnackbar({ open: true, severity: 'success', message: 'Phone was successfully verified.' })
          history.push('/login')
      }
    } else {
      setSnackbar({ open: true, severity: 'error', message: 'Code is 6 digits long.' })
    }
  }

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false })
  }

  return (
    <>
      <Header blue />
      <section className={classes.section}>
        <Container maxWidth="sm">
          <Typography variant="h5" style={{ marginBottom: 40 }}>Verification code was sent to the mobile number registered to your account, please enter the verification code in the box below to complete your sign up:</Typography>
          <form onSubmit={handleSubmit}>
            <TextField inputProps={{ style: { textAlign: 'center', fontSize: '6rem' } }} fullWidth endAdornment={phoneLoading && <CircularProgress color="secondary" style={{ width: 20, height: 20 }} />} InputProps={{ disableUnderline: true }} variant="outlined" color="secondary" type="text" value={digits} onChange={handleDigits} />
            <Button endIcon={phoneLoading && <CircularProgress style={{ color: '#ffffff', height: 20, width: 20 }} />} style={{ marginTop: 16, width: '100%' }} color="primary" variant="contained" type="submit">Submit</Button>
          </form>
        </Container>
      </section>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} variant="filled" severity={snackbar.severity} elevation={6}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
