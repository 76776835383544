
import * as React from 'react';
import {
    AppointmentForm,
} from '@devexpress/dx-react-scheduler-material-ui';
import { Grid, TextField } from '@material-ui/core';
import { DateTimePicker } from "@material-ui/pickers";
import { SchedulerStore } from '../../context/SchedulerContext';


const PerfectlyDistancedAppointmentFormCommands = (props) => {
    const scheduler = React.useContext(SchedulerStore);
    const disableSave = scheduler.errors.capacity || scheduler.errors.group_size_limit || scheduler.errors.description;
    return <AppointmentForm.CommandLayout {...props} disableSaveButton={disableSave}/>
}


export const CustomDateTimePicker = (props) => {
    return <DateTimePicker
        value={props.value}
        format='MM/DD/YYYY HH:mm a'
        inputVariant="outlined"
        variant='inline'
        onChange={props.onValueChange}
    />
}

const EventTextEditor = (props) => {
    if (props.type === 'multilineTextEditor') {
        return null;

    } return <TextField {...props} label={props.placeholder} style={{ width: '100%', marginTop: 5, marginBottom: 15 }} variant='outlined' value={props.value} onChange={event => props.onValueChange(event.target.value)} />;
};

const PerfectlyDistancedAppointmentForm = (props) => {
    const {setErrors: setSchedulerErrors} = React.useContext(SchedulerStore);
    const [errors, setErrors] = React.useState({
        capacity: !props.appointmentData.capacity || Number(props.appointmentData.capacity) === 0,
        group_size_limit: !props.appointmentData.group_size_limit || Number(props.appointmentData.group_size_limit) === 0,
        description: (props.appointmentData?.length || 0) > 250
    });
    const onCustomFieldChange = (field) => (event) => {
        const { value } = event.target;
        if (['capacity', 'group_size_limit'].includes(field)) {
            setErrors({ ...errors, [field]: !value || Number(value) === 0 })
        } else if (field === 'description') {

            setErrors({ ...errors, description: value.length > 250 });

        }
        props.onFieldChange({ [field]: field !== 'description' ? Number(event.target.value) : event.target.value });
    };

    React.useEffect(() => {
        setSchedulerErrors(errors);
    }, [errors, setSchedulerErrors])

    const remainingCharacters = 250 - (props.appointmentData.description?.length || 0);
    return (
        <AppointmentForm.BasicLayout
            appointmentData={props.appointmentData}
            onFieldChange={props.onFieldChange}
            {...props}
        >
            <TextField
                style={{ minWidth: '100%' }}
                variant='outlined'
                type='text'
                multiline
                label='Description'
                defaultValue={props.appointmentData.description}
                onChange={onCustomFieldChange('description')}
                error={errors.description}
                helperText={`${remainingCharacters < 0 ? 0 : remainingCharacters}/250 characters remaining`}

            />
            <TextField
                style={{ minWidth: '100%', marginTop: 20 }}
                required
                variant='outlined'
                type='number'
                label='What is the maximum capacity?'
                defaultValue={props.appointmentData.capacity}
                onChange={onCustomFieldChange('capacity')}
                error={errors.capacity}
                inputProps={{ min: 1 }}
            />
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>

                    <TextField
                        style={{ minWidth: '100%', marginTop: 20 }}
                        required
                        variant='outlined'
                        type='number'
                        label='Set Group Booking Limit'
                        defaultValue={props.appointmentData.group_size_limit}
                        onChange={onCustomFieldChange('group_size_limit')}
                        error={errors.group_size_limit}
                        inputProps={{ min: 1 }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        style={{ minWidth: '100%', marginTop: 20 }}
                        variant='outlined'
                        type='number'
                        label='Slots allocated for Disabled'
                        defaultValue={props.appointmentData.reserved_capacity}
                        onChange={onCustomFieldChange('reserved_capacity')}
                        inputProps={{ min: 0 }}
                    />
                </Grid>

            </Grid>
        </AppointmentForm.BasicLayout>
    );
};


export { PerfectlyDistancedAppointmentForm, EventTextEditor, PerfectlyDistancedAppointmentFormCommands };

