import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Grid, makeStyles } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import { useUpdateStore } from '../../hooks/use-fetch';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { FullWidthTextField, RichTextEditor } from '../common/FormikFields';
import Alert from '@material-ui/lab/Alert';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useClases = makeStyles(theme => ({
    root: {
        minHeight: 450
    }
}));

const Transition = React.forwardRef(function Transition(
    props,
    ref
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EditProfile = ({ profile, onClose, open, onUpdate }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useClases();
    const [error, setError] = React.useState();
    const [success, setSuccess] = React.useState();

    const [, update] = useUpdateStore();
    const handleSave = async (values) => {
        const params = { ...profile, ...values, phone: values.phone.split(" ").join('').replace('+1', '') };
        try {
            const response = await update({ params });
            if (!response.error) {
                onUpdate(params);
            }
            setError(response.error);
            setSuccess(!response.error);
        } catch (error) {
            setError(error);
        }
    };

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={open}
            TransitionComponent={Transition}
            keepMounted
            fullScreen={fullScreen}
            scroll='paper'
            onClose={onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">Edit profile information</DialogTitle>

            <Formik
                initialValues={{
                    name: profile.name,
                    description: profile.description,
                    street: profile.street,
                    city: profile.city,
                    zip: profile.zip,
                    phone: profile.phone.split(" ").join('').replace('+1', ''),
                    website: profile.website,
                    state: profile.state
                }}
                validationSchema={
                    Yup.object().shape({
                        name: Yup.string().required('Name is required'),
                        street: Yup.string().required('Street Address is required'),
                        city: Yup.string().required('City is required'),
                        zip: Yup.string().required('Zip Code is required'),
                        phone: Yup.string().required('Phone number is required'),
                        state: Yup.string().required('State number is required'),
                    })
                }
                onSubmit={handleSave}
            >
                {({ isSubmitting, isValid, dirty }) => (
                    <Form >
                        <DialogContent className={classes.root} dividers>
                            <Grid container={true} spacing={1}>
                                <Grid item={true} xs={12}>
                                    <Field
                                        component={FullWidthTextField}
                                        name="name"
                                        type="text"
                                        label="Name"
                                    />
                                </Grid>

                                <Grid item={true} xs={12}>
                                    <Field
                                        component={FullWidthTextField}
                                        name="street"
                                        type="text"
                                        label="Street Address"
                                    />
                                </Grid>
                                <Grid item xs={12}>

                                    <Grid container spacing={1}>
                                        <Grid item={true} xs={12} md={6}>
                                            <Field
                                                component={FullWidthTextField}
                                                name="city"
                                                type="text"
                                                label="City"
                                            />
                                        </Grid>
                                        <Grid item={true} xs={12} md={6}>
                                            <Field
                                                component={FullWidthTextField}
                                                name="zip"
                                                type="text"
                                                label="Zip Code"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>

                                    <Grid container spacing={1}>
                                        <Grid item={true} xs={12} md={6}>
                                            <Field
                                                component={FullWidthTextField}
                                                name="phone"
                                                type="text"
                                                label="Phone"
                                            />
                                        </Grid>
                                        <Grid item={true} xs={12} md={6}>
                                            <Field
                                                component={FullWidthTextField}
                                                name="website"
                                                type="text"
                                                label="Website"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item={true} xs={12}>
                                    <Field
                                        component={FullWidthTextField}
                                        name="state"
                                        type="text"
                                        label="State"
                                    />
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <Field
                                        component={RichTextEditor}
                                        name="description"
                                        type="text"
                                        label="Description"
                                    />
                                </Grid>
                            </Grid>
                            {
                                success && <Alert color='success'>Successfully saved profile</Alert>
                            }
                            {
                                !!error && <Alert color='error'>Failed to save. Check your input and try again.</Alert>
                            }

                        </DialogContent>

                        <DialogActions>
                            <Button onClick={onClose} color="primary">
                                Close
                             </Button>
                            <Button type='submit' disabled={!dirty || !isValid} color="primary">
                                Save
                            </Button>
                        </DialogActions>
                    </Form>
                )}

            </Formik>
        </Dialog>
    );
}

EditProfile.propTypes = {
    onClose: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    open: PropTypes.bool
};

export { EditProfile };
