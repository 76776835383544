import * as React from 'react';
import Header, { ChurchAboutButton, ChurchCalendarButton, ChurchAccountButton, ManagerProfileButton } from './Header';
import { Button, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';

export const HoWNavBar = ({ context, blue }) => {
    const history = useHistory();
    const [signingOut, setSigningOut] = React.useState(false);
    const auth = useAuth();

    const isActive = (candidate) => {
        const path = history.location.pathname;

        const pathContains = () => path.includes(candidate);

        switch (candidate) {
            case 'about':
                return path === '/space/details' || pathContains();
            default:
                return pathContains();
        }
    };

    return (<Header blue={blue}>
        <ChurchAboutButton active={isActive('about')} />
        <ChurchCalendarButton active={isActive('calendar')} />
        <ChurchAccountButton active={isActive('account')} />
        <ManagerProfileButton active={isActive('profile')} />

        <Button color="primary" variant="text" onClick={async () => {
            setSigningOut(true);
            await auth.logout('manager');
            setSigningOut(false);
        }
        }>Sign out&nbsp;{signingOut && <CircularProgress size={20} />}</Button>

    </Header>

    );
}