import React from "react";
import StaticPagesHeader from "../components/StaticPagesHeader";
import { makeStyles, Container, Typography, Box } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  section: {
    padding: "85px 0",
  },
  link: {
    color: "#000000",
  },
  container: {
    paddingleft: 40,
    paddingright: 40,
    paddingTop: 40,
    paddingBottom: 40,
    "& h5": {
      margin: "20px 0",
      fontWeight: 300,
    },
  },
  uppercase: {
    textTransform: "uppercase",
  },
}));

export default function CookiePolicy() {
  const classes = useStyles();

  return (
    <>
      <StaticPagesHeader />
      <section>
        <Container className={classes.container}>
          <Typography variant="h4" color="primary">
            Perfectly Distanced Privacy Policy
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              General
            </Box>
          </Typography>

          <Typography variant="h5">
            Tersus Labs Inc. (“Company” or “we” or “us” or “our”) respects the
            privacy of the users (“user” or “you”) that use our website located
            at www.perfectlydistanced.com, including other media forms, media
            channels, mobile website or mobile application related or connected
            thereto (collectively, the “Website”). The following Company privacy
            policy (“Privacy Policy”) is designed to inform you, as a user of
            the Website, about the types of information that Company may gather
            about or collect from you in connection with your use of the
            Website. It also is intended to explain the conditions under which
            Company uses and discloses that information, and your rights in
            relation to that information. Changes to this Privacy Policy are
            discussed at the end of this document. Each time you use the
            Website, however, the current version of this Privacy Policy will
            apply. Accordingly, each time you use the Website you should check
            the date of this Privacy Policy (which appears at the beginning of
            this document) and review any changes since the last time you used
            the Website.
          </Typography>

          <Typography variant="h5">
            The Website is hosted in the United States of America and is subject
            to U.S. state and federal law. If you are accessing our Website from
            other jurisdictions, please be advised that you are transferring
            your personal information to us in the United States, and by using
            our Website, you consent to that transfer and use of your personal
            information in accordance with this Privacy Policy. You also agree
            to abide by the applicable laws of applicable states and U.S.
            federal law concerning your use of the Website and your agreements
            with us. Any persons accessing our Website from any jurisdiction
            with laws or regulations governing the use of the Internet,
            including personal data collection, use and disclosure, different
            from those of the jurisdictions mentioned above may only use the
            Website in a manner lawful in their jurisdiction. If your use of the
            Website would be unlawful in your jurisdiction, please do not use
            the Website.
          </Typography>

          <Typography className={classes.uppercase} variant="h5">
            BY USING OR ACCESSING THE WEBSITE, YOU ARE ACCEPTING THE PRACTICES
            DESCRIBED IN THIS PRIVACY POLICY.
          </Typography>

          <Typography className={classes.uppercase} variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              GATHERING, USE AND DISCLOSURE OF NON-PERSONALLY-IDENTIFYING
              INFORMATION{" "}
            </Box>
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              Users of the Website Generally
            </Box>
          </Typography>

          <Typography variant="h5">
            “Non-Personally-Identifying Information” is information that,
            without the aid of additional information, cannot be directly
            associated with a specific person. “Personally-Identifying
            Information,” by contrast, is information such as a name or email
            address that, without more, can be directly associated with a
            specific person. Like most website operators, Company gathers from
            users of the Website Non-Personally-Identifying Information of the
            sort that Web browsers, depending on their settings, may make
            available. That information includes the user’s Internet Protocol
            (IP) address, operating system, browser type and the locations of
            the websites the user views right before arriving at, while
            navigating and immediately after leaving the Website. Although such
            information is not Personally-Identifying Information, it may be
            possible for Company to determine from an IP address a user’s
            Internet service provider and the geographic location of the
            visitor’s point of connectivity as well as other statistical usage
            data. Company analyzes Non-Personally-Identifying Information
            gathered from users of the Website to help Company better understand
            how the Website is being used. By identifying patterns and trends in
            usage, Company is able to better design the Website to improve
            users’ experiences, both in terms of content and ease of use. From
            time to time, Company may also release the
            Non-Personally-Identifying Information gathered from Website users
            in the aggregate, such as by publishing a report on trends in the
            usage of the Website.
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              Web Cookies
            </Box>
          </Typography>

          <Typography variant="h5">
            A “Web Cookie” is a string of information which assigns you a unique
            identification that a website stores on a user’s computer, and that
            the user’s browser provides to the website each time the user
            submits a query to the website. We use cookies on the Website to
            keep track of services you have used, to record registration
            information regarding your login name and password, to record your
            user preferences, to keep you logged into the Website and to
            facilitate purchase procedures. Company also uses Web Cookies to
            track the pages that users visit during each Website session, both
            to help Company improve users’ experiences and to help Company
            understand how the Website is being used. As with other
            Non-Personally-Identifying Information gathered from users of the
            Website, Company analyzes and discloses in aggregated form
            information gathered using Web Cookies, so as to help Company, its
            partners and others better understand how the Website is being used.{" "}
            <span className={classes.uppercase}>
              COMPANY USERS WHO DO NOT WISH TO HAVE WEB COOKIES PLACED ON THEIR
              COMPUTERS SHOULD SET THEIR BROWSERS TO REFUSE WEB COOKIES BEFORE
              ACCESSING THE WEBSITE, WITH THE UNDERSTANDING THAT CERTAIN
              FEATURES OF THE WEBSITE MAY NOT FUNCTION PROPERLY WITHOUT THE AID
              OF WEB COOKIES. WEBSITE USERS WHO REFUSE WEB COOKIES ASSUME ALL
              RESPONSIBILITY FOR ANY RESULTING LOSS OF FUNCTIONALITY.
            </span>
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              Third-Party Advertisers
            </Box>
          </Typography>

          <Typography variant="h5">
            We may use third-party advertising companies to serve ads when you
            visit the Website. These companies may use information (not
            including any Personally-Identifying Information) about your visits
            to the Website and other websites that are contained in Web Cookies
            in order to provide advertisements about goods and services of
            interest to you. Using a tool created by the Network Advertising
            Initiative, you can opt out of several third-party ad servers’ and
            networks’ Web Cookies simultaneously. If you would like more
            information about this practice and to know your choices about not
            having this information used by these companies, please follow the
            following links:{" "}
            <a
              className={classes.link}
              href="https://optout.networkadvertising.org/?c=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://networkadvertising.org/managing/opt_out.asp
            </a>
            ,{" "}
            <a
              className={classes.link}
              href="https://optout.aboutads.info/?c=2&lang=EN"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://optout.aboutads.info/?c=2&lang=EN"
            </a>
            . Please contact us if you would like to know the identity of the
            third-party advertising companies we are currently using to serve
            ads.
          </Typography>

          <Typography variant="h5">
            We may allow advertisers to choose the characteristics of users who
            will see their advertisements, and we may use any of the
            Non-Personally Identifying Information we have collected (including
            information you may have decided not to show to other users, such as
            your birth year or other sensitive personal information or
            preferences) to select the appropriate audience for those
            advertisements. We do not identify you to the advertiser.
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              Web Beacons
            </Box>
          </Typography>

          <Typography variant="h5">
            A “Web Beacon” is an object that is embedded in a web page or email
            that is usually invisible to the user and allows website operators
            to check whether a user has viewed a particular web page or an
            email. Company may use Web Beacons on the Website and in emails to
            count users who have visited particular pages, viewed emails and to
            deliver co-branded services. Web Beacons are not used to access
            users’ Personally-Identifying Information. They are a technique
            Company may use to compile aggregated statistics about Website
            usage. Web Beacons collect only a limited set of information,
            including a Web Cookie number, time and date of a page or email view
            and a description of the page or email on which the Web Beacon
            resides. You may not decline Web Beacons. However, they can be
            rendered ineffective by declining all Web Cookies or modifying your
            browser setting to notify you each time a Web Cookie is tendered,
            permitting you to accept or decline Web Cookies on an individual
            basis.
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              Analytics
            </Box>
          </Typography>

          <Typography variant="h5">
            We may use third-party vendors, including Google, who use
            first-party cookies (such as the Google Analytics cookie) and
            third-party cookies (such as the DoubleClick cookie) together to
            inform, optimize and serve ads based on your past activity on the
            Website, including Google Analytics for Display Advertising. The
            information collected may be used to, among other things, analyze
            and track data, determine the popularity of certain content and
            better understand online activity. If you do not want any
            information to be collected and used by Google Analytics, you can
            install an opt-out in your web browser
            (https://tools.google.com/dlpage/gaoptout/) and/or opt out from
            Google Analytics for Display Advertising or the Google Display
            Network by using Google’s Ads Settings (
            <a
              className={classes.link}
              href="https://adssettings.google.com/authenticated"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.google.com/settings/ads
            </a>
            ).
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              Aggregated and Non-Personally-Identifying Information
            </Box>
          </Typography>

          <Typography variant="h5">
            We may share aggregated and Non-Personally Identifying Information
            we collect under any of the above circumstances. We may also share
            it with third parties and our affiliate companies to develop and
            deliver targeted advertising on the Website and on websites of third
            parties. We may combine Non-Personally Identifying Information we
            collect with additional Non-Personally Identifying Information
            collected from other sources. We also may share aggregated
            information with third parties, including advisors, advertisers and
            investors, for the purpose of conducting general business analysis.
            For example, we may tell our advertisers the number of visitors to
            the Website and the most popular features or services accessed. This
            information does not contain any Personally-Identifying Information
            and may be used to develop website content and services that we hope
            you and other users will find of interest and to target content and
            advertising.
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              SOCIAL MEDIA
            </Box>
          </Typography>

          <Typography variant="h5">
            We may use hyperlinks on the Website which will redirect you to a
            social network if you click on the respective link. However, when
            you click on a social plug-in, such as Facebook’s “Like” button,
            Twitter’s “tweet” button or the Google+, that particular social
            network’s plugin will be activated and your browser will directly
            connect to that provider’s servers. If you do not use these buttons,
            none of your data will be sent to the respective social network’s
            plugin provider. So for example, when you click on the Facebook’s
            “Like” button on the Website, Facebook will receive your IP address,
            the browser version and screen resolution, and the operating system
            of the device you have used to access the Website. Settings
            regarding privacy protection can be found on the websites of these
            social networks and are not within our control.
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              COLLECTION, USE AND DISCLOSURE OF PERSONALLY-IDENTIFYING
              INFORMATION
            </Box>
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              Website Registration
            </Box>
          </Typography>

          <Typography variant="h5">
            As defined above, Personally-Identifying Information is information
            that can be directly associated with a specific person. Company may
            collect a range of Personally-Identifying Information from and about
            Website users. Much of the Personally-Identifying Information
            collected by Company about users is information provided by users
            themselves when (1) registering for our service, (2) logging in with
            social network credentials, (3) participating in polls, contests,
            surveys or other features of our service, or responding to offers or
            advertisements, (4) communicating with us, (5) creating a public
            profile or (6) signing up to receive newsletters. That information
            may include each user’s name, address, email address and telephone
            number, and, if you transact business with us, financial information
            such as your payment method (valid credit card number, type,
            expiration date or other financial information). We also may request
            information about your interests and activities, your gender, age,
            date of birth, username, hometown and other demographic or relevant
            information as determined by Company from time to time. Users of the
            Website are under no obligation to provide Company with
            Personally-Identifying Information of any kind, with the caveat that
            a user’s refusal to do so may prevent the user from using certain
            Website features.
          </Typography>

          <Typography variant="h5">
            BY REGISTERING WITH OR USING THE WEBSITE, YOU CONSENT TO THE USE AND
            DISCLOSURE OF YOUR PERSONALLY-IDENTIFYING INFORMATION AS DESCRIBED
            IN THIS “COLLECTION, USE AND DISCLOSURE OF PERSONALLY-IDENTIFYING
            INFORMATION” SECTION.
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              Company Communications
            </Box>
          </Typography>

          <Typography variant="h5">
            We may occasionally use your name and email address to send you
            notifications regarding new services offered by the Website that we
            think you may find valuable. We may also send you service-related
            announcements from time to time through the general operation of the
            service. Generally, you may opt out of such emails at the time of
            registration or through your account settings, though we reserve the
            right to send you notices about your account, such as service
            announcements and administrative messages, even if you opt out of
            all voluntary email notifications.
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              Company Disclosures
            </Box>
          </Typography>

          <Typography variant="h5">
            Company will disclose Personally-Identifying Information under the
            following circumstances:
            <ul>
              <li>
                <Box component="span" fontWeight="fontWeightBold">
                  By Law or to Protect Rights.{" "}
                </Box>
                When we believe disclosure is appropriate, we may disclose
                Personally-Identifying Information in connection with efforts to
                investigate, prevent or take other action regarding illegal
                activity, suspected fraud or other wrongdoing; to protect and
                defend the rights, property or safety of Company, our users, our
                employees or others; to comply with applicable law or cooperate
                with law enforcement; to enforce our Terms of Use or other
                agreements or policies, in response to a subpoena or similar
                investigative demand, a court order or a request for cooperation
                from a law enforcement or other government agency; to establish
                or exercise our legal rights; to defend against legal claims; or
                as otherwise required by law. In such cases, we may raise or
                waive any legal objection or right available to us.
              </li>
              <li>
                <Box component="span" fontWeight="fontWeightBold">
                  Marketing Communications.{" "}
                </Box>
                Unless users opt-out from receiving Company marketing materials
                upon registration, Company may email users about products and
                services that Company believes may be of interest to them. If
                you wish to opt-out of receiving marketing materials from
                Company, you may do so by following the unsubscribe link in the
                email communications, by going to your account settings (if
                applicable) or contacting us using the contact information
                below.
              </li>
              <li>
                <Box component="span" fontWeight="fontWeightBold">
                  Third-Party Marketing Communications.{" "}
                </Box>
                Unless users opt-out from receiving marketing materials upon
                registration, Company may provide users’ email information to
                third parties, so that those third parties may directly contact
                them about additional products and services. To cease having
                your email information provided to third parties, you may do so
                by going to your account settings (if applicable) or contacting
                us using the contact information below. Even after opting-out,
                you may continue to receive marketing emails from third parties
                to whom Company already has provided your email information. You
                will be responsible for directly contacting such third parties
                to request cessation of further marketing emails.
              </li>
              <li>
                <Box component="span" fontWeight="fontWeightBold">
                  Third-Party Service Providers.{" "}
                </Box>
                We may share your Personally-Identifying Information, which may
                include your name and contact information (including email
                address) with our authorized service providers that perform
                certain services on our behalf. These services may include
                fulfilling orders, providing customer service and marketing
                assistance, performing business and sales analysis, supporting
                the Website’s functionality and supporting contests,
                sweepstakes, surveys and other features offered through the
                Website. We may also share your name, contact information and
                credit card information with our authorized service providers
                who process credit card payments. These service providers may
                have access to personal information needed to perform their
                functions but are not permitted to share or use such information
                for any other purpose.
              </li>
              <li>
                <Box component="span" fontWeight="fontWeightBold">
                  Business Transfers; Bankruptcy.{" "}
                </Box>
                Company reserves the right to transfer all
                Personally-Identifying Information in its possession to a
                successor organization in the event of a merger, acquisition,
                bankruptcy or other sale of all or a portion of Company’s
                assets. Other than to the extent ordered by a bankruptcy or
                other court, the use and disclosure of all transferred
                Personally-Identifying Information will be subject to this
                Privacy Policy, or to a new privacy policy if you are given
                notice of that new privacy policy and are given an opportunity
                to affirmatively opt-out of it. Personally-Identifying
                Information submitted or collected after a transfer, however,
                may be subject to a new privacy policy adopted by the successor
                organization.
              </li>
            </ul>
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              Changing Personally-Identifying Information; Account Termination
            </Box>
          </Typography>

          <Typography variant="h5">
            You may at any time review or change your Personally-Identifying
            Information by going to your account settings (if applicable) or
            contacting us using the contact information below. Upon your
            request, we will deactivate or delete your account and contact
            information from our active databases. Such information will be
            deactivated or deleted as soon as practicable based on your account
            activity and accordance with our deactivation policy and applicable
            law. To make this request, either go to your account settings (if
            applicable) or contact us as provided below. We will retain in our
            files some Personally-Identifying Information to prevent fraud, to
            troubleshoot problems, to assist with any investigations, to enforce
            our Terms of Use and to comply with legal requirements as is
            permitted by law. Therefore, you should not expect that all your
            Personally-Identifying Information will be completely removed from
            our databases in response to your requests. Additionally, we keep a
            history of changed information to investigate suspected fraud with
            your account.
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              General Use
            </Box>
          </Typography>

          <Typography variant="h5">
            Company uses the Personally-Identifying Information in the file we
            maintain about you, and other information we obtain from your
            current and past activities on the Website (1) to deliver the
            products and services that you have requested; (2) to manage your
            account and provide you with customer support; (3) to communicate
            with you by email, postal mail, telephone and/or mobile devices
            about products or services that may be of interest to you either
            from us, our affiliate companies or other third parties; (4) to
            develop and display content and advertising tailored to your
            interests on the Website and other sites; (5) to resolve disputes
            and troubleshoot problems; (6) to measure consumer interest in our
            services; (7) to inform you of updates; (8) to customize your
            experience; (9) to detect and protect us against error, fraud and
            other criminal activity; (10) to enforce our Terms of Use; and (11)
            to do as otherwise described to you at the time of collection. At
            times, we may look across multiple users to identify problems. In
            particular, we may examine your Personally-Identifying Information
            to identify users using multiple user IDs or aliases. We may compare
            and review your Personally-Identifying Information for accuracy and
            to detect errors and omissions. We may use financial information or
            payment method to process payment for any purchases made on the
            Website, enroll you in the discount, rebate, and other programs in
            which you elect to participate, to protect against or identify
            possible fraudulent transactions and otherwise as needed to manage
            our business.
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              COLLECTION AND USE OF INFORMATION BY THIRD PARTIES GENERALLY
            </Box>
          </Typography>

          <Typography variant="h5">
            Company contractually prohibits its contractors, affiliates, vendors
            and suppliers from disclosing Personally-Identifying Information
            received from Company, other than in accordance with this Privacy
            Policy. However, third parties are under no obligation to comply
            with this Privacy Policy with respect to Personally-Identifying
            Information that users provide directly to those third parties, or
            that those third parties collect for themselves. These third parties
            include advertisers, providers of games, utilities, widgets and a
            variety of other third-party applications accessible through the
            Website. Company neither owns nor controls the third-party websites
            and applications accessible through the Website. Thus, this Privacy
            Policy does not apply to information provided to or gathered by the
            third parties that operate them. Before visiting a third party, or
            using a third-party application, whether by means of a link on the
            Website, directly through the Website or otherwise, and before
            providing any Personally-Identifying Information to any such third
            party, users should inform themselves of the privacy policies and
            practices (if any) of the third party responsible for that website
            or application, and should take those steps necessary to, in those
            users’ discretion, protect their privacy.
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              SECURITY
            </Box>
          </Typography>

          <Typography variant="h5">
            We take the security of your Personally-Identifying Information
            seriously and use reasonable electronic, personnel and physical
            measures to protect it from loss, theft, alteration or misuse.
            However, please be advised that even the best security measures
            cannot fully eliminate all risks. We cannot guarantee that only
            authorized persons will view your information. We are not
            responsible for third-party circumvention of any privacy settings or
            security measures.
          </Typography>

          <Typography variant="h5">
            We are dedicated to protect all information on the Website as is
            necessary. However, you are responsible for maintaining the
            confidentiality of your Personally-Identifying Information by
            keeping your password confidential. You should change your password
            immediately if you believe someone has gained unauthorized access to
            it or your account. If you lose control of your account, you should
            notify us immediately.
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              PRIVACY POLICY CHANGES
            </Box>
          </Typography>

          <Typography variant="h5">
            Company may, in its sole discretion, change this Privacy Policy from
            time to time. Any and all changes to Company’s Privacy Policy will
            be reflected on this page and the date new versions are posted will
            be stated at the top of this Privacy Policy. Unless stated
            otherwise, our current Privacy Policy applies to all information
            that we have about you and your account. Users should regularly
            check this page for any changes to this Privacy Policy. Company will
            always post new versions of the Privacy Policy on the Website.
            However, Company may, as determined in its discretion, decide to
            notify users of changes made to this Privacy Policy via email or
            otherwise. Accordingly, it is important that users always maintain
            and update their contact information.
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              CHILDREN
            </Box>
          </Typography>

          <Typography variant="h5">
            The Children's Online Privacy Protection Act ("COPPA") protects the
            online privacy of children under 13 years of age. We do not
            knowingly collect or maintain Personally-Identifying Information
            from anyone under the age of 13, unless or except as permitted by
            law. Any person who provides Personally-Identifying Information
            through the Website represents to us that he or she is 13 years of
            age or older. If we learn that Personally-Identifying Information
            has been collected from a user under 13 years of age on or through
            the Website, then we will take the appropriate steps to cause this
            information to be deleted. If you are the parent or legal guardian
            of a child under 13 who has become a member of the Website or has
            otherwise transferred Personally-Identifying Information to the
            Website, please contact Company using our contact information below
            to have that child's account terminated and information deleted.
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              CALIFORNIA PRIVACY RIGHTS
            </Box>
          </Typography>

          <Typography variant="h5">
            California Civil Code Section 1798.83, also known as the "Shine The
            Light" law, permits our users who are California residents to
            request and obtain from us, once a year and free of charge,
            information about the Personally-Identifying Information (if any) we
            disclosed to third parties for direct marketing purposes in the
            preceding calendar year. If applicable, this information would
            include a list of the categories of the Personally-Identifying
            Information that was shared and the names and addresses of all third
            parties with which we shared Personally-Identifying Information in
            the immediately preceding calendar year. If you are a California
            resident and would like to make such a request, please submit your
            request in writing to our privacy officer as listed below.
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              DO-NOT-TRACK POLICY
            </Box>
          </Typography>

          <Typography variant="h5">
            Most web browsers and some mobile operating systems include a
            Do-Not-Track (“DNT”) feature or setting you can activate to signal
            your privacy preference not to have data about your online browsing
            activities monitored and collected. Because there is not yet a
            common understanding of how to interpret the DNT signal, the Website
            currently does not respond to DNT browser signals or mechanisms.
          </Typography>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              CONTACT
            </Box>
          </Typography>

          <Typography variant="h5">
            If you have any questions regarding our Privacy Policy, please
            contact us at:
            <br />
            <br />
            Tersus Labs Inc.
            <br />
            Attn: Privacy Officer
            <br />
            16192 Coastal Highway
            <br />
            Lewes, DE 19958
            <br />
            Email:{" "}
            <a
              className={classes.link}
              href="mailto:legal@perfectlydistanced.com"
            >
              legal@perfectlydistanced.com
            </a>
          </Typography>
        </Container>
      </section>
    </>
  );
}
