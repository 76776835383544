import React, { useContext } from "react";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import requireLogin from "./router/guards/requireLogin";
import notIE from "./router/guards/notIE";
import ScrollToTop from "./components/ScrollToTop";
import FullPageSpinner from "./components/FullPageSpinner";
import SearchResults from "./pages/SearchResults";
import WorshipHome from "./pages/WorshipHome";
import UnderDevelopment from "./pages/UnderDevelopment";
import StoreHome from "./pages/StoreHome";
import StoreSignup from "./pages/StoreSignup";
import StoreBookings from "./pages/StoreBookings";
import Signup from "./pages/Signup";
import StoreDetails from "./pages/StoreDetails";
import CookiePolicy from "./pages/CookiePolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import NotFound from "./pages/NotFound";
import Verify from "./pages/Verify";
import VerifyCustomer from "./pages/VerifyCustomer";
import ErrorPage from "./pages/Error";
import IEError from "./pages/IEError";
import Scheduler from './pages/SchedulerPage';
import BusinessProfile from "./pages/BusinessProfile";
import { AUTH_ONLY_MANAGER, NOT_IE, AUTH_ONLY_CUSTOMER } from "./router/types";
import QRScan from "./pages/QRScan";
import { UserContext } from "./context/UserContext"
import AttendeePage from "./pages/AttendeePage";
import useSession from "./hooks/use-session"
import { HOWProvider } from "./context/HOWProvider";
import { LoginPage } from "./pages/account/LoginPage";
import { ForgotPasswordPage } from "./pages/account/ForgotPasswordPage";
import { withFooter } from "./hoc/withFooter";
import { loadChurchBeforeRouteEnter } from "./router/guards/loadChurchBeforeEnter";
import { ResetPasswordPage } from "./pages/account/ResetPasswordPage";
import ProfilePage from "./pages/account/ProfilePage";
import { ReservationPage } from "./pages/reservations/ReservationsPage";
import { ManagerProvider } from "./context/ManagerContext";
import { CurrentUserProvider } from "./context/CurrentUserContext";

const GLOBAL_GUARDS = [requireLogin, notIE];

function App() {
  const [user, setUser] = useContext(UserContext)

  useSession(setUser)

  console.log(process.env.REACT_APP_ENV);
  return (
    <React.Fragment>
      <Router>
        <ScrollToTop />
        <ManagerProvider>
          <CurrentUserProvider>

            <HOWProvider>

              <GuardProvider guards={GLOBAL_GUARDS} error={ErrorPage}>
                <Switch>
                  <Redirect from="/home" to="/" />
                  <GuardedRoute
                    component={withFooter(UnderDevelopment)}
                    exact={true}
                    path="/under-development"
                    meta={{ [NOT_IE]: true }}
                  />
                  <GuardedRoute
                    component={withFooter(WorshipHome)}
                    exact={true}
                    path="/"
                    meta={{ [NOT_IE]: true }}
                  />
                  <GuardedRoute
                    component={withFooter(Signup)}
                    exact={true}
                    path="/signup"
                    meta={{ [NOT_IE]: true }}
                  />
                  <GuardedRoute
                    component={withFooter(Signup)}
                    exact={true}
                    path="/signup/:church"
                    meta={{ [NOT_IE]: true }}
                  />
                  <GuardedRoute
                    component={withFooter(SearchResults)}
                    exact={true}
                    path="/search"
                    loading={FullPageSpinner}
                    meta={{ [NOT_IE]: true }}
                  />
                  <GuardedRoute
                    component={withFooter(StoreHome)}
                    exact={true}
                    path="/space/"
                    meta={{ [NOT_IE]: true }}
                  />
                  <GuardedRoute
                    component={withFooter(StoreSignup)}
                    exact={true}
                    path="/space/signup"
                    meta={{ [NOT_IE]: true }}
                  />
                  <GuardedRoute
                    component={withFooter(LoginPage)}
                    exact={true}
                    path="/login"
                    meta={{ [NOT_IE]: true }}
                  />
                  <GuardedRoute
                    component={withFooter(LoginPage)}
                    exact={true}
                    path="/:context/login"
                    meta={{ [NOT_IE]: true }}
                  />
                  <GuardedRoute
                    component={withFooter(ForgotPasswordPage)}
                    exact={true}
                    path="/forgot-password"
                    meta={{ [NOT_IE]: true }}
                  />
                  <GuardedRoute
                    component={withFooter(ForgotPasswordPage)}
                    exact={true}
                    path="/:context/forgot-password"
                    meta={{ [NOT_IE]: true }}
                  />
                  <GuardedRoute
                    component={withFooter(ResetPasswordPage)}
                    exact={true}
                    path="/reset"
                    meta={{ [NOT_IE]: true }}
                  />
                  <GuardedRoute
                    component={withFooter(StoreDetails)}
                    path="/space/details/:context"
                    meta={{ [AUTH_ONLY_MANAGER]: true, [NOT_IE]: true, user, setUser }}
                  />
                  <GuardedRoute
                    component={withFooter(StoreDetails)}
                    path="/space/details"
                    meta={{ [AUTH_ONLY_MANAGER]: true, [NOT_IE]: true, user, setUser }}
                  />
                  <GuardedRoute
                    component={withFooter(StoreBookings)}
                    exact={true}
                    path="/space/bookings/:date/:time"
                    meta={{ [AUTH_ONLY_MANAGER]: true, [NOT_IE]: true, user, setUser }}
                  />
                  <GuardedRoute
                    component={withFooter(CookiePolicy)}
                    exact={true}
                    path="/cookie-policy"
                    meta={{ [NOT_IE]: true }}
                  />
                  <GuardedRoute
                    component={withFooter(PrivacyPolicy)}
                    exact={true}
                    path="/privacy-policy"
                    meta={{ [NOT_IE]: true }}
                  />
                  <GuardedRoute
                    component={withFooter(TermsOfUse)}
                    exact={true}
                    path="/terms-of-use"
                    meta={{ [NOT_IE]: true }}
                  />
                  <GuardedRoute
                    component={withFooter(Verify)}
                    exact={true}
                    path="/verify"
                    meta={{ [NOT_IE]: true }}
                  />
                  <GuardedRoute
                    component={withFooter(VerifyCustomer)}
                    exact={true}
                    path="/verifycode/:email/:email_verification_code"
                    meta={{ [NOT_IE]: true }}
                  />
                  <GuardedRoute
                    component={withFooter(ErrorPage)}
                    exact={true}
                    path="/error"
                    meta={{ [NOT_IE]: true }}
                  />
                  <GuardedRoute component={withFooter(IEError)} exact={true} path="/ie-error" />
                  <GuardedRoute
                    component={withFooter(ReservationPage)}
                    exact={true}
                    path="/reservation"
                    meta={{ [AUTH_ONLY_CUSTOMER]: true, [NOT_IE]: true, user, setUser }}
                  />
                  <GuardedRoute
                    component={withFooter(QRScan)}
                    exact={true}
                    path="/QRScan/:hash/:way"
                    meta={{ [NOT_IE]: true }}
                  />
                  <GuardedRoute
                    component={withFooter(ProfilePage)}
                    exact={true}
                    path="/profile"
                  />
                  <GuardedRoute
                    component={withFooter(Scheduler)}
                    exact={true}
                    path="/calendar/church/:id"
                    guards={[loadChurchBeforeRouteEnter]}
                    meta={{ [NOT_IE]: true, user, setUser }}
                  />
                  <GuardedRoute
                    component={withFooter(AttendeePage)}
                    exact={true}
                    path="/event/:id/bookings/:date"
                    meta={{ [AUTH_ONLY_MANAGER]: true, [NOT_IE]: true, user, setUser }}
                  />
                  <GuardedRoute
                    component={withFooter(AttendeePage)}
                    exact={true}
                    path="/event/:id/bookings"
                    meta={{ [AUTH_ONLY_MANAGER]: true, [NOT_IE]: true, user, setUser }}
                  />
                  <GuardedRoute
                    component={withFooter(BusinessProfile)}
                    exact={true}
                    path="/church/:id"
                    meta={{ [NOT_IE]: true, user, setUser }}
                    guards={[loadChurchBeforeRouteEnter]}
                  />
                  <GuardedRoute component={withFooter(NotFound)} path="*" />
                </Switch>
              </GuardProvider>
            </HOWProvider>
          </CurrentUserProvider>
        </ManagerProvider>
      </Router>
    </React.Fragment>
  );
}

export default App;
