import React from "react";
import {
  Box,
  Typography,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    background: theme.palette.surface.main,
    position: "fixed",
    top: 0,
    left: 0,
    height: "100vh",
    width: "100vw",
    zIndex: 2,
    "& > h2": {
      marginBottom: "40px",
    },
  },
}));

export default function FullPageSpinner({
  message = "Finding stores near you...",
}) {
  const classes = useStyles();
  return (
    <div className={classes.spinner}>
      <Typography variant="h2">
        <Box component="span" fontWeight="fontWeightLight">
          {message}
        </Box>
      </Typography>
      <CircularProgress color="primary" />
    </div>
  );
}
