import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Typography, Box, Button, Container, Grid, makeStyles } from "@material-ui/core";
import Header, { SignOutButton } from "../components/Header";
import heroBg from "../images/worship/doorsofworship.jpg";
import attendeeList from "../images/worship/Attendee_List.png";
import configCal from "../images/worship/Config_cal.png";
import QRCodes from "../images/worship/iphones_qrcodes.png";
import feedingAmerica from "../images/worship/csr_feedingamerica.jpg";
import LoginDialog from "../components/LoginDialog";
import CustomerLoginDialog from "../components/CustomerLoginDialog";
import { UserContext } from "../context/UserContext";
import { loadHOW } from "../helpers/localcache";
import { useAuth } from "../hooks/use-auth";

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundImage: `url('${heroBg}')`,
    backgroundRepeat: 'norepeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .fs20': {
      fontSize: 20
    },
    '& .fs27': {
      fontSize: 27
    },
    '& .mb18': {
      marginBottom: 18
    }
  },
  howItWorks: {
    paddingTop: 45,
    paddingBottom: 45,
    '& .text-center': {
      textAlign: 'center'
    },
    '& .mb30': {
      marginBottom: 30
    },
    '& .mb40': {
      marginBottom: 40
    },
    '& .mb65': {
      marginBottom: 65
    },
    '& .mt150': {
      marginTop: 150
    },
    '& .button': {
      marginTop: 30,
      marginBottom: 45,
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'block',
      maxWidth: 135,
    },
  },
  pledge: {
    color: '#ffffff',
    backgroundImage: `url('${feedingAmerica}')`,
  }
}))

const WorshipHome = () => {
  const [, setUser] = useContext(UserContext);
  const history = useHistory();
  const classes = useStyles();
  const auth = useAuth();

  const handleLoggedIn = (user) => {
    setUser(user);
    history.push("/space/details");
  };

  const customerLoggedIn = (data) => {
    setUser(data);
    const church = data.profile_name || loadHOW();
    if (church) {
      history.push(`/church/${church}`);
    } else {
      history.push("/reservation");
    }
  };

  const logoutUser = () => {
    setUser(null);
    window.localStorage.removeItem("keepMeLoggedIn")
    window.localStorage.clear()
    window.location.reload();
  };

  return (
    <>
      <Header>
        {
          !auth.authenticated ? <>

            {!auth.initializing && !auth.isManager && <LoginDialog onLogin={handleLoggedIn} />}
            {!auth.initializing && !auth.isCustomer && <CustomerLoginDialog onLogin={customerLoggedIn} />}

          </> :
            <SignOutButton onClick={logoutUser} />
        }
      </Header>
      <section className={classes.hero}>
        <Container>
          <Grid container spacing={2}>
            <Grid xs={12} md={6} container item alignItems="center" justify="center">
              <Typography variant="h3">
                Perfectly Distanced (PD) is a simple solution to the social distancing challenges that face congregations
              </Typography>
            </Grid>
            <Grid xs={12} md={6} direction="column" container item alignItems="center" justify="center">
              <Button href="/space/signup" className="mb18" variant="contained" color="primary">
                <Typography className="fs27" variant="h5">
                  Create your PD page
                  </Typography>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className={classes.howItWorks}>
        <Container align="center" justify="center" maxWidth="lg">
          <Typography className="text-center mb40" variant="h3">How it works</Typography>
          <Grid className="mb65" container spacing={4}>
            <Grid item md={6} xs={12}>
              <Typography variant="h4" className="mb30">
                Key Features
              </Typography>
              <Typography variant="h6">
                <Box component="span" fontWeight="fontWeightLight">
                  Manage attendance safely with scheduled arrival times
                  <br />
                  <br />
                  Simple web browser interface - no need to download an app
                  <br />
                  <br />
                  Contactless entry management Reminders & notifications
                </Box>
              </Typography>
            </Grid>
            <Grid item container justify="center" alignItems="center" xs={12} md={6}>
              <img src={attendeeList} alt="Attendee List." />
            </Grid>
          </Grid>
          <Grid className="mb65" container spacing={4}>
            <Grid item container justify="center" alignItems="center" xs={12} md={6}>
              <img src={configCal} alt="Configuration Calendar." />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography variant="h4" className="mb30">
                Easy Calendar Configuration
              </Typography>
              <Typography variant="h6">
                <Box component="span" fontWeight="fontWeightLight">
                  Add services or events (recurring and single) to your calendar
                  <br />
                  <br />
                  Designate arrival time slots and configure capacity limits
                  <br />
                  <br />
                  Option to provide dedicated slots for folk who need extra assistance/time to enter Share your calendar with congregants
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid className="mb65" container spacing={4}>
            <Grid item md={6} xs={12}>
              <Typography variant="h4" className="mb30">
                Streamlined Entry Process
              </Typography>
              <Typography variant="h6">
                <Box component="span" fontWeight="fontWeightLight">
                  Congregants reserve an entry slot ahead of time (group bookings or individual)
                  <br />
                  <br />
                  They notify their arrival and wait in the comfort of their car
                  <br />
                  <br />
                  Attendee List automatically updates their status as ‘Waiting’
                  <br />
                  <br />
                  Notify them when to ‘Approach Now’
                </Box>
              </Typography>
            </Grid>
            <Grid item container justify="center" alignItems="center" xs={12} md={6}>
              <img src={QRCodes} alt="QR Codes." />
            </Grid>
          </Grid>
          <Typography variant="h4" className="text-center mt150 mb30">
            Perfectly Distanced is a simple tool designed to give Houses of Worship the ability and confidence to welcome congregants back to worship.
          </Typography>
          <Typography variant="h6" className="text-center">
            <Box component="span" fontWeight="fontWeightLight">
              Interested? Find out more:
            </Box>
          </Typography>
          <Button href="mailto:sales@perfectlydistanced.com?subject=Perfectly Distanced – Inquiry" className="button" variant="contained" color="primary">
            Get in touch!
          </Button>
        </Container>
      </section>
      <section className={`${classes.hero} ${classes.pledge}`}>
        <Container>
          <Grid container spacing={2}>
            <Grid xs={12} md={6} item align="center">
              <Typography variant="h3">
                Covid-19 has hit many in our communities hard so we pledge to donate 10% of Perfectly Distanced’s profits to{' '}
                <a href="https://feedingamerica.org" target="_blank" style={{ color: 'currentColor' }} rel="noopener noreferrer">
                  feedingamerica.org
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} ></Grid>
          </Grid>
        </Container>
      </section>
    </>
  )
}

export default WorshipHome;
