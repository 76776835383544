import React from "react";
import {
    makeStyles} from "@material-ui/core";
import { NavBar } from "../../components/NavBar";
import { ProfileEditForm } from "./ProfileEditForm";


const useStyles = makeStyles((theme) => ({
    profile: {
        minHeight: 'calc(100vh - 168px)',
        paddingTop: '3rem',
        backgroundColor: theme.palette.surface.grey
    },
}));


export default function ProfilePage() {
    const classes = useStyles()

    return (
        <>
            <NavBar context='profile' />
            <section className={classes.profile}>

                <ProfileEditForm />

            </section>
        </>
    )
}
