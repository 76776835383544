import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  makeStyles,
  Table,
  TableHead,
  TableCell,
  Button,
  Typography,
  TableBody,
  Dialog,
  IconButton,
  DialogContent,
  TableRow,
  Snackbar,
  CircularProgress,
  FormControlLabel,
  Grid,
  Checkbox,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import {
  useDeleteSubscription,
  useGetPrivateClientSecret,
  useCreateSubscription,
} from "../../hooks/use-fetch";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiPaper-root.MuiDialog-paper": {
      padding: 20,
      backgroundColor: theme.palette.surface.lightBlue,
    },
    "&  .MuiFormControlLabel-root.items-start": {
      margin: "20px 0",
    },
    "& button": {
      display: "block",
      width: "100%",
      marginBottom: 28,
      "&.close": {
        width: "24px",
        height: "24px",
        padding: 0,
        margin: "12px 12px 16px auto",
      },
    },
    "& .MuiFormControlLabel-labelPlacementBottom": {
      alignItems: "flex-start",
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
      marginBottom: 16,
      "& .MuiInputBase-formControl": {
        marginBottom: 4,
      },
    },
    "& .fs12": {
      "& .MuiFormControlLabel-label": {
        fontSize: "12px",
      },
    },
    "& .MuiInputBase-root.MuiFilledInput-root": {
      marginBottom: 16,
      borderRadius: "4px",
      backgroundColor: "#ffffff",
      border: "1px solid transparent",
      "&:focus-within": {
        borderColor: theme.palette.secondary.main,
      },
      "& input": {
        padding: 16,
      },
    },
    "& .StripeElement": {
      width: "100%",
      padding: 16,
      backgroundColor: "#ffffff",
      borderRadius: 4,
      marginBottom: 4,
      border: "1px solid transparent",
      "&.StripeElement--focus": {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  account: {
    position: "relative",
    height: "100%",
    "& .full-width-spinner": {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .title": {
      marginBottom: 16,
      textAlign: "left",
    },
    "& .text-medium": {
      color: theme.palette.text.medium,
      fontSize: 16,
    },
    "& .buttons": {
      marginTop: 16,
      display: "flex",
      alignItems: "center",
      "& > button": {
        marginRight: 16,
      },
    },
    "& table": {
      "& thead": {
        "& th": {
          backgroundColor: "rgba(255,255,255,0.38)",
        },
      },
      "& tbody": {
        "& td": {
          backgroundColor: "#ffffff",
        },
      },
      "& p": {
        margin: 0,
      },
      "& .right": {
        textAlign: "right",
      },
    },
    "& .cancel, & .change-card": {
      marginTop: 20,
    },
  },
}));

export default function AccountTab({ data, loading, refetch }) {
  const classes = useStyles();
  const [newCardError, setNewCardError] = useState(null);
  const [newCardLoading, setNewCardLoading] = useState(false);
  const [paymentAck, setPaymentAck] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [
    { error: clientSecretError },
    getClientSecret,
  ] = useGetPrivateClientSecret();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [
    { data: cancelData, loading: cancelLoading, error: cancelError },
    cancelSubscription,
  ] = useDeleteSubscription();
  const [
    { data: subscribeData, error: subscribeError },
    subscribe,
  ] = useCreateSubscription();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (!newCardLoading) {
      if (clientSecretError) {
        setSnackbarSeverity("error");
        setSnackbarMessage(clientSecretError.message);
        setSnackbarOpen(true);
      }

      if (subscribeError) {
        setSnackbarSeverity("error");
        setSnackbarMessage(subscribeError.message);
        setSnackbarOpen(true);
      }

      if (newCardError) {
        setSnackbarSeverity("error");
        setSnackbarMessage(newCardError.message);
        setSnackbarOpen(true);
      }

      if (subscribeData) {
        setSnackbarSeverity("success");
        setSnackbarMessage("Card changed.");
        setSnackbarOpen(true);
      }
    }
  }, [
    clientSecretError,
    newCardLoading,
    subscribeData,
    subscribeError,
    newCardError,
  ]);

  useEffect(() => {
    if (!cancelLoading) {
      if (cancelData) {
        setSnackbarSeverity("success");
        setSnackbarMessage("Subscription Canceled.");
        setSnackbarOpen(true);
      }

      if (cancelError) {
        setSnackbarSeverity("error");
        setSnackbarMessage(cancelError.message);
        setSnackbarOpen(true);
      }
    }
  }, [cancelLoading, cancelError, cancelData]);

  const handleCancelSubscription = async () => {
    await cancelSubscription();
    await refetch();
  };

  const handleAlertClose = () => {
    setSnackbarOpen(false);
  };

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleCardSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) return;
    const email = e.target.email;
    const billingDetails = {
      name: e.target.nameOnCard.value,
      email,
      address: {
        postal_code: e.target.cardZipCode.value,
      },
    };
    setNewCardLoading(true);

    if (data && data.brand) {
      await cancelSubscription();
    }

    const clientSecret = await getClientSecret();

    if (
      clientSecret.error ||
      !clientSecret.data ||
      !clientSecret.data.client_secret
    ) {
      setNewCardLoading(false);
      return;
    }

    // 5. Create card for client
    const result = await stripe.confirmCardSetup(
      clientSecret.data.client_secret,
      {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: billingDetails,
        },
      }
    );

    if (result.error) {
      setNewCardError(result.error);
      setNewCardLoading(false);
      return;
    }

    const subscribeRes = await subscribe();

    setNewCardLoading(false);

    if (!subscribeRes.error) {
      setDialogOpen(false);
      await refetch();
    }
  };

  const handlePaymentAck = (e) => {
    setPaymentAck(e.target.checked);
  };

  return (
    <section className={classes.account} style={{marginBottom: 10, marginTop: 10}}>
      <Container maxWidth="xs">
        {loading ? (
          <div className="full-width-spinner">
            <CircularProgress color="primary" />
          </div>
        ) : (
          <>
            {data && data.brand && (
              <>
                <Typography variant="h4" className="title">
                  First month <span className="uppercase">free!</span>
                </Typography>
                <p className="text-medium">
                  Your free first month started on:{" "}
                  <Box component="span" fontWeight="fontWeightBold">
                    {new Date(data.start_date * 1000).toLocaleDateString()}{" "}
                  </Box>
                  We will alert you one week before trial ends and billing
                  starts.
                </p>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <p>Credit Card</p>
                      </TableCell>
                      <TableCell>
                        <p className="right">Expires</p>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <p className="text-medium">
                          <span style={{ textTransform: "capitalize" }}>
                            {data.brand}
                          </span>{" "}
                          ({data.last4})
                        </p>
                      </TableCell>
                      <TableCell>
                        <p className="text-medium right">
                          {data.exp_month}/{data.exp_year}
                        </p>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </>
            )}
            <Button
              fullWidth
              color="primary"
              variant="contained"
              className="change-card"
              id="form-dialog-title"
              onClick={handleClickOpen}
            >
              {data && data.brand ? "Change card" : "Add card"}
            </Button>
            <Dialog
              open={dialogOpen}
              onClose={handleDialogClose}
              aria-labelledby="form-dialog-title"
              className={classes.dialog}
              disableBackdropClick
            >
              <IconButton className="close" onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
              <DialogContent>
                <form onSubmit={handleCardSubmit}>
                  <FormControlLabel
                    control={<CardNumberElement />}
                    className="fs12"
                    label="Card number*"
                    required
                    labelPlacement="bottom"
                  />
                  <Grid container spacing={2}>
                    <Grid item sm={4}>
                      <FormControlLabel
                        control={<CardExpiryElement />}
                        className="fs12"
                        label="Expiration Date*"
                        required
                        labelPlacement="bottom"
                      />
                    </Grid>
                    <Grid item sm={4}>
                      <FormControlLabel
                        control={<CardCvcElement />}
                        className="fs12"
                        label="Security Code (CVV)*"
                        required
                        labelPlacement="bottom"
                      />
                    </Grid>
                    <Grid item sm={4}>
                      <FormControlLabel
                        control={
                          <TextField
                            InputProps={{ disableUnderline: true }}
                            name="cardZipCode"
                            fullWidth
                            variant="filled"
                            required
                          />
                        }
                        className="fs12"
                        label="Zip Code*"
                        required
                        labelPlacement="bottom"
                      />
                    </Grid>
                  </Grid>
                  <TextField
                    InputProps={{ disableUnderline: true }}
                    placeholder="Name on card"
                    name="nameOnCard"
                    fullWidth
                    variant="filled"
                    required
                  />
                  <FormControlLabel
                    name="useThisPaymentMethod"
                    control={
                      <Checkbox
                        value={paymentAck}
                        onChange={handlePaymentAck}
                      />
                    }
                    label="I acknowledge that this payment method will be used for the monthly subscription service to Perfectly Distanced. This subscription and payment method can be cancelled or changed at any time in your User Account."
                    labelPlacement="end"
                    className="items-start"
                  />
                  <Button
                    disabled={!paymentAck}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    style={{ display: "flex" }}
                    endIcon={
                      newCardLoading && (
                        <CircularProgress
                          style={{ width: 20, height: 20, color: "#ffffff" }}
                        />
                      )
                    }
                  >
                    Submit
                  </Button>
                </form>
              </DialogContent>
            </Dialog>
            {data && data.brand && (
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                className="cancel"
                onClick={handleCancelSubscription}
                endIcon={
                  cancelLoading && (
                    <CircularProgress
                      style={{ width: 20, height: 20, color: "#ffffff" }}
                    />
                  )
                }
              >
                Cancel subscription
              </Button>
            )}
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleAlertClose}
            >
              <Alert
                elevation={6}
                variant="filled"
                onClose={handleAlertClose}
                severity={snackbarSeverity}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </>
        )}
      </Container>
    </section>
  );
}
