import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

let theme = createMuiTheme({
  overrides: {
    MuiButton: {
      containedSecondary: {
        backgroundColor: "#7FDCEB",
        "&:hover": {
          backgroundColor: "#00B8D4",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#D81B60",
    },
    secondary: {
      main: "#00B8D4",
      light: "#7FDCEB",
    },
    surface: {
      main: "#FFFFFF",
      light: "#FFFFFF",
      dark: "#F3F3F3",
      grey: "#F0F0F0",
      lightBlue: "#E0F6FA",
      blueDark: "#0087A2",
    },
    text: {
      high: "rgba(0,0,0,0.87)",
      medium: "rgba(0,0,0,0.6)",
      disabled: "rgba(0,0,0,0.38)",
    },
  },
});

theme = responsiveFontSizes(theme)

export default theme;
