import * as React from 'react';
import PerfectlyDistancedScheduler from '../components/Scheduler/scheduler';
import { makeStyles, Paper, Typography } from '@material-ui/core';
import { useRouteParam } from '../hooks/query';
import { setHOW } from '../helpers/localcache';
import { NavBar } from '../components/NavBar';
import { SchedulerStoreProvider } from '../context/SchedulerContext';

const useStyles = makeStyles(() => ({
    content: {

    },
    title: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 30,
        paddingBottom: 30,
        backgroundColor: '#7FDCEB'
    }
}));

export default ({ church }) => {
    console.log(church)
    const classes = useStyles();
    const storeId = useRouteParam(params => params.id);

    React.useEffect(() => {
        setHOW(storeId);
    }, [storeId]);

    return (
        <SchedulerStoreProvider store={church}>
            <NavBar context='calendar' />
            <section className={classes.content}>
                <Paper className={classes.title} elevation={2} square>
                    <Typography variant='h4'>{church.name}</Typography>
                </Paper>

                <PerfectlyDistancedScheduler store={church} readonly={true} />
            </section>
        </SchedulerStoreProvider>
    );
}
