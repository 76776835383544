import * as React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import {
    AppointmentTooltip
} from '@devexpress/dx-react-scheduler-material-ui';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ListIcon from '@material-ui/icons/List';
import moment from 'moment';
import { ReserveButton } from './reserve-button';
import { isInThePast } from '../../helpers/date';
import { SchedulerStore } from '../../context/SchedulerContext';

const useStyles = makeStyles(() => ({
    header: {
        display: 'flex',
        alignItems: 'center'
    }
}));

export const getAppointmentTooltipHeader = (onFullscreenClick, readonly) => (props) => {
    const classes = useStyles();
    const schedule = React.useContext(SchedulerStore);
    let waitlistLink = `/event/${props.appointmentData.id}/bookings`;

    if (props.appointmentData.rRule) {
        waitlistLink = `${waitlistLink}/${moment(props.appointmentData.startDate).format('YYYY-MM-DD')}`
    }

    let reserveLabel = 'RESERVE';
    if (!schedule.isAvailable(props.appointmentData)) {
        reserveLabel = 'This service is fully booked.'
    }
    if (isInThePast(props.appointmentData.startDate)) {
        reserveLabel = 'This service has finished.'
    }



    return (
        <AppointmentTooltip.Header
            {...props}
            className={classes.header}
            appointmentData={props.appointmentData}
        >
            {!readonly && <IconButton
                href={waitlistLink}
            >
                <ListIcon />
            </IconButton>}
            {readonly && <IconButton
                onClick={() => onFullscreenClick(props.appointmentData)}
            >
                <VisibilityIcon />
            </IconButton>}
            {readonly &&
                <ReserveButton
                    label={reserveLabel}
                    onClick={() => onFullscreenClick(props.appointmentData)}
                    disabled={!schedule.isAvailable(props.appointmentData) || isInThePast(props.appointmentData.startDate)}
                />
            }
        </AppointmentTooltip.Header>
    );
}
