import React, { useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles, Container } from "@material-ui/core";
import Header, { ReservationsButton, SignOutButton  } from "../components/Header";
import LoginDialog from "../components/LoginDialog";
import SearchStoresForm from "../components/SearchStoresForm";
import Steps from "../components/Steps";
import Results from "../components/Results";
import WhatHappens from "../components/WhatHappens";
import { UserContext } from "../context/UserContext";

const useStyles = makeStyles((theme) => ({
  search: {
    backgroundColor: theme.palette.secondary.main,
    minHeight: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function SearchResults() {
  const location = useLocation();
  const history = useHistory();
  const [user, setUser] = useContext(UserContext);
  const [search, setSearch] = useState(location.state);
  const classes = useStyles();

  const handleSearch = (e) => {
    setSearch(e);
  };

  const handleRedirect = (user) => {
    setUser(user);
    history.push("/space/details");
  };
  
  const goToReservation = () => {
    history.push("/reservation");
  };

  const logoutUser = () => {
    setUser(null);
    //window.localStorage.removeItem("keepMeLoggedIn")
    window.localStorage.clear()
    history.push("/");
  };

  return (
    <>
      <Header>
        {!user && <LoginDialog onLogin={handleRedirect} />}
        {user && <ReservationsButton onClick={goToReservation}/>}
        {user && <SignOutButton onClick={logoutUser}/>}
        {/* <LanguageDropdown /> */}
      </Header>
      <section className={classes.search}>
        <Container>
          <SearchStoresForm
            defaultSearch={location.state || search}
            onClick={handleSearch}
          />
        </Container>
      </section>
      <Results
        results={(search && search.stores && search.stores.data) || []}
      />
      <Steps step={1} />
      <WhatHappens />
    </>
  );
}
