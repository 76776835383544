
import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiFormControl-root.MuiTextField-root": {
      backgroundColor: "rgba(0,0,0,0.16)",
      borderRadius: "4px",
      marginBottom: 16,
      "& input": {
        padding: "16px 16px 19px",
      },
      border: "1px solid transparent",
      "&:focus-within": {
        borderColor: theme.palette.secondary.main,
      },
    },
    "& button": {
      display: "block",
      width: "100%",
      marginBottom: 28,
      "&.close": {
        width: "24px",
        height: "24px",
        padding: 0,
        margin: "12px 12px 16px auto",
      },
    },
    "& h4": {
      marginBottom: 16,
    },
    "& a, & .link-button": {
      display: "block",
      textAlign: "center",
      marginBottom: 28,
      fontSize: "0.875rem",
      cursor: "pointer",
      border: 0,
      padding: 0,
      textDecoration: "underline",
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      "&.inline": {
        display: "inline",
      },
    },
    "& label": {
      display: "block",
      marginBottom: "30px",
    },
    "& .text-medium": {
      color: theme.palette.text.medium,
    },
    "& .centered": {
      textAlign: "center",
    },
    "& .MuiDialogActions-root": {
      justifyContent: "center",
      paddingTop: 30,
      paddingBottom: 40,
      borderTop: "1px solid rgba(0,0,0, 0.12)",
    },
    "& .MuiCircularProgress-root.MuiCircularProgress-colorPrimary.MuiCircularProgress-indeterminate": {
      color: "currentColor",
    },
    "& .MuiButton-label": {
      display: "flex",
      alignItems: "center",
    },
  },
}));

export default function BookingConfirmDialog({ disabled, onConfirm, label }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <>
      <Button
        variant="text"
        color="primary"
        onClick={handleClickOpen}
        disabled={disabled}
      >
        {label}
      </Button>
      <Dialog
        className={classes.dialog}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
      >
        <IconButton className="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography id="form-dialog-title" variant="h4">
            Are you sure you want to book this time?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={handleClose}>
            No, continue browsing
          </Button>
          <Button color="primary" variant="contained" onClick={onConfirm}>
            Yes, book this time
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
