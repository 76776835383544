import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import StoreIcon from "@material-ui/icons/Store";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { makeStyles, CircularProgress } from "@material-ui/core";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ffffff",
    "&::before": {
      borderBottom: 0,
    },
    position: "relative",
  },
  inputRoot: {
    fontSize: "16px",
    width: "100%",
  },
  icon: {
    color: theme.palette.primary.main,
    margin: "16px",
  },
  iconButton: {
    position: "absolute",
    padding: 0,
    right: 0,
    margin: "16px",
    transition: "transform 0.2s ease-in-out",
    "&.upside-down": {
      transform: "rotate(180deg)",
    },
  },
}));

const getInputValue = (values) => {
  const allIndex = values.findIndex((value) => value.store_id === "-1");
  if (allIndex !== -1) {
    return values[allIndex].name;
  } else if (values.length > 1) {
    return `${values[0].name} (+ ${values.length - 1})`;
  } else if (values.length > 0) {
    return `${values[0].name}`;
  } else {
    return "";
  }
};

export default function StoreSelector({
  options,
  onChange,
  values = [],
  loading,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  if (loading)
    return (
      <div className={classes.autocomplete}>
        <InputBase
          disabled
          startAdornment={<StoreIcon className={classes.icon} />}
          className={classes.inputRoot}
          placeholder="Loading stores"
        />
        <CircularProgress
          style={{ width: 20, height: 20, marginRight: 16 }}
          color="primary"
        />
      </div>
    );

  return (
    <Autocomplete
      multiple
      open={open}
      id="store-selector"
      options={options}
      value={values}
      getOptionSelected={(option, value) => {
        return option && option.store_id === value.store_id;
      }}
      onChange={(e, v, r) => {
        const { dataset } = e.target.parentElement.parentElement;
        const index = parseInt(dataset.index);
        if (index === 0) {
          onChange(e.target.checked ? options : []);
        } else {
          if (!e.target.checked) {
            onChange(v.filter((option) => option.store_id !== "-1"));
          } else {
            onChange(v);
          }
        }
      }}
      disableCloseOnSelect
      autoComplete
      getOptionLabel={(option) => option.name}
      renderOption={(option, { selected }) => {
        const index = options.findIndex(
          (opt) => opt.store_id === option.store_id
        );

        return (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              data-index={index}
            />
            {option.name}
          </React.Fragment>
        );
      }}
      renderInput={(params) => {
        return (
          <div className={classes.autocomplete}>
            <InputBase
              startAdornment={
                <StartAdornment classes={classes} values={values} />
              }
              className={classes.inputRoot}
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              onFocus={() => setOpen(true)}
              onBlur={() => setOpen(false)}
              style={{ paddingRight: 50 }}
              placeholder="Which business(es)?"
            />
            <IconButton
              onClick={handleOpen}
              className={`${classes.iconButton} ${open ? "upside-down" : ""}`}
            >
              <ArrowDropDownIcon />
            </IconButton>
          </div>
        );
      }}
    />
  );
}

const StartAdornment = ({ values, classes }) => {
  return (
    <React.Fragment>
      <StoreIcon className={classes.icon} />
      <p style={{ whiteSpace: "nowrap", margin: "0 12px 0 0" }}>
        {getInputValue(values)}
      </p>
    </React.Fragment>
  );
};
