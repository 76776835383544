import React from "react";
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  section: {
    padding: "85px 0",
    "& h5": {
      marginBottom: 20,
      marginTop: 20,
    },
  },
  uppercase: {
    textTransform: "uppercase",
  },
  link: {
    color: "#000000",
  },
  container: {
    paddingLeft: 40,
    paddingRight: 40,
  },
}));

export default function StoreBenefits() {
  const classes = useStyles();
  return (
    <section className={classes.section}>
      <Container className={classes.container}>
        <Grid container spacing={10}>
          <Grid item xs={12} md={8}>
            <Typography variant="h4" color="primary">
              Benefits of participating
            </Typography>
            <Typography variant="h5">
              <Box component="span" fontWeight="fontWeightLight">
                1. Help those people who are unable to wait in line to shop
              </Box>
            </Typography>
            <Typography variant="h5">
              <Box component="span" fontWeight="fontWeightLight">
                2. Provide a better experience for all your customers
              </Box>
            </Typography>
            <Typography variant="h5">
              <Box component="span" fontWeight="fontWeightLight">
                3. Help your community stay safe with social distancing
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h4" color="primary">
              Caring matters
            </Typography>
            <Typography variant="h5">
              Sadly, not everyone can afford to shop at your store, so we pledge
              to donate 10% of our profits to{" "}
              <a
                className={classes.link}
                href="https://www.feedingamerica.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                feedingamerica.org
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
