import React, { useState } from "react";
import {
  Container,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import SortIcon from "@material-ui/icons/Sort";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { daysOfWeek, months, days } from "../helpers/date";
import StoreRow from './StoreRow'

const useStyles = makeStyles((theme) => ({
  results: {
    "& ul": {
      padding: 0,
      listStyleType: "none",
    },
    "& th.small": {
      maxWidth: "max-content",
    },
    "& .iconButton": {
      padding: 0,
    },
    "& .MuiTableCell-sizeSmall": {
      width: 30,
    },
    "& .date": {
      color: theme.palette.text.medium,
    },
    "& .flex": {
      display: "flex",
      "& .mb0": {
        marginBottom: "0px !important",
      },
      "& .mr5": {
        marginRight: 5,
      },
      "& .logo": {
        width: 100,
        height: 100,
        objectFit: "contain",
        marginRight: 40,
      },
      "&.columns": {
        flexDirection: "column",
        "& h5": {
          marginBottom: 15,
        },
        "& p": {
          marginTop: 0,
          marginBottom: 8,
        },
        "& .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1": {
          fontSize: 12,
        },
      },
    },
  },
}));

export default function Results({ results }) {
  const classes = useStyles();
  const [dayCount, setDayCount] = useState(0);

  const addDayCount = () => {
    setDayCount(dayCount + 7);
  };

  const substractDaycount = () => {
    if (dayCount > 6) {
      setDayCount(dayCount - 7);
    }
  };

  return (
    <section className={classes.results}>
      <Container>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <IconButton>
                  <SortIcon />
                </IconButton>
                Sort businesses by distance
              </TableCell>
              <TableCell align="center" size="small" className="iconButton">
                <IconButton onClick={substractDaycount}>
                  <ChevronLeftIcon />
                </IconButton>
              </TableCell>
              {daysOfWeek(dayCount).map((date) => (
                <TableCell
                  align="center"
                  size="small"
                  key={date.toLocaleDateString("en-US")}
                >
                  <div className="flex columns">
                    <p className="mb0">{days[date.getDay()]}</p>
                    <div className="flex date">
                      <p className="mr5">{months[date.getMonth()]}</p>
                      <p>{date.getDate()}</p>
                    </div>
                  </div>
                </TableCell>
              ))}
              <TableCell align="center" size="small" className="iconButton">
                <IconButton onClick={addDayCount}>
                  <ChevronRightIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((result) => (
              <StoreRow store={result} key={`store-${result.store_id}-${dayCount}`} dates={daysOfWeek(dayCount)} />
            ))}
          </TableBody>
        </Table>
      </Container>
    </section>
  );
}
