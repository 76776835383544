import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import StoreHero from "../components/StoreHome/StoreHero";
import StoreSteps from "../components/StoreHome/StoreSteps";
import StoreBenefits from "../components/StoreHome/StoreBenefits";
import StoreTellCustomers from "../components/StoreHome/StoreTellCustomers";
import Header, {
  CustomerSiteButton,
} from "../components/Header";
import LoginDialog from "../components/LoginDialog";
import { UserContext } from "../context/UserContext";

export default function StoreHome() {
  const [user, setUser] = useContext(UserContext);
  const history = useHistory();

  const handleLoggedIn = (user) => {
    setUser(user);
    history.push("/space/details");
  };

  return (
    <>
      <Header>
        {!user && <LoginDialog onLogin={handleLoggedIn} />}
        <CustomerSiteButton />
        {/* <LanguageDropdown /> */}
      </Header>
      <StoreHero />
      <StoreSteps />
      <StoreBenefits />
      <StoreTellCustomers />
    </>
  );
}
