import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Snackbar,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import StoreIcon from '@material-ui/icons/Store'
import CustomerLoginDialog from '../components/CustomerLoginDialog'
import BookingConfirmDialog from '../components/BookingConfirmDialog'
import { UserContext } from '../context/UserContext'
import { useGetCalendar, useCreateBooking } from '../hooks/use-fetch'
import { parseToYYYYMMDD } from '../helpers/date'

const useStyles = makeStyles((theme) => ({
  stores: {
    '& ul': {
      padding: 0,
      listStyleType: 'none',
    },
    '& th.small': {
      maxWidth: 'max-content',
    },
    '& .iconButton': {
      padding: 0,
    },
    '& .MuiTableCell-sizeSmall': {
      width: 30,
    },
    '& .date': {
      color: theme.palette.text.medium,
    },
    '& .flex': {
      display: 'flex',
      '&.centered': {
        alignItems: 'center',
        justifyContent: 'center',
      },
      '& .mb0': {
        marginBottom: '0px !important',
      },
      '& .mr5': {
        marginRight: 5,
      },
      '& .logo': {
        width: 100,
        height: 100,
        objectFit: 'contain',
        marginRight: 40,
      },
      '&.columns': {
        flexDirection: 'column',
        '& h5': {
          marginBottom: 15,
        },
        '& p': {
          marginTop: 0,
          marginBottom: 8,
        },
        '& .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
          fontSize: 12,
        },
      },
    },
  },
}))

export default function StoreRow({ store, dates }) {
  const classes = useStyles()
  const [user, setUser] = useContext(UserContext)
  const history = useHistory()
  const [showAll, setShowAll] = useState(false)
  const [snackbar, setSnackbar] = useState({ message: '', open: false, severity: 'error' })
  const [{ data: bookingsData, }] = useGetCalendar(
    store.store_id,
    parseToYYYYMMDD(dates[0]),
    parseToYYYYMMDD(dates[dates.length - 1])
  )
  const [, createBooking] = useCreateBooking()

  const handleTimeslotSelected = async ({ date, time, result, user }) => {
    let endTime = parseInt(time.split(':')[0]) + 1
    endTime = endTime > 9 ? `${endTime}:00` : `0${endTime}:00`
    const { error: createError } = await createBooking({
      params: {
        customer_email: user.username,
        store_id: store.store_id,
        date,
        start_time: time,
        end_time: endTime
      }
    })
  
    if (!createError) {
      history.push('/reservation', { date, time, result })
    } else {
      setSnackbar({ message: createError.message, open: true, severity: 'error' })
    }
  }

  return (
    <TableRow className={classes.stores}>
      <TableCell>
        <Snackbar open={snackbar.open}>
          <Alert variant="filled" severity={snackbar.severity} elevation={6}>
            {snackbar.message}
          </Alert>
        </Snackbar>
        <div className='flex'>
          {store.logo ? (
            <img className='logo' src={store.logo} alt={store.name} />
          ) : (
            <StoreIcon className='logo' fontSize='large' color='primary' />
          )}
          <div className='flex columns'>
            <Typography variant='h5'>
              <Box fontWeight='fontWeightLight' component='span'>
                {store.name}
              </Box>
            </Typography>
            <p>
              {store.street}, {store.city}, {store.state} {store.zip}
            </p>
            <p>{store.distance}</p>
            <FormControlLabel
              control={<Checkbox />}
              label="Don't show this store again"
              labelPlacement='end'
              className='items-start'
            />
          </div>
        </div>
      </TableCell>
      {/* Empty to use the space for the buttons in header */}
      <TableCell>
        <ul>{}</ul>
      </TableCell>
      {bookingsData &&
        bookingsData.data &&
        Object.keys(bookingsData.data).map((key) => (
          <TableCell key={key}>
            <ul>
              {Object.keys(bookingsData.data[key].slots).map((time, i) => {
                if (!showAll && i === 5) {
                  return (
                    <li key={`${key}-show-more`}>
                      <Button
                        variant='text'
                        color='primary'
                        onClick={() => setShowAll(true)}
                      >
                        Show more
                      </Button>
                    </li>
                  )
                }
                if (showAll || i < 5) {
                  return (
                    <li key={`${key}-${time}`}>
                      {user ? (
                        <BookingConfirmDialog
                          variant='text'
                          color='primary'
                          disabled={
                            !bookingsData.data[key].slots[time].isAvailable ||
                            bookingsData.data[key].slots[time].max_capacity === 0
                          }
                          onConfirm={() =>
                            handleTimeslotSelected({
                              time,
                              store,
                              date: key,
                              user: user,
                            })
                          }
                          label={time}
                        />
                      ) : (
                        <CustomerLoginDialog
                          label={time}
                          onLogin={(data) => {
                            setUser({ ...data })
                            handleTimeslotSelected({
                              time,
                              store,
                              date: key,
                              user: {...data}
                            })
                          }}
                          disabled={
                            !bookingsData.data[key].slots[time].isAvailable
                          }
                          color='primary'
                          variant='text'
                        />
                      )}
                    </li>
                  )
                }

                return <React.Fragment key={`${key}-${time}`}></React.Fragment>
              })}
            </ul>
          </TableCell>
        ))}
      {/* Empty to use the space for the buttons in header */}
      <TableCell></TableCell>
    </TableRow>
  )
}
