import * as React from 'react';
import Header, { AuthCancelButton } from "../../components/Header";
import { makeStyles, Paper, Typography, Grid, Container, Hidden } from '@material-ui/core';
import { CurrentUserContext } from '../../context/CurrentUserContext';
import { ResetPasswordForm } from './ResetPasswordForm';
import { Redirect } from 'react-router-dom';
import { loadHOW } from '../../helpers/localcache';
const useClasses = makeStyles(theme => ({
    title: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 31.5,
        paddingBottom: 31.5,
        backgroundColor: '#7FDCEB',
        fontSize: 48,
        height: 120
    }
}));

export const ResetPasswordPage = () => {
    const classes = useClasses();

    return (
        <>
            <Header>
                <AuthCancelButton />
            </Header>
            <Paper className={classes.title} elevation={2} square>
                <Typography variant='h4'>Reset Password</Typography>
            </Paper>
            <Container style={{ minHeight: 300, marginTop: 30 }}>

                <CurrentUserContext.Consumer>
                    {
                        ({ user }) => {
                            return !user ?
                                <Grid container spacing={2}>
                                    <Hidden smDown>
                                        <Grid item md={4}></Grid>
                                    </Hidden>
                                    <Grid xs={12} md={4}>
                                        <ResetPasswordForm />
                                    </Grid>
                                    <Hidden smDown>
                                        <Grid item md={4}></Grid>
                                    </Hidden>
                                </Grid>

                                : <Redirect to={`/church/${loadHOW()}`} />
                        }
                    }
                </CurrentUserContext.Consumer>
            </Container>
        </>
    );
}
