import React, { useState } from "react";
import Header from "../components/Header";
import {
  makeStyles,
  Container,
  Typography,
  Box,
  Button,
  Dialog,
  DialogContent,
  CircularProgress,
  IconButton,
  TextField,
} from "@material-ui/core";
import { Store as StoreIcon, Close as CloseIcon } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import { useMarketing } from "../hooks/use-fetch";

import Banner from "../images/banner.jpg";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiFormControl-root.MuiTextField-root": {
      backgroundColor: "rgba(0,0,0,0.16)",
      borderRadius: "4px",
      marginBottom: 16,
      border: "1px solid transparent",
      "& input": {
        padding: "16px 16px 19px",
      },
      "&:focus-within": {
        borderColor: theme.palette.secondary.main,
      },
    },
    "& button": {
      display: "block",
      width: "100%",
      marginBottom: 28,
      "&.close": {
        width: "24px",
        height: "24px",
        padding: 0,
        margin: "12px 12px 16px auto",
      },
    },
    "& h4": {
      marginBottom: 16,
    },
    "& a": {
      display: "block",
      textAlign: "center",
      marginBottom: 28,
      color: theme.palette.secondary.main,
      "&.inline": {
        display: "inline",
      },
    },
    "& label": {
      display: "block",
      marginBottom: "30px",
    },
    "& .MuiDialogActions-root": {
      justifyContent: "center",
      paddingTop: 30,
      paddingBottom: 40,
      borderTop: "1px solid rgba(0,0,0, 0.12)",
    },
    "& .MuiCircularProgress-root.MuiCircularProgress-colorPrimary.MuiCircularProgress-indeterminate": {
      color: "currentColor",
    },
    "& .MuiButton-label": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiDialogContent-root": {
      paddingBottom: 25,
    },
    "& .mb16": {
      marginBottom: 16,
    },
  },
  root: {
    backgroundImage: `url(${Banner})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "right bottom",
    minHeight: "calc(100vh - 162px)",
    textAlign: "center",
    paddingTop: 113,
    paddingBottom: 113,
    color: "#ffffff",
    position: "relative",
    "& h3": {
      marginBottom: 48,
    },
    "& h5": {
      marginBottom: 30,
    },
    "& .container": {
      position: "relative",
      zIndex: 10,
    },
    "& .overlay": {
      top: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.2)",
      position: "absolute",
    },
  },
}));

export default function Home() {
  const classes = useStyles();
  const [{ data, loading }, marketing] = useMarketing();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    marketing({
      params: {
        email: e.target.email.value,
        name: e.target.name.value,
      },
    });
  };

  return (
    <>
      <Header>LAUNCHING SOON...</Header>
      <div className={classes.root}>
        <div className="overlay"></div>
        <Container className="container" maxWidth="md">
          <Typography variant="h3">
            <Box fontWeight="fontWeightLight">
              Making life easier during this pandemic
            </Box>
          </Typography>
          <Typography variant="h5">
            No one likes to queue and many disabled folk, senior citizens and
            young families simply cannot endure long waits at the door.
          </Typography>
          <Typography variant="h5">
            Perfectly Distanced provides stores a way to manage access and
            capacity, and customers a way to avoid the queue by reserving a time
            to shop.
          </Typography>

          <Button
            startIcon={<StoreIcon />}
            onClick={handleClickOpen}
            color="primary"
            variant="contained"
          >
            learn more
          </Button>
          <Dialog
            className={classes.dialog}
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <IconButton className="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <Typography id="form-dialog-title" className="mb16" variant="h6">
                <Box component="span" fontWeight="fontWeightLight">
                  Perfectly Distanced is glad to welcome you as a future user.
                  We are currently in the process of signing up stores. We
                  anticipate being ready for a full user experience shortly. In
                  the meantime, to sign up for our newsletter please enter your
                  name and email address and we'll keep you posted on our
                  progress.
                </Box>
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  InputProps={{ disableUnderline: true }}
                  placeholder="Email"
                  id="email"
                  type="email"
                  name="email"
                  fullWidth
                  required
                />
                <TextField
                  InputProps={{ disableUnderline: true }}
                  placeholder="Name"
                  id="name"
                  type="text"
                  name="name"
                  fullWidth
                  required
                />
                <Button
                  color="secondary"
                  variant="contained"
                  type="submit"
                  endIcon={
                    loading && (
                      <CircularProgress style={{ width: 20, height: 20 }} />
                    )
                  }
                >
                  Subscribe
                </Button>
                {data && (
                  <Alert variant="filled" severity="success" elevation={2}>
                    Successfully subscribed!
                  </Alert>
                )}
              </form>
            </DialogContent>
          </Dialog>
        </Container>
      </div>
    </>
  );
}
