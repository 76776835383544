import { NOT_IE } from "../types";

const notIE = (to, from, next) => {
  if (to.meta[NOT_IE]) {
    if (!!window.MSInputMethodContext && !!document.documentMode) {
      return next.redirect("ie-error");
    }
  }

  next();
};

export default notIE;
