import React, { createContext } from "react";
import { CUSTOMER_BASE_URL, API_VERSION, useGetCustomer, useUpdateCustomer } from "../hooks/use-fetch"
import FullPageSpinner from "../components/FullPageSpinner";
import { useHistory } from "react-router-dom";
import { loadHOW } from "../helpers/localcache";

export const CurrentUserContext = createContext({ user: undefined });

export const CurrentUserProvider = ({ children }) => {
    const history = useHistory();
    const [user, setUser] = React.useState();
    const [initializing, setInitializing] = React.useState(true);
    const [{ loading: profileLoading, data: profileData }] = useGetCustomer();
    const [, updateCustomer] = useUpdateCustomer()
    const [profile, setProfile] = React.useState({});

    React.useEffect(() => {
        setProfile(profileData)
    }, [profileData])


    React.useEffect(() => {
        const loadUser = async () => {
            try {
                const params = {
                    AccessToken: localStorage.getItem('AccessToken'),
                    RefreshToken: localStorage.getItem('RefreshToken'),
                    Username: localStorage.getItem('username')
                }
                const endpoint = `${CUSTOMER_BASE_URL}/${API_VERSION}/customer/check`
                const blob = await fetch(endpoint, {
                    method: "POST",
                    body: JSON.stringify(params)
                });
                const res = await blob.json();
                if (res && res.sessionResponse && res.sessionResponse.newAccessToken) {
                    setUser({ AccessToken: res.sessionResponse.newAccessToken })
                }
                setInitializing(false);
            } catch (error) {
                setInitializing(false);
            }

        }
        loadUser();
    }, []);

    const logout = () => {
        localStorage.removeItem('AccessToken');
        localStorage.removeItem('RefreshToken');
        localStorage.removeItem('username');

        setUser(undefined);
        setProfile(null);
        history.replace(!!loadHOW() ? `/church/${loadHOW()}` : '/')
    }

    const onLogin = (data) => {
        setUser(data);
    }

    const update = async (values) => {
        const response = await updateCustomer({ params: values });
        if (!response.error) {
            const phone = profile.phone_number;
            setProfile({...profile, ...values});
            response.phoneChanged = values.phone_number !== phone;
        }

        return response;
    }

    return (<CurrentUserContext.Provider value={{
        user, profile, logout, initializing, login: onLogin, update, profileLoading
    }}>
        {initializing ? <FullPageSpinner message='' /> : children}
    </CurrentUserContext.Provider>);
}

