import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Select, MenuItem, Button, IconButton, Menu } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ListIcon from "@material-ui/icons/ListAlt";
import CalendarIcon from "@material-ui/icons/DateRange";
import SettingsIcon from "@material-ui/icons/Settings";
import HomeIcon from "@material-ui/icons/Home";
import { CurrentUserContext } from "../context/CurrentUserContext";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import classNames from 'classnames';
import { HOWContext } from "../context/HOWProvider";
import { useRouteParam } from "../hooks/query";
import { loadHOW } from "../helpers/localcache";
import { store } from "../helpers/use-context";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    minHeight: "60px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 40px",
    "& .colorWhite": {
      color: "#ffffff",
    },
    "& .primary": {
      color: theme.palette.primary.main,
    },
    "&.blue": {
      backgroundColor: theme.palette.surface.blueDark,
      "& svg": {
        color: "#ffffff",
      },
      "& .cancel-button, & .my-profile-button, & .signout-button, & .calendar-button, & .about-button, & .account-button, & .my-reservations-button": {
        color: "#ffffff",
        '&.active': {
          color: theme.palette.primary.main
        }
      },
    },
    "& svg": {
      color: theme.palette.primary.main,
      maxWidth: 300,
      "&.logo": {
        minWidth: 260,
      },
    },
    "& svg.close": {
      color: theme.palette.text.medium,
    },
  },
  select: {
    color: theme.palette.secondary.main,
    "& svg": {
      color: theme.palette.secondary.main,
    },
    "& > div": {
      paddingTop: "0",
      paddingBottom: "0",
    },
    "&::before": {
      borderBottom: 0,
    },
  },
  nav: {
    display: "none",
    alignItems: "center",
    justifyContent: "space-evenly",
    "& > *": {
      marginLeft: "20px",
    },
    "@media(min-width: 761px)": {
      display: "flex",
    },
  },
  menuButton: {
    "@media(min-width: 761px)": {
      display: "none",
    },
  },
}));

export default function Header({ blue, children }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <header className={`${blue ? "blue" : ""} ${classes.header}`}>
      {/* <Link style={{ textDecoration: "none" }} > */}
      <svg id="Perfectly_DISTANCED_logotype" data-name="Perfectly DISTANCED logotype" xmlns="http://www.w3.org/2000/svg" width="272" height="21.678" viewBox="0 0 272 21.678">
        <g id="Group_1" data-name="Group 1" transform="translate(-67.943 -22.073)">
          <g id="Group_2" data-name="Group 2">
            <path id="Path_2" data-name="Path 2" d="M70.521,33.063v5.994H67.943V23.416h5.231a6.889,6.889,0,0,1,2.165.349,5.054,5.054,0,0,1,1.713.967,4.392,4.392,0,0,1,1.123,1.525,4.9,4.9,0,0,1,.4,2.025,4.707,4.707,0,0,1-.4,1.977,4.369,4.369,0,0,1-1.123,1.5,5.016,5.016,0,0,1-1.713.956,6.977,6.977,0,0,1-2.165.344Zm0-2.084h2.653a3.227,3.227,0,0,0,1.171-.2,2.594,2.594,0,0,0,.881-.553,2.381,2.381,0,0,0,.559-.849,2.92,2.92,0,0,0,.193-1.074,3.188,3.188,0,0,0-.193-1.128,2.462,2.462,0,0,0-1.44-1.461,3.31,3.31,0,0,0-1.171-.215H70.521Z" />
            <path id="Path_3" data-name="Path 3" d="M85.11,39.271a6.233,6.233,0,0,1-2.352-.429,5.429,5.429,0,0,1-1.821-1.182,5.293,5.293,0,0,1-1.176-1.777,5.8,5.8,0,0,1-.419-2.219v-.44a6.611,6.611,0,0,1,.446-2.493,5.889,5.889,0,0,1,1.192-1.89,5.129,5.129,0,0,1,1.74-1.2,5.311,5.311,0,0,1,2.09-.419,5.516,5.516,0,0,1,2.234.424,4.423,4.423,0,0,1,1.617,1.192,5.256,5.256,0,0,1,.983,1.821,7.661,7.661,0,0,1,.333,2.31v1.085H81.92a3.839,3.839,0,0,0,.333,1.257,3.386,3.386,0,0,0,.7,1.005,3.189,3.189,0,0,0,1.02.666,3.333,3.333,0,0,0,1.284.241,4.171,4.171,0,0,0,1.8-.387A3.541,3.541,0,0,0,88.4,35.77l1.4,1.31a4.308,4.308,0,0,1-.72.812,5.019,5.019,0,0,1-1.031.7,6.209,6.209,0,0,1-1.327.494A6.493,6.493,0,0,1,85.11,39.271ZM84.8,29.292a2.552,2.552,0,0,0-1,.193,2.515,2.515,0,0,0-.827.564,3.246,3.246,0,0,0-.618.9A4.416,4.416,0,0,0,82,32.16h5.446v-.193a3.693,3.693,0,0,0-.2-.994,2.654,2.654,0,0,0-.489-.854,2.346,2.346,0,0,0-.811-.6A2.7,2.7,0,0,0,84.8,29.292Z" />
            <path id="Path_4" data-name="Path 4" d="M99.56,27.219q.311,0,.623.021c.207.015.406.036.6.065s.363.059.521.091a2.329,2.329,0,0,1,.4.113l-.365,2.524A10.5,10.5,0,0,0,99,29.765a3.635,3.635,0,0,0-2.079.537A3.143,3.143,0,0,0,95.757,31.8v7.262H93.189V27.434H95.6l.118,1.815a5.363,5.363,0,0,1,1.681-1.488A4.374,4.374,0,0,1,99.56,27.219Z" />
            <path id="Path_5" data-name="Path 5" d="M106.382,39.057V29.346h-3.126V27.434h3.126v-.72a5.437,5.437,0,0,1,.339-1.993,3.823,3.823,0,0,1,.977-1.45,4.29,4.29,0,0,1,1.542-.892,6.3,6.3,0,0,1,2.03-.306,8.551,8.551,0,0,1,1.413.113q.67.112,1.4.263l-.225,2.041a9.212,9.212,0,0,0-.978-.209,7.808,7.808,0,0,0-1.256-.092,2.724,2.724,0,0,0-2.025.656,2.564,2.564,0,0,0-.64,1.869v.72h4.319v1.912H108.96v9.711Z" />
            <path id="Path_6" data-name="Path 6" d="M120.757,39.271a6.234,6.234,0,0,1-2.353-.429,5.429,5.429,0,0,1-1.821-1.182,5.323,5.323,0,0,1-1.176-1.777,5.82,5.82,0,0,1-.419-2.219v-.44a6.631,6.631,0,0,1,.446-2.493,5.907,5.907,0,0,1,1.192-1.89,5.123,5.123,0,0,1,1.741-1.2,5.31,5.31,0,0,1,2.089-.419,5.51,5.51,0,0,1,2.234.424,4.406,4.406,0,0,1,1.617,1.192,5.24,5.24,0,0,1,.983,1.821,7.629,7.629,0,0,1,.333,2.31v1.085h-8.057a3.839,3.839,0,0,0,.333,1.257,3.386,3.386,0,0,0,.7,1.005,3.193,3.193,0,0,0,1.021.666,3.328,3.328,0,0,0,1.283.241,4.172,4.172,0,0,0,1.8-.387,3.557,3.557,0,0,0,1.337-1.063l1.4,1.31a4.3,4.3,0,0,1-.719.812,5.026,5.026,0,0,1-1.032.7,6.22,6.22,0,0,1-1.326.494A6.5,6.5,0,0,1,120.757,39.271Zm-.312-9.979a2.559,2.559,0,0,0-1,.193,2.515,2.515,0,0,0-.827.564,3.246,3.246,0,0,0-.618.9,4.416,4.416,0,0,0-.359,1.208h5.446v-.193a3.733,3.733,0,0,0-.2-.994,2.636,2.636,0,0,0-.489-.854,2.336,2.336,0,0,0-.811-.6A2.7,2.7,0,0,0,120.445,29.292Z" transform="translate(0.01)" />
            <path id="Path_7" data-name="Path 7" d="M132.306,37.23a2.831,2.831,0,0,0,.918-.15,2.63,2.63,0,0,0,.79-.419,2.034,2.034,0,0,0,.542-.634,1.555,1.555,0,0,0,.188-.8h2.428a3.218,3.218,0,0,1-.381,1.575,4.209,4.209,0,0,1-1.064,1.283,5.426,5.426,0,0,1-3.4,1.192,5.6,5.6,0,0,1-2.352-.462,4.759,4.759,0,0,1-1.687-1.261,5.4,5.4,0,0,1-1.015-1.859,7.333,7.333,0,0,1-.338-2.251v-.386a7.384,7.384,0,0,1,.338-2.256,5.34,5.34,0,0,1,1.015-1.859,4.858,4.858,0,0,1,1.687-1.262,5.523,5.523,0,0,1,2.341-.467,5.723,5.723,0,0,1,1.95.322,4.682,4.682,0,0,1,1.547.9,4.058,4.058,0,0,1,1.01,1.386,4.172,4.172,0,0,1,.349,1.762h-2.428a2.123,2.123,0,0,0-.677-1.622,2.513,2.513,0,0,0-.784-.494,2.6,2.6,0,0,0-.988-.183,2.409,2.409,0,0,0-2.2,1.187,3.7,3.7,0,0,0-.457,1.209,7.014,7.014,0,0,0-.134,1.375v.386a7.065,7.065,0,0,0,.134,1.386,3.717,3.717,0,0,0,.457,1.209,2.408,2.408,0,0,0,2.213,1.186Z" transform="translate(0.013)" />
            <path id="Path_8" data-name="Path 8" d="M144.252,24.619v2.815h4.394v1.912h-4.394v5.575a3.215,3.215,0,0,0,.156,1.085,1.705,1.705,0,0,0,.435.687,1.56,1.56,0,0,0,.656.361,3.15,3.15,0,0,0,.827.1,6.449,6.449,0,0,0,.693-.037q.358-.038.7-.086t.623-.107c.189-.039.349-.077.478-.113l.29,1.772a3.94,3.94,0,0,1-.671.3,6.005,6.005,0,0,1-.854.22c-.309.057-.629.1-.962.134s-.661.048-.983.048a5.021,5.021,0,0,1-1.6-.241,3.2,3.2,0,0,1-1.246-.752,3.488,3.488,0,0,1-.817-1.31,5.552,5.552,0,0,1-.295-1.918V29.346h-2.836V27.434h2.836V24.619Z" transform="translate(0.015)" />
            <path id="Path_9" data-name="Path 9" d="M151.461,22.557h6.285V36.93h3.545v2.127h-9.83V36.93h3.7V24.694h-3.7Z" transform="translate(0.018)" />
            <path id="Path_10" data-name="Path 10" d="M167.479,34.4l.441,1.4,3.266-8.368h2.846l-5.886,13.384a8.241,8.241,0,0,1-.5.952,4.851,4.851,0,0,1-.768.95,3.969,3.969,0,0,1-1.091.736,3.493,3.493,0,0,1-1.482.295,4.45,4.45,0,0,1-.715-.059q-.36-.06-.639-.134l.366-2c.049,0,.118,0,.2,0s.175.009.268.016.181.013.263.017.149.005.2.005a1.1,1.1,0,0,0,.671-.22,2.836,2.836,0,0,0,.521-.505,3.651,3.651,0,0,0,.371-.559c.1-.182.167-.306.21-.371l.762-1.439L161.84,27.434h2.836Z" transform="translate(0.021)" />
            <path id="Path_11" data-name="Path 11" d="M184.307,39.057V23.416h4.372a7.007,7.007,0,0,1,2.69.5,6.048,6.048,0,0,1,2.117,1.439,6.615,6.615,0,0,1,1.38,2.245,8.239,8.239,0,0,1,.494,2.922v1.429a8.236,8.236,0,0,1-.494,2.922,6.482,6.482,0,0,1-1.386,2.24,6.083,6.083,0,0,1-2.137,1.434,7.25,7.25,0,0,1-2.75.505Zm1.321-14.513V37.939h2.965a5.667,5.667,0,0,0,2.266-.434,4.863,4.863,0,0,0,1.719-1.225,5.456,5.456,0,0,0,1.09-1.89,7.38,7.38,0,0,0,.382-2.434V30.5a7.363,7.363,0,0,0-.387-2.454,5.407,5.407,0,0,0-1.091-1.875,4.819,4.819,0,0,0-1.691-1.2,5.543,5.543,0,0,0-2.2-.424Z" transform="translate(0.025)" />
            <path id="Path_12" data-name="Path 12" d="M205.673,39.057h-1.321V23.416h1.321Z" transform="translate(0.029)" />
            <path id="Path_13" data-name="Path 13" d="M224.117,35.211a2.947,2.947,0,0,0-.209-1.144,2.417,2.417,0,0,0-.709-.913,5.34,5.34,0,0,0-1.316-.758,17.882,17.882,0,0,0-2.041-.687,18.926,18.926,0,0,1-2.127-.725,6.6,6.6,0,0,1-1.606-.919,3.792,3.792,0,0,1-1.021-1.213,3.44,3.44,0,0,1-.359-1.612,3.5,3.5,0,0,1,.386-1.643,3.79,3.79,0,0,1,1.085-1.273,5.255,5.255,0,0,1,1.665-.827,7.215,7.215,0,0,1,2.116-.3,6.443,6.443,0,0,1,2.23.365,5.116,5.116,0,0,1,1.691,1,4.383,4.383,0,0,1,1.075,1.483,4.454,4.454,0,0,1,.376,1.826h-1.332a3.67,3.67,0,0,0-.269-1.413,3.117,3.117,0,0,0-.784-1.122A3.689,3.689,0,0,0,221.7,24.6a5.093,5.093,0,0,0-1.719-.269,5.693,5.693,0,0,0-1.7.231,3.706,3.706,0,0,0-1.224.623,2.647,2.647,0,0,0-.743.919,2.567,2.567,0,0,0-.251,1.117,2.527,2.527,0,0,0,.209,1.031,2.4,2.4,0,0,0,.7.875,5.5,5.5,0,0,0,1.3.752,14.054,14.054,0,0,0,1.988.661,18.707,18.707,0,0,1,2.2.752,6.536,6.536,0,0,1,1.633.962,3.753,3.753,0,0,1,1.009,1.272,3.817,3.817,0,0,1,.344,1.665,3.61,3.61,0,0,1-.4,1.714,3.823,3.823,0,0,1-1.117,1.284,5.226,5.226,0,0,1-1.7.805,7.852,7.852,0,0,1-2.149.279,8.433,8.433,0,0,1-1.4-.118,7.429,7.429,0,0,1-1.354-.359,5.844,5.844,0,0,1-1.213-.618,4.2,4.2,0,0,1-.978-.9,4.14,4.14,0,0,1-.654-1.192,4.353,4.353,0,0,1-.243-1.5h1.322a3.193,3.193,0,0,0,.408,1.671,3.321,3.321,0,0,0,1.053,1.1,4.5,4.5,0,0,0,1.45.608,7.084,7.084,0,0,0,1.611.187,6.126,6.126,0,0,0,1.66-.209,3.984,3.984,0,0,0,1.278-.6,2.68,2.68,0,0,0,.816-.929A2.558,2.558,0,0,0,224.117,35.211Z" transform="translate(0.032)" />
            <path id="Path_14" data-name="Path 14" d="M243.829,24.544h-5.36V39.057h-1.322V24.544H231.8V23.416h12.031Z" transform="translate(0.036)" />
            <path id="Path_15" data-name="Path 15" d="M259.717,34.674h-7.1l-1.611,4.383h-1.386l5.919-15.641H256.8l5.919,15.641h-1.375Zm-6.693-1.128H259.3l-3.137-8.519Z" transform="translate(0.04)" />
            <path id="Path_16" data-name="Path 16" d="M282.007,39.057h-1.321l-8.992-13.385V39.057h-1.332V23.416h1.332l9,13.4v-13.4h1.311Z" transform="translate(0.044)" />
            <path id="Path_17" data-name="Path 17" d="M302.406,34.18a6.94,6.94,0,0,1-.558,2.137,4.778,4.778,0,0,1-1.156,1.6,4.948,4.948,0,0,1-1.74,1.005,7.078,7.078,0,0,1-2.3.348,5.659,5.659,0,0,1-2.417-.5,5.3,5.3,0,0,1-1.858-1.434,6.589,6.589,0,0,1-1.192-2.229,9.334,9.334,0,0,1-.419-2.879V30.259a9.376,9.376,0,0,1,.419-2.884,6.481,6.481,0,0,1,1.2-2.229,5.393,5.393,0,0,1,1.886-1.435,5.87,5.87,0,0,1,2.481-.51,6.876,6.876,0,0,1,2.245.344,4.78,4.78,0,0,1,1.714,1,5.006,5.006,0,0,1,1.149,1.612,6.7,6.7,0,0,1,.553,2.153h-1.332a6.47,6.47,0,0,0-.429-1.691,3.649,3.649,0,0,0-.843-1.252,3.61,3.61,0,0,0-1.289-.773,5.3,5.3,0,0,0-1.768-.269,4.371,4.371,0,0,0-1.955.424,4.19,4.19,0,0,0-1.461,1.2,5.5,5.5,0,0,0-.918,1.864,8.64,8.64,0,0,0-.317,2.422v1.988a8.9,8.9,0,0,0,.3,2.379,5.7,5.7,0,0,0,.88,1.88,4.093,4.093,0,0,0,1.429,1.23,4.156,4.156,0,0,0,1.944.44,4.4,4.4,0,0,0,3.126-.993,4.756,4.756,0,0,0,1.3-2.981Z" transform="translate(0.057)" />
            <path id="Path_18" data-name="Path 18" d="M319.6,31.559h-7.315v6.38H320.7v1.118h-9.732V23.416h9.678v1.128h-8.357v5.9H319.6Z" transform="translate(0.057)" />
            <path id="Path_19" data-name="Path 19" d="M328.832,39.057V23.416H333.2a7.012,7.012,0,0,1,2.691.5,6.057,6.057,0,0,1,2.117,1.439,6.631,6.631,0,0,1,1.38,2.245,8.239,8.239,0,0,1,.494,2.922v1.429a8.236,8.236,0,0,1-.494,2.922,6.5,6.5,0,0,1-1.386,2.24,6.1,6.1,0,0,1-2.138,1.434,7.25,7.25,0,0,1-2.75.505Zm1.321-14.513V37.939h2.965a5.675,5.675,0,0,0,2.267-.434A4.863,4.863,0,0,0,337.1,36.28a5.47,5.47,0,0,0,1.089-1.89,7.38,7.38,0,0,0,.382-2.434V30.5a7.362,7.362,0,0,0-.387-2.454,5.4,5.4,0,0,0-1.09-1.875,4.832,4.832,0,0,0-1.692-1.2,5.539,5.539,0,0,0-2.2-.424Z" transform="translate(0.057)" />
          </g>
        </g>
      </svg>
      {/* </Link> */}

      {children && (
        <>
          <IconButton
            className={classes.menuButton}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleOpenMenu}
            style={{ width: 48 }}
          >
            <MenuIcon className={`${blue ? "colorWhite" : "primary"}`} />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            {React.Children.map(children, (child, i) => (
              <MenuItem key={`header-menu-item-${i}`} onClick={handleCloseMenu}>
                {child}
              </MenuItem>
            ))}
          </Menu>

          <nav className={classes.nav}>
            {React.Children.map(children, (child, i) => (
              <React.Fragment key={`header-child-${i}`}>{child}</React.Fragment>
            ))}
          </nav>
        </>
      )}
    </header>
  );
}

export const CancelButton = ({ onClick }) => (
  <Button
    variant=""
    endIcon={<CloseIcon className="close" />}
    className="cancel-button"
    onClick={onClick}
    color=""
  >
    cancel
  </Button>
);

export const AuthCancelButton = () => {
  const context = useRouteParam(x => x.context);
  const history = useHistory();

  const cancelUrl = React.useMemo(() => {
    return !!loadHOW() && context !== store ? `/church/${loadHOW()}` : '/';
  }, [context]);

  return < CancelButton onClick={() => history.push(cancelUrl)} />
}

export const LanguageDropdown = () => {
  const classes = useStyles();

  return (
    <Select value="EN" color="secondary" className={classes.select}>
      <MenuItem value="EN">EN</MenuItem>
    </Select>
  );
};

export const ReservationsButton = ({ onClick, active }) => (
  <Button
    endIcon={<ListIcon />}
    className={classNames("my-reservations-button", { active })}
    // style={{ textTransform: "capitalize" }}
    href='/reservation'
    color={active ? 'primary' : 'default'}
  >
    Reservations
  </Button>
);

export const ProfileButton = ({ onClick, active }) => (
  <Button
    endIcon={<AccountCircleIcon />}
    className="my-profile-button"
    // style={{ textTransform: "capitalize" }}
    color={active ? 'primary' : 'default'}
    onClick={onClick}
  >
    Profile
  </Button>
);

export const ManagerProfileButton = ({ active }) => (
  <Button
    endIcon={<AccountCircleIcon />}
    className="my-profile-button"
    color={active ? 'primary' : 'default'}
    href='/space/details/profile'
  >
    Profile
  </Button>
);

export const SignOutButton = ({ onClick }) => (
  <Button
    className="signout-button"
    endIcon={<OpenInNewIcon />}
    onClick={onClick}
  >
    sign out
  </Button>
);

export const CalendarButton = ({ active }) => {
  return (
    <HOWContext.Consumer>
      {
        ({ how }) => !!how ? <Button
          className="calendar-button"
          endIcon={<CalendarIcon />}
          href={`/calendar/church/${how}`}
          color={active ? 'primary' : 'default'}
        >
          Calendar
      </Button> : null
      }
    </HOWContext.Consumer>
  );
};

export const ChurchCalendarButton = ({ active }) => {
  return (
    <Button
      className="calendar-button"
      endIcon={<CalendarIcon />}
      href={`/space/details/calendar`}
      color={active ? 'primary' : 'default'}
    >
      Calendar
    </Button>);
};

export const AboutButton = ({ active }) => {
  return (
    <HOWContext.Consumer>
      {
        ({ how }) => !!how ? (<Button
          color={active ? 'primary' : 'default'}
          className="about-button"
          endIcon={<HomeIcon />}
          href={`/church/${how}`}
        >
          My House of worship
        </Button>) : null
      }
    </HOWContext.Consumer>
  )
}

export const ChurchAboutButton = ({ active }) => {
  return (
    <Button
      color={active ? 'primary' : 'default'}
      className="about-button"
      endIcon={<HomeIcon />}
      href={`/space/details/about`}
    >
      About
    </Button>
  )
}

export const AccountButton = () => (
  <Button
    className="account-button"
    endIcon={<SettingsIcon />}
    href='/account'
  >
    Account
  </Button>
);

export const ChurchAccountButton = ({ active }) => (
  <Button
    color={active ? 'primary' : 'default'}
    className="account-button"
    endIcon={<SettingsIcon />}
    href='/space/details/account'
  >
    Account
  </Button>
);

export const CustomerSiteButton = () => (
  <Link to="/" style={{ textDecoration: "none" }}>
    <Button color="secondary">Customer Site</Button>
  </Link>
);

export const LogoutOutButton = ({ onClick }) => {
  const history = useHistory();

  const logout = () => {
    if (onClick) {
      onClick();
    }
    localStorage.removeItem('username');
    localStorage.getItem('AccessToken');
    localStorage.getItem('RefreshToken');
    history.replace('/');
  }

  return (
    <Button
      className="signout-button"
      endIcon={<OpenInNewIcon />}
      onClick={logout}
    >
      sign out
    </Button>
  );
}

export const LoginButton = () => {
  return (
    <CurrentUserContext.Consumer>
      {
        ({ user, login }) => <Button startIcon={<AccountCircleIcon />} onClick={login}>
          LOGIN
        </Button>
      }
    </CurrentUserContext.Consumer>
  )
}


