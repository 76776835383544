import * as React from 'react';
import moment from 'moment';
import {
    useCreateEvent, useUpdateEvent, useDeleteEvent,
    useEventsCalendar, useCheckEventBookingsCount
} from '../hooks/use-fetch';
import { Dialog, DialogContent, CircularProgress, Snackbar } from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import { useCreateEventBooking } from '../hooks/use-fetch';

export const SchedulerStore = React.createContext({ errors: {}, loading: true, events: [], snackbar: {} });
export const { Provider } = SchedulerStore;

const fromApiModel = (input) => {
    input.title = input.name;
    input.id = Number(input.event_id);
    input.allDay = input.all_day;
    input.startDate = moment(input.startDate).local();
    input.endDate = moment(input.endDate).local();

    if (input.exDate) {
        input.exDate = input.exDate.split(',').map(x => moment(x).toISOString()).join(',')
    }
    return input;
}

export const SchedulerStoreProvider = ({ children, store }) => {
    const [state, setState] = React.useState({ loading: false, events: [], snackbar: {} })
    const [, addEvent] = useCreateEvent();
    const [, updateEvent] = useUpdateEvent();
    const [, deleteEvent] = useDeleteEvent();
    const [, getEvents,] = useEventsCalendar(Number(store.store_id));
    const [, makeReservation] = useCreateEventBooking();
    const [, checkEventBookingsCount] = useCheckEventBookingsCount();
    const [errors, setErrors] = React.useState({});

    React.useEffect(() => {

        const load = async () => {
            try {
                setState({ ...state, loading: false });
                const response = await getEvents();
                if (!response.error) {
                    setState({ ...state, loading: false, events: response.data.filter(x => !!x.startDate).map(e => fromApiModel(e)) })
                } else {
                    setState({ ...state, loading: false, error: 'Whooops!!' });
                }
            } catch (error) {
                console.log(error)
                setState({ ...state, loading: false, error: 'Whooops!!' });
            }
        }

        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAvailability = (event) => {
        let reservationCount = 0;
        const info = (event.eventInfo || []).filter(x => {
            const eventDate = moment(event.startDate).format('YYYY-MM-DD');
            const reservationDate = x.date.split('T')[0];
            return moment(eventDate).isSame(reservationDate, 'day');
        });
        if (!!info) {
            reservationCount = info.reduce((sum, i) => sum + Number(i.head_count), 0);
        }
        const availability = (!!info ? event.capacity - reservationCount : event.capacity);

        return availability >= 0 ? availability : 0;
    }

    const isAvailable = (event) => getAvailability(event) > 0;

    const cancel = async (id) => {
        setState({ ...state, loading: true });
        const response = await deleteEvent({ url: `event/${id}` });
        if (!response.error) {
            setState({
                ...state,
                loading: false,
                snackbar: { open: true, severity: 'success', message: 'Successfully cancelled event' },
                events: state.events.filter(event => event.id !== id)
            });
        } else {
            setState({
                ...state,
                loading: false,
                snackbar: { open: true, severity: 'error', message: 'Failed to cancel event, try again.' },
            });
        }
    }

    const create = async (params) => {
        setState({ ...state, loading: true });
        const response = await addEvent({ params });
        if (!response.error) {

            setState({
                ...state,
                loading: false,
                events: [...state.events, fromApiModel(response.data)],
                snackbar: { open: true, severity: 'success', message: 'Successfully created event' }
            });
        } else {
            setState({
                ...state,
                loading: false,
                events: [...state.events, fromApiModel(response.data)],
                snackbar: { open: true, severity: 'error', message: 'Failed to create event. Please, try again.' }
            });

        }
    }

    const update = async (event) => {
        setState({ ...state, loading: true });
        const response = await updateEvent({ params: event });
        if (!response.error) {
            setState({
                ...state,
                loading: false,
                snackbar: { open: true, severity: 'success', message: `Successfully ${event.id_delete ? 'canceled' : 'updated'} event` },
                events: state.events.map(ev => (
                    ev.event_id === event.event_id ? {...ev, ...fromApiModel(response.data)} : ev))
            });

        } else {
            setState({
                ...state,
                loading: false,
                snackbar: { open: true, severity: 'error', message: 'Failed to update event. Please, try again' }
            });

        }
    }

    const reserve = async (params) => {
        setState({ ...state, loading: true });
        const response = await makeReservation({ ...params });
        if (!response.error) {

            setState({
                ...state,
                loading: false,
                events: state.events.map(ev => ev.event_id === params.event_id ? { ...ev, eventInfo: [...ev.eventInfo, { ...params, date: params.startDate }] } : ev)
            });
            return true
        } else {
            setState({ ...state, loading: false, snackbar: { severity: 'error', open: true, message: response.error.errorMessage || 'Failed to make reservation' } });
            return false;
        }
    }

    const updateOneOccurence = async (added, updated) => {
        setState({ ...state, loading: true });
        const updateResponse = await updateEvent({ params: updated });
        const addResponse = await addEvent({ params: added });
        if (!updateResponse.error && !addResponse.error) {
            setState({
                ...state,
                loading: false,
                snackbar: { open: true, severity: 'success', message: 'Successfully updated event' },
                events: [...state.events.map(ev => (
                    ev.event_id === updateResponse.data.event_id ? fromApiModel(updateResponse.data) : ev)), fromApiModel(addResponse.data)]
            });

        } else {
            setState({
                ...state,
                loading: false,
                snackbar: { open: true, severity: 'error', message: 'Failed to update event. Please, try again' }
            });
        }
    }

    const hasBookings = async (id, date) => {
        let url = `event/${id}/bookingcount`;
        url = date ? `${url}/${date}` : url;
        const response = await checkEventBookingsCount({ url });
        return Number(response.data) > 0;
    }

    const closeSpinner = () => setState({ ...state, loading: false });
    const closeSnack = () => setState({ ...state, snackbar: {} });
    return <Provider
        value={{
            ...state,
            cancel,
            update,
            create,
            reserve,
            hasBookings,
            updateOneOccurence,
            errors,
            setErrors,
            isAvailable,
            getAvailability
        }}
    >
        <Snackbar
            open={state.snackbar.open}
            autoHideDuration={5000}
            onClose={closeSnack}
        >
            <Alert
                elevation={6}
                variant="filled"
                onClose={closeSnack}
                severity={state.snackbar.severity}
            >
                {state.snackbar.message}
            </Alert>
        </Snackbar>
        <Dialog open={state.loading} onClose={closeSpinner}>
            <DialogContent style={{ minWidth: 150, display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                <CircularProgress />
            </DialogContent>
        </Dialog>
        {children}
    </Provider>
}