import React from "react";
import LockIcon from "@material-ui/icons/Lock";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import SignUpCard from "./SignUpCard";

import {
  Checkbox,
  FormControlLabel,
  TextField,
  Grid,
  Box,
  Typography,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  secondaryColor: {
    color: theme.palette.text.medium,
  },
  uppercase: {
    textTransform: "uppercase",
  },
  link: {
    color: "unset",
  },
  hr: {
    marginTop: 20,
    marginBottom: 20,
  },
  h5: {
    marginBottom: 10,
  },
}));

export default function YourDetailsCard({ setPaymentAck, paymentAck }) {
  const classes = useStyles();

  const handlePaymentAck = (e) => {
    setPaymentAck(e.target.checked);
  };

  return (
    <SignUpCard title="Payment">
      <Typography variant="h5" className={classes.h5}>
        <Box component="span" fontWeight="fontWeightLight">
          First month <span className={classes.uppercase}>free!</span>
        </Box>
      </Typography>
      <Typography variant="h6">
        You will be billed a $25 monthly subscription fee AFTER your one month trial has ended, which covers all the services included with Perfectly Distanced
      </Typography>
      <p className={classes.secondaryColor}>
        For group subscriptions, please contact us at{" "} 
        <a className={classes.link} href="mailto:info@perfectlydistanced.com">
        info@perfectlydistanced.com
        </a>
      </p>
      <p className={classes.secondaryColor}>
        Please provide your billing information now.{" "}
        <strong>
          We will not start billing you until after your 1 month free service is
          up.
        </strong>{" "}
        You can cancel your subscription at any time.
      </p>
      <hr className={classes.hr} />
      <span className="flex items-center">
        <LockIcon />
        <p>Credit / Debit Card Details</p>
      </span>

      <FormControlLabel
        control={<CardNumberElement />}
        className="fs12"
        label="Card number*"
        required
        labelPlacement="bottom"
      />
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <FormControlLabel
            control={<CardExpiryElement />}
            className="fs12"
            label="Expiration Date*"
            required
            labelPlacement="bottom"
          />
        </Grid>
        <Grid item sm={4}>
          <FormControlLabel
            control={<CardCvcElement />}
            className="fs12"
            label="Security Code (CVV)*"
            required
            labelPlacement="bottom"
          />
        </Grid>
        <Grid item sm={4}>
          <FormControlLabel
            control={
              <TextField
                InputProps={{ disableUnderline: true }}
                name="cardZipCode"
                fullWidth
                variant="filled"
                required
              />
            }
            className="fs12"
            label="Zip Code*"
            required
            labelPlacement="bottom"
          />
        </Grid>
      </Grid>
      <TextField
        InputProps={{ disableUnderline: true }}
        placeholder="Name on card"
        name="nameOnCard"
        fullWidth
        variant="filled"
        required
      />
      <FormControlLabel
        name="useThisPaymentMethod"
        value={paymentAck}
        onChange={handlePaymentAck}
        control={<Checkbox />}
        label="Use this credit card as payment method for Perfectly Distanced’s monthly subscription service"
        labelPlacement="end"
        className="items-start"
      />
    </SignUpCard>
  );
}
