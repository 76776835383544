import { useEffect } from "react";

export default function useMount(callback, cleanup = () => {}) {
  useEffect(() => {
    if (callback) {
      callback();
    }

    return cleanup;
    //eslint-disable-next-line
  }, []);
}
