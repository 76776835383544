import React, { useState } from "react";
import SignUpCard from "./SignUpCard";
import {
  TextField,
  Grid,
  Select,
  Input,
  MenuItem,
  makeStyles,
  ListItemText,
} from "@material-ui/core";
import ImageInput from "../ImageInput";
import stateOptions from "../../data/states.json";
import useHandlePhone from "../../hooks/use-handlephone";

const useStyles = makeStyles((theme) => ({
  placeholder: {
    color: theme.palette.text.disabled,
  },
}));

export default function YourDetailsCard({ handleLogo }) {
  const classes = useStyles();
  const [states, setStates] = useState([]);
  const [storeName, setStoreName] = useState("");
  const [mobile, handlePhone] = useHandlePhone();

  const handleChange = (e) => {
    setStates(e.target.value);
  };

  const handleStoreName = (e) => {
    setStoreName(e.target.value);
  };

  return (
    <SignUpCard title="House of Worship">
      <TextField
        InputProps={{ disableUnderline: true }}
        name="storeName"
        fullWidth
        variant="filled"
        placeholder="Name*"
        onInput={handleStoreName}
        required
      />
      <TextField
        InputProps={{ disableUnderline: true }}
        name="storeStreet"
        fullWidth
        variant="filled"
        placeholder="Street address and unit number*"
        required
      />
      <TextField
        InputProps={{ disableUnderline: true }}
        name="storeCity"
        fullWidth
        variant="filled"
        placeholder="City*"
        required
      />
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <Select
            fullWidth
            value={states}
            name="storeState"
            onChange={handleChange}
            displayEmpty
            input={
              <Input placeholder="State*" fullWidth disableUnderline={true} />
            }
            renderValue={(selected) =>
              states.length > 0 ? (
                selected
              ) : (
                <span className={classes.placeholder}>State*</span>
              )
            }
          >
            {Object.keys(stateOptions).map((key, i) => {
              return (
                <MenuItem
                  key={key}
                  value={key}
                >
                  <ListItemText primary={stateOptions[key]} />
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item sm={4}>
          <TextField
            InputProps={{ disableUnderline: true }}
            placeholder="Zip Code*"
            name="storeZipCode"
            fullWidth
            variant="filled"
            required
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            InputProps={{ disableUnderline: true }}
            placeholder="Country*"
            name="storeCountry"
            defaultValue="USA"
            disabled
            fullWidth
            variant="filled"
            required
          />
        </Grid>
      </Grid>
      <TextField
        InputProps={{ disableUnderline: true }}
        placeholder="Telephone"
        name="storeContactTelephoneNumber"
        fullWidth
        variant="filled"
        value={mobile}
        onChange={handlePhone}
      />
      <ImageInput
        storeName={storeName}
        id="logo-upload"
        name="storeLogo"
        onChange={handleLogo}
      />
    </SignUpCard>
  );
}
