import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Grid, makeStyles, Typography, Box, IconButton, withStyles, CircularProgress, Snackbar } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { FullWidthTextField } from './common/FormikFields';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { CheckboxField } from '../components/common/FormikFields';
import AccessibleIcon from '@material-ui/icons/Accessible';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { useRouteParam } from '../hooks/query';
import { loadHOW } from '../helpers/localcache';
import { useCreateCustomer, useSendEmailVerification } from "../hooks/use-fetch";
import { useHistory } from 'react-router-dom';
import Alert from "@material-ui/lab/Alert";

const useClases = makeStyles(() => ({
    root: {
        minHeight: 450
    }
}));

const Transition = React.forwardRef(function Transition(
    props,
    ref
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export const SignupModal = ({ onClose, open }) => {
    const [snackbar, setSnackbar] = React.useState({});
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useClases();
    const churchProfileName = useRouteParam(ps => ps.church);
    const history = useHistory();
    const [
        ,
        signup,
    ] = useCreateCustomer();
    const [, sendEmail] = useSendEmailVerification()

    const handleSave = async (values) => {
        const user = {
            Username: values.email,
            Password: values.password,
            name: `${values.firstname} ${values.lastname}`,
            phone_number: values.phone.split(" ").join(""),
            senior_citizen: values.seniorCitizen,
            profile_name: churchProfileName || loadHOW()
        };

        const res = await signup({ params: user });

        if (!res.error) {
            await sendEmail({
                params: {
                    email: user.Username,
                    name: user.name
                }
            })

            history.push(`/verify`, { email: user.Username });
        } else {
            setSnackbar({open: true, severity: 'error', message: res.error.message})
        }
    };

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={open}
            TransitionComponent={Transition}
            keepMounted
            fullScreen={fullScreen}
            scroll='paper'
            onClose={onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title" onClose={onClose}>
                Sign up
            </DialogTitle>

            <Formik
                initialValues={{
                    firstname: '',
                    lastname: '',
                    email: '',
                    phone: '',
                    password: '',
                    confirmPassword: '',
                    seniorCitizen: false
                }}
                validationSchema={
                    Yup.object().shape({
                        firstname: Yup.string().required('First name is required'),
                        lastname: Yup.string().required('Last name is required'),
                        email: Yup.string().email('Must be a valid email address').required('Email address is required'),
                        phone: Yup.string().matches(/^\d{10}$/, 'Mobile number must be a 10 digits number').required('Mobile number is required'),
                        password: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters long.'),
                        confirmPassword: Yup.string().required('Retype password is required').oneOf([Yup.ref('password'), null], 'Passwords do not match'),
                    })
                }
                onSubmit={handleSave}
            >
                {({ isSubmitting, isValid, dirty }) => (
                    <Form >
                        <DialogContent className={classes.root} dividers>
                            <Grid container={true} spacing={3}>
                                <Grid item={true} xs={12} md={6}>
                                    <Grid container spacing={2}>

                                        <Grid item xs={12}>
                                            <Field
                                                component={FullWidthTextField}
                                                name='firstname'
                                                type='text'
                                                label='First name'
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                component={FullWidthTextField}
                                                name='lastname'
                                                type='text'
                                                label='Last name'
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                component={FullWidthTextField}
                                                name='email'
                                                type='email'
                                                label='Email Address'
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                component={FullWidthTextField}
                                                name='phone'
                                                type='text'
                                                label='Mobile number'
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                component={FullWidthTextField}
                                                name='password'
                                                type='password'
                                                label='Password'
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                component={FullWidthTextField}
                                                name='confirmPassword'
                                                type='password'
                                                label='Retype password'
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button disabled={!isValid || !dirty || isSubmitting} type='submit' color='primary' variant='contained' fullWidth>
                                                Sign up&nbsp;&nbsp;{isSubmitting && <CircularProgress size={20} />}
                                            </Button>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <Grid style={{ marginBottom: 20 }} spacing={2} container alignItems="center">
                                        <Grid item xs={1} >
                                            <AccessibleIcon />
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Typography variant="h5">
                                                <Box fontWeight="fontWeightLight" component="span">
                                                    Do you require extra time or assistance?
                                                </Box>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                name='seniorCitizen'
                                                type='checkbox'
                                                component={CheckboxField}
                                                label='Check this box if you need assistance or additional time to enter and exit the premises. This will give you access to dedicated entry time slots designated to assist you with your needs.'
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            Already signed up?&nbsp;&nbsp;<Button variant='outlined' href='/login' color='primary'>Login</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </DialogContent>

                    </Form>
                )}

            </Formik>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={5000}
                onClose={() => setSnackbar({})}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={() => setSnackbar({})}
                    severity={snackbar.severity}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Dialog>
    );
}

