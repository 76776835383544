import * as React from 'react';
import {
    makeStyles, Avatar, Container, Grid, Link, Paper, Snackbar,
    Typography, Button, List, ListItem, ListItemIcon, IconButton, ListItemSecondaryAction
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CalendarIcon from '@material-ui/icons/DateRange';
import PhoneIcon from '@material-ui/icons/Phone';
import PublicIcon from '@material-ui/icons/Public';
import BusinessLocationMap from './map';
import { EditProfile } from './editProfile';
import ImageInput from '../ImageInput';
import { useUpdateStore } from "../../hooks/use-fetch";
import Alert from "@material-ui/lab/Alert";
import LinkIcon from '@material-ui/icons/Link';
import TextField from '@material-ui/core/TextField';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import { Instructions } from './instructions';
import { SignupModal } from '../SignUpModal';
import { CurrentUserContext } from '../../context/CurrentUserContext';

const useClasses = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    background: {
        width: '100%',
        height: 300,
        backgroundImage: 'url(/images/Backgrounds/peace_banner.jpg)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    logo: {
        height: 200,
        width: 200
    },
    content: theme.mixins.gutters({
    }),
    scheduleTitle: theme.mixins.gutters({
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }),
    name: {
        fontSize: '22',
        height: 80,
        display: 'flex',
        alignItems: 'center'
    },
    instructions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#7FDCEB'
    },
    step: {
        display: 'flex'
    }
}));

const ChurchProfile = ({ church, readonly }) => {
    const [profile, setProfile] = React.useState(church);
    const classes = useClasses();
    const [showEdit, setShowEdit] = React.useState(false);
    const [, updateStore] = useUpdateStore();
    const [snackbar, setSnackbar] = React.useState();
    const profileLinkRef = React.useRef();
    const [description, setDescription] = React.useState();
    const [signupModalOpen, setSignUpModalOpen] = React.useState(false);

    React.useEffect(() => {
        const renderDescription = () => {

            if (profile.description) {
                let value = profile.description;
                try {
                    JSON.parse(value);
                } catch (error) {
                    const contentHTML = convertFromHTML(value)
                    const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
                    value = JSON.stringify(convertToRaw(state));
                }

                const markup = draftToHtml(JSON.parse(value));
                return markup;
            } else {
                return ''
            }
        }

        setDescription(renderDescription())
    }, [profile.description]);

    const closeEdit = () => setShowEdit(false);

    const copyLink = () => {
        navigator.clipboard.writeText(`${window.location.protocol}//${window.location.hostname}/church/${profile.profile_name}`);
        setSnackbar({ severity: 'success', message: 'Copied link!!!' })
    }

    const handleLogo = async (logoResponse) => {
        try {
            await fetch(logoResponse["presigned-url"], {
                method: "PUT",
                body: logoResponse.logo,
            });

            await updateStore({
                params: {
                    store_id: church.store_id,
                    logo: logoResponse.url,
                },
            });
            setSnackbar({ severity: 'success', message: 'Successfully updated logo!!' })
        } catch (error) {
            setSnackbar({ severity: 'error', message: 'Failed to update logo!!' })

        }

    };

    return (
        <div className={classes.root}>
            {snackbar && <Snackbar autoHideDuration={6000} onClose={() => setSnackbar(undefined)} open={!!snackbar}>
                <Alert onClose={() => setSnackbar(undefined)} variant="filled" severity={snackbar.severity} elevation={6}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            }
            <SignupModal open={signupModalOpen} onClose={() => setSignUpModalOpen(false)} />
            {!readonly ? <EditProfile profile={profile} onClose={closeEdit} onUpdate={setProfile} open={showEdit} /> : null}
            <div className={classes.background}>
                {
                    readonly ?

                        <Avatar alt={profile.name} className={classes.logo} src={profile.logo} />

                        :
                        <ImageInput
                            storeName={church.name}
                            id="change-store-logo"
                            name="storeLogo"
                            text="Change store logo"
                            onChange={handleLogo}
                            defaultValue={church.logo}
                        />
                }
            </div>
            <Container className={classes.content}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        {!readonly && <Button onClick={() => setShowEdit(true)} style={{ marginTop: 20 }} variant='contained' color="primary">Edit</Button>}
                        <div style={{ padding: 10 }}>
                            <div className={classes.name}>
                                <Typography variant='h4'>{profile.name}</Typography>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: description }} className={classes.description}></div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={2} style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: 20 }}>
                            {readonly && <Grid item xs={12}>
                                <CurrentUserContext.Consumer>
                                    {
                                        ({ user }) => {
                                            return !!user ? (
                                                <Button
                                                    href={`/calendar/church/${profile.profile_name}`}
                                                    startIcon={<CalendarIcon />}
                                                    fullWidth
                                                    variant='contained'
                                                    color='primary'>
                                                    Reserve your entry time slot
                                                </Button>
                                            ) : (
                                                <Button
                                                    onClick={() => setSignUpModalOpen(true)}
                                                    fullWidth
                                                    variant='contained'
                                                    color='primary'>
                                                    Sign up to see our calendar
                                                </Button>
                                            )
                                        }
                                    }
                                </CurrentUserContext.Consumer>

                            </Grid>}
                            <Grid item xs={12}>
                                <Paper style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    {false && <BusinessLocationMap lat={Number(profile.lat)} lng={Number(profile.long)} address={profile.street} />}
                                    <div style={{ margin: 10 }}>
                                        <Typography style={{ fontWeight: 'bold' }}>{profile.street}, {profile.city}, {profile.state}, {profile.zip}</Typography>
                                        {/*
                                        <Button
                                            target='_blank'
                                            startIcon={<PinIcon />}
                                            endIcon={<OpenInNewIcon />}
                                            href={`https://www.google.com/maps/dir/?api=1&query=${googleMapsDirectionQuery}`}
                                        >Google Maps</Button>
                                      */}
                                    </div>
                                </Paper>

                                <Paper style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                    <List>
                                        <ListItem>
                                            <ListItemIcon>
                                                <PhoneIcon />
                                            </ListItemIcon>
                                            {profile.phone}
                                        </ListItem>
                                    </List>
                                    {profile.website && <List>
                                        <ListItem style={{ overflowWrap: 'anywhere' }}>
                                            <ListItemIcon>
                                                <PublicIcon />
                                            </ListItemIcon>
                                            <Link target="_blank" href={profile.website}>{profile.website}</Link>
                                        </ListItem>
                                    </List>}
                                    {!readonly && <List>
                                        <ListItem>
                                            <ListItemIcon>
                                                <LinkIcon />
                                            </ListItemIcon>
                                            <TextField readOnly ref={profileLinkRef} value={`${window.location.protocol}//${window.location.hostname}/church/${profile.profile_name}`} />
                                            <ListItemSecondaryAction>
                                                <IconButton onClick={copyLink}>
                                                    <FileCopyIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Container>
            <Instructions />
        </div>
    )

}

ChurchProfile.propTypes = {
    church: PropTypes.any.isRequired,
    readonly: PropTypes.bool
}

export { ChurchProfile }
