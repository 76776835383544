import React from "react";
import {
  Container,
  Box,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import logo from "../images/PD_logo_website.svg";
import error from "../images/error.png";

const useStyles = makeStyles((theme) => ({
  error: {
    height: "100%",
    paddingTop: 110,
    position: "absolute",
    zIndex: 3,
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: "#ffffff",
    color: theme.palette.text.medium,
    "& a": {
      color: theme.palette.text.medium,
    },
  },
}));

export default function Error() {
  const classes = useStyles();
  return (
    <section className={classes.error}>
      <Container maxWidth="sm">
        <Grid container>
          <Grid item xs={12} md={8}>
            <img src={logo} alt="Perfectly Distanced." />
            <Typography variant="h4">
              <Box component="span" fontWeight="fontWeightLight">
                Opps! Something went wrong, we’re sorry.
              </Box>
            </Typography>
            <p>
              If the problem persists, please email{" "}
              <a href="mailto:support@perfectlydistanced.com">
                support@perfectlydistanced.com
              </a>
            </p>
          </Grid>
          <Grid item xs={12} md={4}>
            <img src={error} alt="Error." />
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
