import React from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Alert from "@material-ui/lab/Alert";
import Checkbox from "@material-ui/core/Checkbox";
import {
  makeStyles,
  Typography,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useLoginCustomer } from "../hooks/use-fetch";
import { setHOW } from "../helpers/localcache";
import { CurrentUserContext } from '../context/CurrentUserContext';

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiFormControl-root.MuiTextField-root": {
      backgroundColor: "rgba(0,0,0,0.16)",
      borderRadius: "4px",
      marginBottom: 16,
      "& input": {
        padding: "16px 16px 19px",
      },
      border: "1px solid transparent",
      "&:focus-within": {
        borderColor: theme.palette.secondary.main,
      },
    },
    "& button": {
      display: "block",
      width: "100%",
      marginBottom: 28,
      "&.close": {
        width: "24px",
        height: "24px",
        padding: 0,
        margin: "12px 12px 16px auto",
      },
    },
    "& h4": {
      marginBottom: 16,
    },
    "& a, & .link-button": {
      display: "block",
      textAlign: "center",
      marginBottom: 28,
      fontSize: "0.875rem",
      cursor: "pointer",
      border: 0,
      padding: 0,
      textDecoration: "underline",
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      "&.inline": {
        display: "inline",
      },
    },
    "& label": {
      display: "block",
      marginBottom: "30px",
    },
    "& .text-medium": {
      color: theme.palette.text.medium,
    },
    "& .centered": {
      textAlign: "center",
    },
    "& .MuiDialogActions-root": {
      justifyContent: "center",
      paddingTop: 30,
      paddingBottom: 40,
      borderTop: "1px solid rgba(0,0,0, 0.12)",
    },
    "& .MuiCircularProgress-root.MuiCircularProgress-colorPrimary.MuiCircularProgress-indeterminate": {
      color: "currentColor",
    },
    "& .MuiButton-label": {
      display: "flex",
      alignItems: "center",
    },
  },
}));

export default function CustomerLoginDialog({
  label = 'Congregant Login',
  color,
  variant,
  onLogin,
  disabled,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    severity: "",
    message: "",
  });
  const [keepMeLoggedIn, setKeepMeLoggedIn] = React.useState(false);
  const [form, setForm] = React.useState({ username: "", password: "" });
  const [loginCall, login] = useLoginCustomer();
  const history = useHistory();
  const auth = React.useContext(CurrentUserContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setForm({ ...form, password: "" });
    setOpen(false);
  };

  const handleForgotPassword = async () => { 
    history.push('/customer/forgot-password');
  };

  const handleInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const { username, password } = form;

    const { data, loading, error } = await login({
      params: {
        Username: username,
        Password: password,
      },
    });

    if (!loading && !error) {
      window.localStorage.setItem("AccessToken", data.AccessToken);
      window.localStorage.setItem("RefreshToken", data.RefreshToken);
      window.localStorage.setItem("username", username);
      window.localStorage.setItem("keepMeLoggedIn", keepMeLoggedIn);
      window.localStorage.setItem("userType", "customer");
      if (data.profile_name) {
        setHOW(data.profile_name);
      }

      if (onLogin) {
        onLogin({ ...data, username, store: false });
      }
      auth.login({ ...data, username, store: false })
    } else {
      console.log(error)
      setSnackbar({
        open: true,
        severity: "error",
        message: error.ErrorMessage
          ? error.ErrorMessage
          : error.message,
      });
    }
  };

  const handleKeepMeLoggedIn = (e) => {
    setKeepMeLoggedIn(e.target.checked);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false })
  }

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleClickOpen} disabled={disabled}>
        {label}
      </Button>
      <Dialog
        className={classes.dialog}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
      >
        <IconButton className="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography id="form-dialog-title" variant="h4">
            Login
          </Typography>
          <form onSubmit={handleLogin}>
            <TextField
              InputProps={{ disableUnderline: true }}
              placeholder="Email"
              id="emailAddress"
              type="email"
              onChange={handleInput}
              value={form.username}
              name="username"
              fullWidth
            />
            <TextField
              InputProps={{ disableUnderline: true }}
              placeholder="Password"
              id="password"
              type="password"
              onChange={handleInput}
              value={form.password}
              name="password"
              fullWidth
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="keepMeLoggedIn"
                  onChange={handleKeepMeLoggedIn}
                  checked={keepMeLoggedIn}
                />
              }
              label="Keep me logged in"
              labelPlacement="end"
            />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              endIcon={
                loginCall.loading && (
                  <CircularProgress style={{ width: 20, height: 20 }} />
                )
              }
            >
              Login
            </Button>
          </form>

          <button onClick={handleForgotPassword} className="link-button">
            Forgot your password?
          </button>
          <p className="text-medium centered">New to Perfectly Distanced?</p>
          <Link to="/signup" style={{ textDecoration: "none" }}>
            <Button color="primary" variant="outlined">
              Sign up
            </Button>
          </Link>
        </DialogContent>
        <Snackbar autoHideDuration={6000} onClose={handleSnackbarClose} open={snackbar.open}>
          <Alert onClose={handleSnackbarClose} variant="filled" severity={snackbar.severity} elevation={6}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Dialog>
    </>
  );
}
