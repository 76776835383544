import moment from 'moment';

export const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const fullDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getToday = (add = 0) => {
  let today = new Date();
  today.setDate(today.getDate() + 0 + add);
  return today;
};

export const getTomorrow = (add = 0) => {
  return getToday(add + 1);
};

export const getThirdDay = (add = 0) => {
  return getTomorrow(add + 1);
};

export const getFourthDay = (add = 0) => {
  return getThirdDay(add + 1);
};
export const getFifthDay = (add = 0) => {
  return getFourthDay(add + 1);
};

export const getSixthDay = (add = 0) => {
  return getFifthDay(add + 1);
};

export const getSeventhDay = (add = 0) => {
  return getSixthDay(add + 1);
};

export const daysOfWeek = (add = 0) => {
  return [
    getToday(add),
    getTomorrow(add),
    getThirdDay(add),
    getFourthDay(add),
    getFifthDay(add),
    getSixthDay(add),
    getSeventhDay(add),
  ];
};

export const workingDaysOfWeek = (add = 0) => {
  return [
    getToday(add),
    getTomorrow(add),
    getThirdDay(add),
    getFourthDay(add),
    getFifthDay(add),
  ];
};

export const parseToYYYYMMDD = (dateStr) => {
  const d = new Date(dateStr)
  const date = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()
  const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1
  return `${d.getFullYear()}-${month}-${date}`
}

export const isInThePast = (input) => {
  return  moment(input).isBefore(moment());
}
