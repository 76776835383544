import { AUTH_ONLY_MANAGER, AUTH_ONLY_CUSTOMER } from "../types";
import { CUSTOMER_BASE_URL, MANAGER_BASE_URL, API_VERSION } from "../../hooks/use-fetch"

export default async function requireLogin(to, from, next) {
    if (!!to.meta[AUTH_ONLY_MANAGER] || !!to.meta[AUTH_ONLY_CUSTOMER]) {
      const [user, setUser] = [to.meta.user, to.meta.setUser]
      if (!user) {
        if (window && window.localStorage.getItem('keepMeLoggedIn')) {
            setUser({
              AccessToken: window.localStorage.getItem('AccessToken'),
              RefreshToken: window.localStorage.getItem('RefreshToken'),
              username: window.localStorage.getItem('username'),
              store: window.localStorage.getItem('userType') === 'store'
            })
            next()
        } else {
          next.redirect("/");
        }
      }

      const params = {
        AccessToken: localStorage.getItem('AccessToken'),
        RefreshToken: localStorage.getItem('RefreshToken'),
        Username: localStorage.getItem('username')
      }

      try {
        const endpoint = to.meta[AUTH_ONLY_MANAGER] ? `${MANAGER_BASE_URL}/${API_VERSION}/manager/check` : `${CUSTOMER_BASE_URL}/${API_VERSION}/customer/check`
        const blob = await fetch(endpoint, {
          method: "POST",
          body: JSON.stringify(params)
        });
        const res = await blob.json();
        if (res && res.sessionResponse && res.sessionResponse.newAccessToken) {
          setUser({ ...user, AccessToken: res.sessionResponse.newAccessToken })
        }
        return next()

      } catch (error) {
        return next.redirect('/');
      }

    }

    next();
}

