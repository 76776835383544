import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    minHeight: "60px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 40px",
    "& a": {
      textDecoration: "none",
      color: "#ffffff"
    },
    "& .colorWhite": {
      color: "#ffffff",
    },
    "& .primary": {
      color: theme.palette.primary.main,
    },
    "&.blue": {
      backgroundColor: theme.palette.surface.blueDark,
      "& > svg": {
        color: "#ffffff",
      },
    },
    "& > svg": {
      color: theme.palette.primary.main,
      maxWidth: 300,
    },
  },
}));

export default function Header({ blue, cancel }) {
  const classes = useStyles();

  return (
    <header className={`blue ${classes.header}`}>
      <Link to="/" style={{ textDecoration: 'none' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 375 60"
          xmlSpace="preserve"
          style={{ maxWidth: 300 }}
        >
          <g id="Group_1" transform="translate(6.76 -85.021)">
            <g id="Group_1-2">
              <path
                id="Subtraction_1"
                fill="currentColor"
                d="M36.9,133H12.6c-1.7,0-3.1-1.1-3.6-2.8l-4.7-17.6c-0.1-0.2-0.1-0.3-0.1-0.5
                c0-1,0.8-1.9,1.9-1.9H15l8.2-12.4c0.3-0.5,0.9-0.8,1.5-0.8s1.2,0.3,1.5,0.8l8.2,12.4h8.9c1,0,1.9,0.9,1.9,1.9l-0.1,0.5l-4.7,17.6
                C40,131.9,38.5,133,36.9,133z M24.9,115.8c-3.5,0-6.3,2.8-6.3,6.2c0,3.5,2.8,6.3,6.2,6.3s6.3-2.8,6.3-6.2c0,0,0,0,0-0.1
                C31.1,118.7,28.3,115.9,24.9,115.8z M24.7,101.9l-5.6,8.3h11.2L24.7,101.9z M28.2,125.4L28.2,125.4l-4.1-2.5v-4.7h1.2v4.1l3.5,2.1
                L28.2,125.4L28.2,125.4z"
              />
            </g>
          </g>
          <g fill="#FFFFFF">
            <path
              d="M70.5,33.1v6h-2.6V23.4h5.2c0.8,0,1.5,0.1,2.2,0.3c0.7,0.2,1.2,0.5,1.7,1c0.5,0.4,0.9,0.9,1.1,1.5c0.3,0.6,0.4,1.3,0.4,2
              c0,0.7-0.1,1.4-0.4,2c-0.3,0.6-0.6,1.1-1.1,1.5c-0.5,0.4-1.1,0.7-1.7,1c-0.7,0.2-1.4,0.3-2.2,0.3H70.5z M70.5,31h2.7
              c0.4,0,0.8-0.1,1.2-0.2c0.3-0.1,0.6-0.3,0.9-0.6c0.2-0.2,0.4-0.5,0.6-0.8c0.1-0.3,0.2-0.7,0.2-1.1c0-0.4-0.1-0.8-0.2-1.1
              s-0.3-0.6-0.6-0.9c-0.2-0.2-0.5-0.4-0.9-0.6c-0.3-0.1-0.7-0.2-1.2-0.2h-2.7V31z"
            />
            <path
              d="M85.1,39.3c-0.9,0-1.6-0.1-2.4-0.4s-1.3-0.7-1.8-1.2c-0.5-0.5-0.9-1.1-1.2-1.8s-0.4-1.4-0.4-2.2v-0.4
              c0-0.9,0.1-1.8,0.4-2.5c0.3-0.7,0.7-1.4,1.2-1.9c0.5-0.5,1.1-0.9,1.7-1.2c0.7-0.3,1.4-0.4,2.1-0.4c0.8,0,1.6,0.1,2.2,0.4
              c0.6,0.3,1.2,0.7,1.6,1.2c0.4,0.5,0.8,1.1,1,1.8c0.2,0.7,0.3,1.5,0.3,2.3v1.1h-8.1c0,0.5,0.2,0.9,0.3,1.3c0.2,0.4,0.4,0.7,0.7,1
              s0.6,0.5,1,0.7c0.4,0.2,0.8,0.2,1.3,0.2c0.7,0,1.3-0.1,1.8-0.4s1-0.6,1.3-1.1l1.4,1.3c-0.2,0.3-0.4,0.5-0.7,0.8
              c-0.3,0.3-0.6,0.5-1,0.7c-0.4,0.2-0.8,0.4-1.3,0.5C86.2,39.2,85.7,39.3,85.1,39.3z M84.8,29.3c-0.4,0-0.7,0.1-1,0.2
              c-0.3,0.1-0.6,0.3-0.8,0.6c-0.2,0.2-0.4,0.5-0.6,0.9c-0.2,0.4-0.3,0.8-0.4,1.2h5.4V32c0-0.3-0.1-0.7-0.2-1
              c-0.1-0.3-0.3-0.6-0.5-0.9c-0.2-0.3-0.5-0.5-0.8-0.6C85.6,29.4,85.2,29.3,84.8,29.3z"
            />
            <path
              d="M99.6,27.2c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1c0.2,0,0.3,0.1,0.4,0.1l-0.4,2.5
              c-0.8-0.2-1.6-0.3-2.3-0.3c-0.9,0-1.6,0.2-2.1,0.5c-0.5,0.4-0.9,0.9-1.2,1.5v7.3h-2.6V27.4h2.4l0.1,1.8c0.5-0.6,1-1.1,1.7-1.5
              C98,27.4,98.8,27.2,99.6,27.2z"
            />
            <path
              d="M106.4,39.1v-9.7h-3.1v-1.9h3.1v-0.7c0-0.8,0.1-1.4,0.3-2c0.2-0.6,0.6-1.1,1-1.5c0.4-0.4,0.9-0.7,1.5-0.9s1.3-0.3,2-0.3
              c0.5,0,1,0,1.4,0.1c0.4,0.1,0.9,0.2,1.4,0.3l-0.2,2c-0.3-0.1-0.6-0.1-1-0.2c-0.4-0.1-0.8-0.1-1.3-0.1c-0.9,0-1.6,0.2-2,0.7
              c-0.4,0.4-0.6,1.1-0.6,1.9v0.7h4.3v1.9H109v9.7H106.4z"
            />
            <path
              d="M120.8,39.3c-0.9,0-1.6-0.1-2.4-0.4s-1.3-0.7-1.8-1.2c-0.5-0.5-0.9-1.1-1.2-1.8s-0.4-1.4-0.4-2.2v-0.4
              c0-0.9,0.1-1.8,0.4-2.5c0.3-0.7,0.7-1.4,1.2-1.9c0.5-0.5,1.1-0.9,1.7-1.2c0.7-0.3,1.4-0.4,2.1-0.4c0.8,0,1.6,0.1,2.2,0.4
              c0.6,0.3,1.2,0.7,1.6,1.2c0.4,0.5,0.8,1.1,1,1.8c0.2,0.7,0.3,1.5,0.3,2.3v1.1h-8.1c0,0.5,0.2,0.9,0.3,1.3c0.2,0.4,0.4,0.7,0.7,1
              s0.6,0.5,1,0.7c0.4,0.2,0.8,0.2,1.3,0.2c0.7,0,1.3-0.1,1.8-0.4s1-0.6,1.3-1.1l1.4,1.3c-0.2,0.3-0.4,0.5-0.7,0.8
              c-0.3,0.3-0.6,0.5-1,0.7c-0.4,0.2-0.8,0.4-1.3,0.5C121.9,39.2,121.3,39.3,120.8,39.3z M120.4,29.3c-0.4,0-0.7,0.1-1,0.2
              c-0.3,0.1-0.6,0.3-0.8,0.6c-0.2,0.2-0.4,0.5-0.6,0.9c-0.2,0.4-0.3,0.8-0.4,1.2h5.4V32c0-0.3-0.1-0.7-0.2-1
              c-0.1-0.3-0.3-0.6-0.5-0.9c-0.2-0.3-0.5-0.5-0.8-0.6C121.3,29.4,120.9,29.3,120.4,29.3z"
            />
            <path
              d="M132.3,37.2c0.3,0,0.6,0,0.9-0.2c0.3-0.1,0.6-0.2,0.8-0.4c0.2-0.2,0.4-0.4,0.5-0.6c0.1-0.2,0.2-0.5,0.2-0.8h2.4
              c0,0.6-0.1,1.1-0.4,1.6c-0.3,0.5-0.6,0.9-1.1,1.3c-0.4,0.4-1,0.7-1.6,0.9c-0.6,0.2-1.2,0.3-1.8,0.3c-0.9,0-1.7-0.2-2.4-0.5
              c-0.7-0.3-1.2-0.7-1.7-1.3s-0.8-1.2-1-1.9s-0.3-1.5-0.3-2.3v-0.4c0-0.8,0.1-1.5,0.3-2.3s0.6-1.3,1-1.9c0.5-0.5,1-1,1.7-1.3
              c0.7-0.3,1.5-0.5,2.3-0.5c0.7,0,1.4,0.1,1.9,0.3c0.6,0.2,1.1,0.5,1.5,0.9c0.4,0.4,0.8,0.8,1,1.4c0.2,0.5,0.4,1.1,0.3,1.8h-2.4
              c0-0.3,0-0.6-0.2-0.9s-0.3-0.5-0.5-0.7c-0.2-0.2-0.5-0.4-0.8-0.5c-0.3-0.1-0.6-0.2-1-0.2c-0.5,0-1,0.1-1.3,0.3
              c-0.4,0.2-0.6,0.5-0.9,0.9s-0.4,0.8-0.5,1.2c-0.1,0.5-0.1,0.9-0.1,1.4v0.4c0,0.5,0,0.9,0.1,1.4c0.1,0.5,0.2,0.9,0.5,1.2
              s0.5,0.6,0.9,0.9C131.3,37.1,131.8,37.2,132.3,37.2z"
            />
            <path
              d="M144.3,24.6v2.8h4.4v1.9h-4.4v5.6c0,0.4,0.1,0.8,0.2,1.1c0.1,0.3,0.2,0.5,0.4,0.7c0.2,0.2,0.4,0.3,0.7,0.4
              c0.3,0.1,0.5,0.1,0.8,0.1c0.2,0,0.5,0,0.7,0c0.2,0,0.5-0.1,0.7-0.1s0.4-0.1,0.6-0.1c0.2,0,0.3-0.1,0.5-0.1l0.3,1.8
              c-0.2,0.1-0.4,0.2-0.7,0.3c-0.3,0.1-0.5,0.2-0.9,0.2c-0.3,0.1-0.6,0.1-1,0.1s-0.7,0-1,0c-0.6,0-1.1-0.1-1.6-0.2s-0.9-0.4-1.2-0.8
              c-0.3-0.3-0.6-0.8-0.8-1.3c-0.2-0.5-0.3-1.2-0.3-1.9v-5.7h-2.8v-1.9h2.8v-2.8H144.3z"
            />
            <path d="M151.5,22.6h6.3v14.4h3.5v2.1h-9.8v-2.1h3.7V24.7h-3.7V22.6z" />
            <path
              d="M167.5,34.4l0.4,1.4l3.3-8.4h2.8l-5.9,13.4c-0.1,0.3-0.3,0.6-0.5,1c-0.2,0.3-0.5,0.7-0.8,1c-0.3,0.3-0.7,0.5-1.1,0.7
              c-0.4,0.2-0.9,0.3-1.5,0.3c-0.2,0-0.5,0-0.7-0.1c-0.2,0-0.5-0.1-0.6-0.1l0.4-2c0,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0
              c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0.3,0,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.3,0.5-0.5c0.2-0.2,0.3-0.4,0.4-0.6s0.2-0.3,0.2-0.4
              l0.8-1.4l-4.9-11.1h2.8L167.5,34.4z"
            />
            <path
              d="M184.3,39.1V23.4h4.4c1,0,1.9,0.2,2.7,0.5c0.8,0.3,1.5,0.8,2.1,1.4c0.6,0.6,1.1,1.4,1.4,2.2c0.3,0.9,0.5,1.8,0.5,2.9V32
              c0,1.1-0.2,2-0.5,2.9c-0.3,0.9-0.8,1.6-1.4,2.2s-1.3,1.1-2.1,1.4c-0.8,0.3-1.7,0.5-2.8,0.5H184.3z M185.6,24.5v13.4h3
              c0.8,0,1.6-0.1,2.3-0.4s1.2-0.7,1.7-1.2s0.8-1.2,1.1-1.9c0.3-0.7,0.4-1.5,0.4-2.4v-1.5c0-0.9-0.1-1.7-0.4-2.5
              c-0.3-0.7-0.6-1.4-1.1-1.9c-0.5-0.5-1-0.9-1.7-1.2c-0.7-0.3-1.4-0.4-2.2-0.4H185.6z"
            />
            <path d="M205.7,39.1h-1.3V23.4h1.3V39.1z" />
            <path
              d="M224.1,35.2c0-0.4-0.1-0.8-0.2-1.1c-0.1-0.3-0.4-0.6-0.7-0.9c-0.3-0.3-0.8-0.5-1.3-0.8c-0.5-0.2-1.2-0.5-2-0.7
              c-0.8-0.2-1.5-0.5-2.1-0.7c-0.6-0.3-1.2-0.6-1.6-0.9c-0.4-0.4-0.8-0.8-1-1.2c-0.2-0.5-0.4-1-0.4-1.6c0-0.6,0.1-1.1,0.4-1.6
              s0.6-0.9,1.1-1.3s1-0.6,1.7-0.8c0.6-0.2,1.3-0.3,2.1-0.3c0.8,0,1.6,0.1,2.2,0.4c0.7,0.2,1.2,0.6,1.7,1c0.5,0.4,0.8,0.9,1.1,1.5
              c0.3,0.6,0.4,1.2,0.4,1.8H224c0-0.5-0.1-1-0.3-1.4c-0.2-0.4-0.4-0.8-0.8-1.1c-0.3-0.3-0.8-0.6-1.3-0.7c-0.5-0.2-1.1-0.3-1.7-0.3
              s-1.2,0.1-1.7,0.2c-0.5,0.2-0.9,0.4-1.2,0.6c-0.3,0.3-0.6,0.6-0.7,0.9c-0.2,0.4-0.3,0.7-0.3,1.1c0,0.4,0.1,0.7,0.2,1
              c0.1,0.3,0.4,0.6,0.7,0.9s0.8,0.5,1.3,0.8c0.5,0.2,1.2,0.5,2,0.7c0.8,0.2,1.6,0.5,2.2,0.8c0.6,0.3,1.2,0.6,1.6,1s0.8,0.8,1,1.3
              c0.2,0.5,0.3,1,0.3,1.7c0,0.6-0.1,1.2-0.4,1.7c-0.3,0.5-0.6,0.9-1.1,1.3c-0.5,0.4-1,0.6-1.7,0.8c-0.7,0.2-1.4,0.3-2.1,0.3
              c-0.5,0-0.9,0-1.4-0.1c-0.5-0.1-0.9-0.2-1.4-0.4c-0.4-0.2-0.8-0.4-1.2-0.6c-0.4-0.2-0.7-0.5-1-0.9c-0.3-0.3-0.5-0.7-0.7-1.2
              c-0.2-0.4-0.2-0.9-0.2-1.5h1.3c0,0.7,0.1,1.2,0.4,1.7s0.6,0.8,1.1,1.1s0.9,0.5,1.5,0.6s1.1,0.2,1.6,0.2c0.6,0,1.2-0.1,1.7-0.2
              c0.5-0.1,0.9-0.3,1.3-0.6c0.4-0.3,0.6-0.6,0.8-0.9C224,36.1,224.1,35.7,224.1,35.2z"
            />
            <path d="M243.8,24.5h-5.4v14.5h-1.3V24.5h-5.3v-1.1h12V24.5z" />
            <path d="M259.7,34.7h-7.1l-1.6,4.4h-1.4l5.9-15.6h1.3l5.9,15.6h-1.4L259.7,34.7z M253,33.5h6.3l-3.1-8.5L253,33.5z" />
            <path d="M282,39.1h-1.3l-9-13.4v13.4h-1.3V23.4h1.3l9,13.4V23.4h1.3V39.1z" />
            <path
              d="M302.4,34.2c-0.1,0.8-0.3,1.5-0.6,2.1c-0.3,0.6-0.7,1.2-1.2,1.6c-0.5,0.4-1.1,0.8-1.7,1c-0.7,0.2-1.4,0.3-2.3,0.3
              c-0.9,0-1.7-0.2-2.4-0.5c-0.7-0.3-1.3-0.8-1.9-1.4s-0.9-1.4-1.2-2.2c-0.3-0.9-0.4-1.8-0.4-2.9v-2c0-1.1,0.1-2,0.4-2.9
              s0.7-1.6,1.2-2.2c0.5-0.6,1.1-1.1,1.9-1.4c0.7-0.3,1.6-0.5,2.5-0.5c0.8,0,1.6,0.1,2.2,0.3c0.7,0.2,1.2,0.6,1.7,1
              c0.5,0.4,0.9,1,1.1,1.6c0.3,0.6,0.5,1.4,0.6,2.2h-1.3c-0.1-0.6-0.2-1.2-0.4-1.7c-0.2-0.5-0.5-0.9-0.8-1.3c-0.4-0.3-0.8-0.6-1.3-0.8
              c-0.5-0.2-1.1-0.3-1.8-0.3c-0.7,0-1.4,0.1-2,0.4c-0.6,0.3-1.1,0.7-1.5,1.2s-0.7,1.1-0.9,1.9c-0.2,0.7-0.3,1.5-0.3,2.4v2
              c0,0.9,0.1,1.7,0.3,2.4s0.5,1.4,0.9,1.9s0.9,0.9,1.4,1.2c0.6,0.3,1.2,0.4,1.9,0.4c1.4,0,2.4-0.3,3.1-1s1.1-1.7,1.3-3H302.4z"
            />
            <path d="M319.6,31.6h-7.3v6.4h8.4v1.1H311V23.4h9.7v1.1h-8.4v5.9h7.3V31.6z" />
            <path
              d="M328.8,39.1V23.4h4.4c1,0,1.9,0.2,2.7,0.5c0.8,0.3,1.5,0.8,2.1,1.4c0.6,0.6,1.1,1.4,1.4,2.2c0.3,0.9,0.5,1.8,0.5,2.9V32
              c0,1.1-0.2,2-0.5,2.9c-0.3,0.9-0.8,1.6-1.4,2.2s-1.3,1.1-2.1,1.4c-0.8,0.3-1.7,0.5-2.8,0.5H328.8z M330.2,24.5v13.4h3
              c0.8,0,1.6-0.1,2.3-0.4s1.2-0.7,1.7-1.2s0.8-1.2,1.1-1.9c0.3-0.7,0.4-1.5,0.4-2.4v-1.5c0-0.9-0.1-1.7-0.4-2.5
              c-0.3-0.7-0.6-1.4-1.1-1.9c-0.5-0.5-1-0.9-1.7-1.2c-0.7-0.3-1.4-0.4-2.2-0.4H330.2z"
            />
          </g>
        </svg>
      </Link>
    </header>
  );
}
