export const getParsedTime = (time) => {
  return time > 12 ? `${time - 12} PM` : `${time} AM`;
};

export const getIntParsedTime = (time) => {
  return time > 12 ? time - 12 : time;
};

export const hhmmToAmPm = (timeString) => {
  if (!timeString) return ''
  const parts = timeString.split(':')[0]
  const hours = parseInt(parts)
  return hours > 12 ? `${hours -12}pm` : `${hours}am`
}
