import React from "react";
import { Container, makeStyles, Typography, Button } from "@material-ui/core";
import StoreIcon from "@material-ui/icons/Store";
import hero from "../../images/store_hero.jpg";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
  hero: {
    backgroundImage: `url(${hero})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top",
    minHeight: "400px",
    position: "relative",
    "&::after": {
      opacity: 0.6,
      position: "absolute",
      content: '""',
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "#000000",
    },
  },
  container: {
    position: "relative",
    paddingTop: "100px",
    paddingBottom: "100px",
    zIndex: "2",
    color: "#ffffff",
  },
  slogan: {
    textAlign: "center",
    marginBottom: "17px",
  },
  paragraph: {
    textAlign: "center",
    marginBottom: "25px",
    fontWeight: 500,
  },
  blockButton: {
    display: "block",
    margin: "0 auto",
    padding: "11px 16px",
    "& > span": {
      display: "flex",
      alignItems: "center",
      "& > svg": {
        marginRight: ".5em",
      },
    },
  },
}));

export default function StoreHero() {
  const classes = useStyles();

  return (
    <section className={classes.hero}>
      <Container className={classes.container}>
        <Typography variant="h2" className={classes.slogan}>
          Improve your customers’ lives in these trying times
        </Typography>
        <Typography variant="h5" className={classes.paragraph}>
          No one likes to queue and many disabled folk, senior citizens and
          young families can’t endure long waits at the door. Perfectly
          Distanced provides stores a way to manage capacity, and your customers
          a way to avoid having to queue.
        </Typography>
        <Link to="/space/signup" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            color="primary"
            className={classes.blockButton}
          >
            <StoreIcon />
            Sign up
          </Button>
        </Link>
      </Container>
    </section>
  );
}
