import * as React from 'react';
import Header, { LogoutOutButton } from "../components/Header";
import { makeStyles, Paper, Typography, Container, Toolbar, Button, Grid, CircularProgress } from '@material-ui/core';
import {
    useGetEvent
} from "../hooks/use-fetch";
import { useRouteParam } from '../hooks/query';
import Congregants, { BookingContext, BookingProvider } from '../components/Scheduler/congregants';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Accessible from '@material-ui/icons/Accessible';
import moment from 'moment';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyItems: 'center',
        alignContent: 'center'
    },
    title: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 31.5,
        paddingBottom: 31.5,
        backgroundColor: '#7FDCEB',
        height: 120
    }
}));

export default () => {
    const classes = useStyles();
    const [event, setEvent] = React.useState();
    const eventId = useRouteParam(params => params.id);
    const date = useRouteParam(params => params.date);
    const history = useHistory();
    const [, getEvent] = useGetEvent();

    React.useEffect(() => {
        const load = async () => {

            let url = `event/${eventId}`;
    
            const response = await getEvent({ url });
            if (response) {
                setEvent(response.data);
            }
        }

        if (getEvent && eventId) {
            load();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const eventTime = () => {
        const format = 'dddd MMMM DD'

        if (event) {
            const day = date ? moment(date).format(format) : moment(event.startDate).format(format);

            return `${day}, ${moment(event.startDate).format('LT')} - ${moment(event.endDate).format('LT')}`;
        }

        return date ? moment(date).format(format)
            : <CircularProgress />;
    }

    const gotoScheduler = () => {
        history.push('/space/details/calendar', {index: 1})
    }

    return (
        <>
            <Header>
                <LogoutOutButton />
            </Header>
            {event && <Paper className={classes.title} elevation={2} square>
                <Typography variant='h4'>{event.name}</Typography>
            </Paper>}
            <Toolbar component={Paper} style={{ marginBottom: 10, width: '100%' }}>
                <Grid container>
                    <Grid item xs={4}>
                        <Button startIcon={<ArrowBackIcon />} onClick={gotoScheduler}>Calendar</Button>
                    </Grid>
                    <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                        {(!!event || !!date) && <Typography>{eventTime()}</Typography>}
                    </Grid>
                    <Grid item xs={4}></Grid>
                </Grid>
            </Toolbar>
            <Container className={classes.content}>
                <BookingProvider>

                    <BookingContext.Consumer>
                        {
                            ({ total, needAssistance, loading }) => {

                                return event && <Toolbar style={{ width: '100%' }}>
                                    <Typography style={{ fontWeight: 'bold' }}>Bookings</Typography>
                                    <span style={{ flex: '1 1 auto' }}></span>
                                    <Button
                                        startIcon={<EventAvailableIcon />}
                                    >{loading ? <CircularProgress size={20} /> : `${total}/${event.capacity}`}</Button>
                                    <Button
                                        startIcon={<Accessible />}
                                        style={{ marginLeft: 10 }}
                                    >{loading ? <CircularProgress size={20} /> : `${needAssistance}/${event.reserved_capacity}`}</Button>
                                </Toolbar>
                            }
                        }
                    </BookingContext.Consumer>
                    <Congregants event={event} />
                </BookingProvider>
            </Container>
        </>
    );
}