import React, { createContext } from "react";
import { CUSTOMER_BASE_URL, API_VERSION, useUpdateManager, useGetManager } from "../hooks/use-fetch"
import FullPageSpinner from "../components/FullPageSpinner";

export const ManagerContext = createContext({ manager: undefined });

export const ManagerProvider = ({ children }) => {
    const [manager, setManager] = React.useState();
    const [initializing, setInitializing] = React.useState(true);
    const [{ loading: profileLoading, data: profileData }] = useGetManager();
    const [, updateManager] = useUpdateManager()
    const [profile, setProfile] = React.useState({});

    React.useEffect(() => {
        setProfile(profileData)
    }, [profileData])


    React.useEffect(() => {
        const loadUser = async () => {
            try {
                const params = {
                    AccessToken: localStorage.getItem('AccessToken'),
                    RefreshToken: localStorage.getItem('RefreshToken'),
                    Username: localStorage.getItem('username')
                }
                const endpoint = `${CUSTOMER_BASE_URL}/${API_VERSION}/manager/check`
                const blob = await fetch(endpoint, {
                    method: "POST",
                    body: JSON.stringify(params)
                });
                const res = await blob.json();
                if (res && res.sessionResponse && res.sessionResponse.newAccessToken) {
                    setManager({ AccessToken: res.sessionResponse.newAccessToken })
                }
                setInitializing(false);
            } catch (error) {
                setInitializing(false);
            }

        }
        loadUser();
    }, []);

    const logout = () => {
        localStorage.removeItem('AccessToken');
        localStorage.removeItem('RefreshToken');
        localStorage.removeItem('username');
        setProfile(null)
        setManager(undefined);
        window.location.replace('/')
    }

    const onLogin = (data) => {
        setManager(data);
    }

    const update = async (values) => {
        const response = await updateManager({ params: values });
        if (!response.error) {
            setProfile({...profile, ...values});
        }

        return response;
    }

    return (<ManagerContext.Provider value={{
        manager, profile, logout, initializing, login: onLogin, update, profileLoading
    }}>
        {initializing ? <FullPageSpinner message='' /> : children}
    </ManagerContext.Provider>);
}

