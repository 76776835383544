import React, { useState, useRef, useCallback, useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  ExportPanel,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { range } from 'lodash';
import AccessibleIcon from '@material-ui/icons/Accessible';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import className from 'classnames';
import { makeStyles, IconButton, Typography, CircularProgress } from '@material-ui/core';
import { grey, orange } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveIcon from '@material-ui/icons/Remove';
import { useSendNotification, usePollBooking, useSetCheckedIn } from '../../hooks/use-fetch';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import saveAs from 'file-saver';

export const BookingContext = React.createContext({
  loading: false,
  bookings: [],
  arrived: 0,
  notified: 0,
  needAssistance: 0,
  entered: 0,
  total: 0
});

export const BookingProvider = (props) => {
  const booking = usePollBooking();

  return (
    <BookingContext.Provider value={{ ...booking }}>
      {props.children}
    </BookingContext.Provider>
  );
}


const styles = theme => ({
  count: {
    margin: theme.spacing(0, 1),
  },
});

const TableHeaderContentBase = ({
  column, children, classes, ...restProps
}) => {
  return (
    <BookingContext.Consumer>
      {
        (bookingStatus) =>
          (<TableHeaderRow.Content
            column={column}
            {...restProps}
          >
            {children}
            {['entered', 'arrived', 'notified'].includes(column.name) ? (
              <Typography className={classes.count}>{bookingStatus[column.name]}</Typography>
            ) : null}
          </TableHeaderRow.Content>)
      }
    </BookingContext.Consumer>
  );
}

export const TableHeaderContent = withStyles(styles, { name: 'TableHeaderContent' })(TableHeaderContentBase);


const CountDisplay = ({ value, needAssistance, ...rest }) => {
  return (
    <VirtualTable.Cell {...rest} >
      {
        range(0, value).map(i => {
          return needAssistance ? <AccessibleIcon key={i} /> : <AccessibilityIcon key={i} />
        })
      }
    </VirtualTable.Cell>
  );
}
const useNotifiedClasses = makeStyles(theme => ({
  notified: {
    color: `${theme.palette.primary.main} !important`
  },
  default: {
    color: grey[500]
  },
  executing: {
    color: `${orange[500]} !important`
  }
}));
const NotifyDisplay = ({ notified, ...rest }) => {
  const classes = useNotifiedClasses();
  const [, sendNotification] = useSendNotification(rest.row.id);
  const booking = useContext(BookingContext);
  const [executing, setExecuting] = React.useState(false);

  const notify = async () => {
    setExecuting(true);
    await sendNotification();
    await booking.load();
    setExecuting(false);
  }
  return (
    <VirtualTable.Cell {...rest} >
      <IconButton onClick={notify}>
        {
          executing ?
            <CircularProgress color='secondary' size={20} />
            :
            <NotificationsActiveIcon className={className(classes.default,
              {
                [classes.notified]: notified,
              })} />
        }
      </IconButton>
    </VirtualTable.Cell>
  );
}

const useWaitingClasses = makeStyles(theme => ({
  waiting: {
    color: '#00B8D4 !important'
  },
  default: {
    color: grey[500]
  }
}));

const WaitingDisplay = ({ arrived, ...rest }) => {
  const classes = useWaitingClasses();
  return (
    <VirtualTable.Cell {...rest}>
      {
        arrived ?

          <DriveEtaIcon className={className(classes.default, { [classes.waiting]: arrived })} />
          :
          <RemoveIcon />
      }
    </VirtualTable.Cell>
  );
}

const useEnteredClasses = makeStyles(theme => ({
  entered: {
    color: '#3FC126 !important'
  },
  default: {
    color: grey[500]
  }
}));

const EnteredDisplay = ({ entered, ...rest }) => {
  const classes = useEnteredClasses();
  const [executing, setExecuting] = React.useState(false);
  const [, setCheckedIn] = useSetCheckedIn(rest.row.id);
  const booking = useContext(BookingContext);

  return (
    <VirtualTable.Cell {...rest}>
      <IconButton onClick={async () => {
        setExecuting(true);
        await setCheckedIn();
        await booking.load();
        setExecuting(false);
      }}>
        {
          executing ?
            <CircularProgress size={20} color='primary' />
            :
            <CheckCircleIcon className={className(classes.default, { [classes.entered]: entered })} />
        }
      </IconButton>
    </VirtualTable.Cell>
  );
}

const Cell = (props) => {
  const { column } = props;
  if (column.name === 'count') {
    return <CountDisplay {...props} value={props.row.count} needAssistance={props.row.needAssistance} />;
  } else if (column.name === 'arrived') {
    return <WaitingDisplay {...props} arrived={props.row.arrived} />;
  } else if (column.name === 'notified') {
    return <NotifyDisplay {...props} notified={props.row.notified} />;
  } else if (column.name === 'entered') {
    return <EnteredDisplay {...props} entered={props.row.entered} />;
  }
  return <VirtualTable.Cell {...props} />;
};
export default ({ event }) => {
  const [columns] = useState([
    { name: 'name', title: 'Congregants' },
    { name: 'count', title: 'Group' },
    { name: 'arrived', title: 'Waiting' },
    { name: 'notified', title: 'Notify' },
    { name: 'entered', title: 'Entered' },
  ]);
  const exporterRef = useRef(null);

  const onSave = (workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${event.name} - ${moment(event.startTime).format('dddd MMMM DD, LT')}.xlsx`);
    });
  };

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  return (

    <BookingContext.Consumer>
      {
        ({ bookings }) =>
          (
            <>
              <Grid
                rootComponent={Paper}
                rows={bookings}

                columns={columns}
              >
                <VirtualTable cellComponent={Cell}
                  columnExtensions={[
                    { columnName: 'name' },
                    { columnName: 'count' },
                    { columnName: 'arrived' },
                    { columnName: 'notified' },
                    { columnName: 'entered' },
                  ]}
                />
                <TableHeaderRow contentComponent={TableHeaderContent} />
                {bookings.length > 0 && <Toolbar />}
                {!!bookings.length && <ExportPanel startExport={startExport} />}
              </Grid>
              <GridExporter
                ref={exporterRef}
                rows={bookings}
                columns={columns}
                onSave={onSave}
              />
            </>)
      }
    </BookingContext.Consumer>
  );
};