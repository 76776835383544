import * as React from 'react';
import Header, { AboutButton, CalendarButton, ReservationsButton, ProfileButton, SignOutButton } from './Header';
import { CurrentUserContext } from '../context/CurrentUserContext';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';

export const NavBar = ({ context, blue }) => {
    const history = useHistory();
    const auth = useAuth();

    const isActive = (candidate) => candidate === context;
    return (
        <CurrentUserContext.Consumer>
            {
                ({ user }) => {

                    return (<Header blue={blue}>
                        {!!user && <AboutButton active={isActive('about')} />}
                        {!!user && <CalendarButton active={isActive('calendar')} />}
                        {!!user && <ReservationsButton active={isActive('reservations')} />}
                        {!!user && <ProfileButton onClick={() => history.push('/profile')} active={isActive('profile')} />}
                        {!!user ? <SignOutButton onClick={() => auth.logout('customer')} /> :
                            <Button color="primary" variant="outlined" href="/login">Login</Button>}
                    </Header>)
                }
            }
        </CurrentUserContext.Consumer>
    );
}