import * as React from 'react';
import {
    Dialog, DialogContent, CircularProgress, Snackbar,
    Typography, DialogActions, Button
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import { usePollReservations, useDeleteBooking, useSetArrived, useGetVerifiedStoresInfo } from '../hooks/use-fetch';
import { setHOW } from '../helpers/localcache';

const ConfirmCancelDialog = ({ open, onClose, onConfirm }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            disableBackdropClick
        >
            <DialogContent>
                <Typography variant="h4">
                    Are you sure you want to cancel this reservation?
          </Typography>
                <Typography variant="h6">This action is permanent.</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={onConfirm}>
                    Yes, Cancel
          </Button>
                <Button color="primary" onClick={onClose}>
                    No, Don't Cancel
          </Button>
            </DialogActions>
        </Dialog>
    );
}

export const ReservationsContext = React.createContext(
    {
        initializing: true,
        reservations: [],
        how: { loaded: false, data: undefined },
        loading: true,
        notifyArrived: (id) => { },
        cancel: (id) => { }
    });

export const ReservationsProvider = ({ children, bookings }) => {
    const [snackbar, setSnackbar] = React.useState({});
    const polledReservations = usePollReservations();
    const [loading, setLoading] = React.useState(false);
    const [initializing, setInitializing] = React.useState(true);
    const [reservations, setReservations] = React.useState([]);
    const [, deleteBooking] = useDeleteBooking()
    const [, getVerifiedStoresInfo] = useGetVerifiedStoresInfo()
    const [, setArrived] = useSetArrived();
    const [deleteConfirm, setDeleteConfirm] = React.useState({});
    const [how, setHow] = React.useState({ loaded: false, data: undefined });

    React.useEffect(() => {
        setReservations(polledReservations.reservations);
        const loadHow = async (profile) => {
            const { error, data } = await getVerifiedStoresInfo({ url: `event/storeinfo/${profile}` });
            if (!error) {
                setHow({ loaded: true, data });
            }
        }

        if (!polledReservations.loading && initializing) {
            setInitializing(false);
        }

        if (polledReservations.reservations && polledReservations.reservations.length > 0 && !how.loaded) {
            setHow({ ...how, loaded: true })
            setHOW(polledReservations.reservations[0].profile_name);
            loadHow(polledReservations.reservations[0].profile_name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [polledReservations, getVerifiedStoresInfo, how.loaded, initializing]);

    const cancel = async (id) => {
        setDeleteConfirm({
            open: true,
            onClose: setDeleteConfirm({}),
            onConfirm: async () => {
                setDeleteConfirm({});
                setLoading(true)
                const response = await deleteBooking({ params: { booking_id: id } });
                if (!response.error) {
                    await polledReservations.load();
                    setLoading(false);
                    setSnackbar({ open: true, message: 'Successfully canceled reservation.', severity: 'success' })
                } else {
                    setLoading(false);
                    setSnackbar({ open: true, message: 'Failed to cancel reservation.', severity: 'error' })
                }
            }
        })
    }

    const notifyArrived = async (id) => {
        setLoading(true)
        const response = await setArrived({ url: `eventbooking/${id}/setArrived` });
        if (!response.error) {
            await polledReservations.load();
            setLoading(false);
            setSnackbar({ open: true, message: 'Notification sent.', severity: 'success' })
        } else {
            setLoading(false);
            setSnackbar({ open: true, message: 'Failed to notify. Please, try again', severity: 'warn' })
        }
    }

    const closeSnack = () => setSnackbar({});

    return (
        <ReservationsContext.Provider
            value={{
                loading,
                reservations,
                cancel,
                notifyArrived,
                how,
                initializing
            }}
        >
            <Snackbar
                open={snackbar.open}
                autoHideDuration={5000}
                onClose={closeSnack}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={closeSnack}
                    severity={snackbar.severity}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Dialog open={loading} onClose={() => setLoading(false)}>
                <DialogContent style={{ minWidth: 150, display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                    <CircularProgress />
                </DialogContent>
            </Dialog>
            <ConfirmCancelDialog open={!!deleteConfirm.open} onClose={() => setDeleteConfirm({})} onConfirm={deleteConfirm.onConfirm} />
            {children}
        </ReservationsContext.Provider>
    );
}
