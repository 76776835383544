import * as React from 'react';
import { CurrentUserContext } from "../../context/CurrentUserContext";
import { Button, Tooltip } from '@material-ui/core';
import { noop } from 'lodash';

export const ReserveButton = ({ onClick, type, disabled, label }) => {
    type = type || 'button';

    return (
        <CurrentUserContext.Consumer>
            {({ user }) => {

                if (user) {
                    return <Button
                        type={type}
                        onClick={type === 'button' ? onClick : noop}
                        variant='contained'
                        color='primary'
                        size='small'
                        disabled={disabled}
                    >
                        {label || 'RESERVE'}
                    </Button>
                }

                return <Tooltip title='You need to login to make a reservation'>
                    <span>

                        <Button
                            type={type}
                            onClick={type === 'button' ? onClick : noop}
                            variant='contained'
                            color='primary'
                            size='small'
                            disabled
                        >
                            {label || 'RESERVE'}
                        </Button>
                    </span>
                </Tooltip>

            }}
        </CurrentUserContext.Consumer>
    );
}