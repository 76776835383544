import React from "react";
import {
  Store as StoreIcon,
  Group as GroupIcon,
  PhoneAndroid as PhoneIcon,
  DirectionsWalk as DirectionsWalkIcon,
} from "@material-ui/icons";
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  Box,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  steps: {
    padding: "45px 0",
    minHeight: "300px",
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.light,
  },
  textCenter: {
    textAlign: "center",
    marginBottom: "28px",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    "& span": {
      whiteSpace: "nowrap",
    },
  },
  bold: {
    fontWeight: "bold",
  },
  icon: {
    color: "#ffffff",
    position: "absolute",
    left: "-45px",
  },
  container: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  gridItem: {
    "&.MuiGrid-item": {
      paddingLeft: 80,
    },
  },
}));

export default function StoreSteps() {
  const classes = useStyles();

  return (
    <section className={classes.steps}>
      <Container className={classes.container}>
        <Typography variant="h4" className={classes.textCenter}>
          How it works
        </Typography>
        <Grid spacing={10} container>
          <Step
            classes={classes}
            icon={<StoreIcon fontSize="large" className={classes.icon} />}
            title="1. Create store profile"
            description="Name, address, upload a logo, list your open hours"
          />
          <Step
            classes={classes}
            icon={<GroupIcon fontSize="large" className={classes.icon} />}
            title="2. Set capacity"
            description="Configure how many customers can shop at any (or specified) time"
          />
          <Step
            classes={classes}
            icon={<PhoneIcon fontSize="large" className={classes.icon} />}
            title="3. Manage at the door"
            description="Customers show a booking code on their mobile – you check them in/out"
          />
          <Step
            classes={classes}
            icon={
              <DirectionsWalkIcon fontSize="large" className={classes.icon} />
            }
            title="4. Track activity"
            description="View ins/outs in realtime and adjust capacity accordingly"
          />
        </Grid>
      </Container>
    </section>
  );
}

const Step = ({ icon, title, description, classes }) => (
  <Grid item xs={12} md={6} lg={3} className={classes.gridItem}>
    <div className={classes.flex}>
      {icon}
      <Typography variant="h5">
        <Box component="span" fontWeight="fontWeightLight">
          {title}
        </Box>
      </Typography>
    </div>
    <Typography variant="h6" className={classes.bold}>
      {description}
    </Typography>
  </Grid>
);
