import { useState, useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import useMount from "./use-mount";
import { useLocation } from 'react-router-dom';
import moment from 'moment';

export const MANAGER_BASE_URL = !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === "development" ?
  "https://sdmkl0d6ic.execute-api.us-west-2.amazonaws.com/dev" :
  process.env.REACT_APP_ENV === "staging" ?
    "https://7n560daudb.execute-api.us-west-2.amazonaws.com/staging" :
    "https://fmm87keaf8.execute-api.us-west-2.amazonaws.com/prod";
const STORE_BASE_URL = !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === "development" ?
  "https://tphgbxlqde.execute-api.us-west-2.amazonaws.com/dev" :
  process.env.REACT_APP_ENV === "staging" ?
    "https://ljmv3fvkkc.execute-api.us-west-2.amazonaws.com/staging" :
    "https://qi4pv5pc9c.execute-api.us-west-2.amazonaws.com/prod";
const STORE_SLOTS_BASE_URL = !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === "development" ?
  "https://pbfzkvcvml.execute-api.us-west-2.amazonaws.com/dev" :
  process.env.REACT_APP_ENV === "staging" ?
    "https://k6k0nlb11g.execute-api.us-west-2.amazonaws.com/staging" :
    "https://1iljewhzbc.execute-api.us-west-2.amazonaws.com/prod";
const PAYMENT_BASE_URL = !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === "development" ?
  "https://j3zxpw0jg5.execute-api.us-west-2.amazonaws.com/dev" :
  process.env.REACT_APP_ENV === "staging" ?
    "https://6mgsoflpg6.execute-api.us-west-2.amazonaws.com/staging" :
    "https://ha0t7pk3sl.execute-api.us-west-2.amazonaws.com/prod";
export const CUSTOMER_BASE_URL = !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === "development" ?
  "https://9btj6e8992.execute-api.us-west-2.amazonaws.com/dev" :
  process.env.REACT_APP_ENV === "staging" ?
    "https://370ij6w0y8.execute-api.us-west-2.amazonaws.com/staging" :
    "https://h76huxy595.execute-api.us-west-2.amazonaws.com/prod";
export const BOOKINGS_BASE_URL = !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === "development" ?
  "https://tmf9wmpgc4.execute-api.us-west-2.amazonaws.com/dev" :
  process.env.REACT_APP_ENV === "staging" ?
    "https://1lwmuaylv0.execute-api.us-west-2.amazonaws.com/staging" :
    "https://dxu5atdyt1.execute-api.us-west-2.amazonaws.com/prod";
const MARKETING_BASE_URL = !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === "development" ?
  "https://efujjxl6o8.execute-api.us-west-2.amazonaws.com/dev" :
  process.env.REACT_APP_ENV === "staging" ?
    "https://ct36y2bqsd.execute-api.us-west-2.amazonaws.com/staging" :
    "https://02y9qwvk02.execute-api.us-west-2.amazonaws.com/prod";
export const EVENTS_BASE_URL = !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === "development" ?
  "https://l2ai6ur3sb.execute-api.us-west-2.amazonaws.com/dev" :
  process.env.REACT_APP_ENV === "staging" ?
    "https://d0lui7bsu3.execute-api.us-west-2.amazonaws.com/staging" :
    "https://wcfya7qo02.execute-api.us-west-2.amazonaws.com/prod";
export const EVENT_BOOKINGS_BASE_URL = !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === "development" ?
  "https://wy7bprhnue.execute-api.us-west-2.amazonaws.com/dev" :
  process.env.REACT_APP_ENV === "staging" ?
    "https://5xwree2qp2.execute-api.us-west-2.amazonaws.com/staging" :
    "https://99r5fqm40j.execute-api.us-west-2.amazonaws.com/prod";

export const API_VERSION = "v1";

export default function useFetch({
  headers,
  method = "GET",
  url,
  manual,
  params,
  BASE_URL
}) {
  let user;
  const context = useContext(UserContext);
  if (context) {
    user = context[0];
  }
  const [callState, setCallState] = useState({
    data: null,
    loading: false,
    error: null,
  });
  const controller = new AbortController();
  const signal = controller.signal;

  const setError = (error) => {
    setCallState({ ...callState, error });
  };

  const setData = (data) => {
    setCallState({ ...callState, data });
  };

  const setLoading = (loading) => {
    setCallState({ ...callState, loading });
  };

  const execute = async (overrides) => {
    setLoading(true);
    const paramsToUse =
      overrides && overrides.params ? overrides.params : params;
    let authorizationHeaders =
      overrides && overrides.headers ? overrides.headers : headers;
    const callUrl = new URL(
      `${BASE_URL}/${API_VERSION}/${
      overrides && overrides.url ? overrides.url : url
      }`
    );

    if (user) {
      authorizationHeaders = {
        ...authorizationHeaders,
        AccessToken: user.AccessToken || localStorage.getItem('AccessToken'),
        RefreshToken: user.RefreshToken || localStorage.getItem('RefreshToken'),
        username: user.username || localStorage.getItem('username'),
      };
    } else {
      authorizationHeaders = {
        ...authorizationHeaders,
        AccessToken: localStorage.getItem('AccessToken'),
        RefreshToken: localStorage.getItem('RefreshToken'),
        username: localStorage.getItem('username')
      };
    }

    if (overrides && overrides.formData) {
      authorizationHeaders = {
        ...authorizationHeaders,
        "Content-Type": "multipart/form-data",
      };
    }

    try {
      const blob = await fetch(callUrl, {
        headers: authorizationHeaders,
        manual,
        method,
        body:
          overrides && overrides.formData
            ? overrides.formData
            : JSON.stringify(paramsToUse),
        signal,
      }).catch((err) => {
        setLoading(false);
        setError(err);
        return { loading: false, data: null, error: err };
      });

      let res;
      if (blob.json) {
        res = await blob.json();
      } else {
        res = blob.error ? blob.error : "Error";
      }

      if (blob.ok) {
        setData(res);
        return { loading: false, data: res, error: null };
      } else {
        setError(res);
        return { loading: false, data: null, error: res };
      }
    } catch (err) {
      if (err.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        setError(err);
        return { loading: false, data: null, error: err };
      }
    }
  };

  useMount(
    () => {
      if (!manual) {
        execute();
      }
    },
    () => {
      controller.abort();
    }
  );

  return [callState, execute, controller];
}

function useStoresFetch(obj) {
  return useFetch({ ...obj, BASE_URL: STORE_BASE_URL })
}

function useCustomersFetch(obj) {
  return useFetch({ ...obj, BASE_URL: CUSTOMER_BASE_URL })
}

function useManagersFetch(obj) {
  return useFetch({ ...obj, BASE_URL: MANAGER_BASE_URL })
}

function useStoreSlotsFetch(obj) {
  return useFetch({ ...obj, BASE_URL: STORE_SLOTS_BASE_URL })
}

function usePaymentsFetch(obj) {
  return useFetch({ ...obj, BASE_URL: PAYMENT_BASE_URL })
}

function useBookingsFetch(obj) {
  return useFetch({ ...obj, BASE_URL: BOOKINGS_BASE_URL })
}

function useEventBookingsFetch(obj) {
  return useFetch({ ...obj, BASE_URL: EVENT_BOOKINGS_BASE_URL })
}

function useMarketingFetch(obj) {
  return useFetch({ ...obj, BASE_URL: MARKETING_BASE_URL })
}

function useEventsFetch(obj) {
  return useFetch({ ...obj, BASE_URL: EVENTS_BASE_URL })
}

/* MANAGER ENDPOINTS */

export function useCreateManager() {
  return useManagersFetch({
    method: "POST",
    url: `manager`,
    manual: true,
  });
}

export function useLoginManager() {
  return useManagersFetch({
    method: "POST",
    url: `manager/login`,
    manual: true,
  });
}

export function useGetManager() {
  return useManagersFetch({
    url: `manager`,
    method: "GET",
    manual: false,
  });
}

export function useUpdateManager() {
  return useManagersFetch({
    url: `manager`,
    method: "PATCH",
    manual: true,
  });
}

export function useForgotPassword(email) {
  return useManagersFetch({
    url: `manager/reset_password/store/${email}`,
    method: "GET",
    manual: true,
  });
}

export function useCustomerForgotPassword(email) {
  return useCustomersFetch({
    url: `customer/reset_password/customer/${email}`,
    method: "GET",
    manual: true,
  });
}

export function useChangePassword() {
  return useManagersFetch({
    url: `manager/change_password`,
    method: "POST",
    manual: true,
  });
}

export function useCustomerChangePassword() {
  return useCustomersFetch({
    url: `customer/change_password`,
    method: "POST",
    manual: true,
  });
}

export function useManagerForgotPassword(email) {
  return useManagersFetch({
    url: `manager/reset_password/store/${email}`,
    method: "GET",
    manual: true,
  });
}

export function useCustomerResetPassword() {
  return useCustomersFetch({
    url: `customer/change_password`,
    method: "POST",
    manual: true,
  });
}

export function useManagerResetPassword() {
  return useCustomersFetch({
    url: `manager/change_password`,
    method: "POST",
    manual: true,
  });
}

/* STORE SLOTS ENDPOINTS */

export function useGetStoreSlots(id) {
  return useStoreSlotsFetch({
    url: `store_slots/${id}`,
    method: "GET",
    manual: false,
  });
}

export function useUpsertStoreSlots() {
  return useStoreSlotsFetch({
    url: `store_slots`,
    method: "POST",
    manual: true,
  });
}

/* PAYMENT ENDPOINTS */

export function useGetClientSecret() {
  return usePaymentsFetch({
    url: `payment/customer`,
    method: "POST",
    manual: true,
  });
}

export function useGetPrivateClientSecret() {
  return usePaymentsFetch({
    url: `payment/customer`,
    method: "GET",
    manual: true,
  });
}

export function useCreateSubscription() {
  return usePaymentsFetch({
    url: `payment/subscription`,
    method: "POST",
    manual: true,
  });
}

export function useDeleteSubscription() {
  return usePaymentsFetch({
    url: `payment/subscription`,
    method: "DELETE",
    manual: true,
  });
}

export function useGetAccountDetails() {
  return usePaymentsFetch({
    url: `payment/subscription`,
    method: "GET",
    manual: false,
  });
}

/* CUSTOMER ENDPOINTS */

export function useCreateCustomer() {
  return useCustomersFetch({
    url: `customer`,
    method: "POST",
    manual: true,
  });
}

export function useGetCustomer() {
  return useCustomersFetch({
    url: `customer`,
    method: "GET",
  });
}

export function useUpdateCustomer() {
  return useCustomersFetch({
    url: `customer`,
    method: "PATCH",
    manual: true,
  });
}

export function useLoginCustomer() {
  return useCustomersFetch({
    url: `customer/login`,
    method: "POST",
    manual: true,
  });
}

export function useCheckSessionCustomer() {
  return useCustomersFetch({
    url: `customer/check`,
    method: "GET",
    manual: true,
  });
}

export function useSendEmailVerification() {
  return useCustomersFetch({
    url: `customer/send/email`,
    method: "POST",
    manual: true,
  })
}

export function useVerifyEmail() {
  return useCustomersFetch({
    url: `customer/verify/email`,
    method: "POST",
    manual: true
  })
}

export function useSendPhoneVerification() {
  return useCustomersFetch({
    url: `customer/send/phone`,
    method: "POST",
    manual: true
  })
}

export function useVerifyPhone() {
  return useCustomersFetch({
    url: `customer/verify/phone`,
    method: "POST",
    manual: true
  })
}

/* STORE ENDPOINTS */

export function useGetAllStoresPerManager() {
  return useStoresFetch({
    url: `store/manager`,
    method: "GET",
    manual: false,
  });
}

export function useUpdateStore() {
  return useStoresFetch({
    method: "PATCH",
    url: `store`,
    manual: true,
  });
}

export function useDeleteStore() {
  return useStoresFetch({
    url: `store`,
    method: "DELETE",
    manual: true,
  });
}

export function useGetStoresByIds() {
  return useStoresFetch({
    url: `store/ids`,
    method: "POST",
    manual: true,
  });
}

export function useGetVerifiedStoresByIds() {
  return useStoresFetch({
    url: `store/ids/verified`,
    method: "POST",
    manual: true,
  });
}

export function useGetAllTimeSlotsPerStore() {
  return useStoresFetch({
    url: `timeslot`,
    method: "POST",
    manual: true,
  });
}

export function useSubmitImage() {
  return useStoresFetch({
    url: `store/logo`,
    method: "POST",
    manual: true,
  });
}

export function useGetVerifiedStores() {
  return useStoresFetch({
    url: `store/verified`,
    method: "GET",
    manual: false,
  });
}

export function useGetStoresByLocation() {
  return useStoresFetch({
    url: `store/location`,
    method: "POST",
    manual: true,
  });
}

export function useCreateStore() {
  return useStoresFetch({
    method: "POST",
    url: `store`,
    manual: true,
  });
}

export function useGetBookingCustomers(start) {
  return useStoresFetch({
    method: "GET",
    url: `store/bookings/${start}`,
    manual: true
  })
}

/* BOOKINGS ENDPOINTS */
export function useCreateBooking() {
  return useBookingsFetch({
    method: "POST",
    url: `booking`,
    manual: true
  });
}

export function useGetCalendar(store, start, end) {
  return useBookingsFetch({
    method: "GET",
    url: `booking/${store}/${start}/${end}`,
    manual: false
  })
}

export function useGetUpcoming() {
  return useBookingsFetch({
    method: "GET",
    url: `booking/upcoming`,
    manual: false
  })
}

export function useDeleteBooking() {
  return useBookingsFetch({
    method: 'DELETE',
    url: `booking`,
    manual: true
  })
}

/* EVENTS ENDPOINTS */
export function useCreateEvent() {
  return useEventsFetch({
    method: "POST",
    url: `event`,
    manual: true
  });
}

export function useGetEvent() {
  return useEventsFetch({
    method: "GET",
    url: `event`,
    manual: true
  });
}

export function useGetEventBookings() {
  return useEventsFetch({
    method: "GET",
    url: `event`,
    manual: true
  });
}

export function useEventsCalendar(storeId) {
  return useEventsFetch({
    method: "GET",
    url: `event/store/${storeId}`,
    manual: true
  })
}

export function useGetVerifiedStoresInfo(profileName) {
  return useEventsFetch({
    url: `event/storeinfo/${profileName}`,
    method: "GET",
    manual: true,
  });
}

export function useDeleteEvent() {
  return useEventsFetch({
    method: 'DELETE',
    url: `event`,
    manual: true
  })
}

export function useUpdateEvent() {
  return useEventsFetch({
    method: 'PUT',
    url: `event`,
    manual: true
  })
}
export function useCheckIn() {
  return useBookingsFetch({
    method: 'POST',
    url: `booking/checkin`,
    manual: true
  })
}

export function useCheckEventBookingsCount() {
  return useEventsFetch({
    method: "GET",
    url: `event`,
    manual: true
  });
}

/* EVENT BOOKINGS ENDPOINTS */
export function useCreateEventBooking() {
  return useEventBookingsFetch({
    method: "POST",
    url: `eventbooking`,
    manual: true
  });
}


export function useCheckOut() {
  return useBookingsFetch({
    method: 'POST',
    url: `booking/checkout`,
    manual: true
  })
}

/* MARKETING ENDPOINTS */

export function useMarketing() {
  return useMarketingFetch({
    url: `marketing`,
    method: "POST",
    manual: true,
  });
}

export const usePollBooking = () => {
  const [, getEventBooking] = useGetEventBookings();
  const [bookings, setBookings] = useState([]);
  const [needAssistance, setNeedAssistance] = useState(0);
  const [arrived, setArrived] = useState(0);
  const [notified, setNotified] = useState(0);
  const [entered, setEntered] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const load = async () => {
    setLoading(true);

    const response = await getEventBooking({ url: location.pathname.replace('/', '') });
    if (response && !response.error && response.data) {
      const data = response.data.map(booking => ({
        ...booking,
        id: booking.booking_id,
        entered: booking.checked_in,
        needAssistance: booking.need_assistance,
        count: booking.head_count,
        eventId: booking.event_id,
        name: `${booking.given_name}`
      }));

      setArrived(data.filter(booking => booking.arrived === true).length);
      setNotified(data.filter(booking => booking.notified === true).length);
      setNeedAssistance(data.filter(booking => booking.needAssistance === true).length);
      setEntered(data.filter(booking => booking.entered === true).length);
      setTotal(data.reduce((acc, cur) => acc + cur.count, 0))
      setBookings(data);
    }

    setLoading(false);
  };
  useEffect(() => {

    load();
    const interval = setInterval(() => {
      if (getEventBooking && location.pathname) {
        load();
      }
    }, 30000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    bookings,
    arrived,
    notified,
    needAssistance,
    entered,
    total,
    load
  }
}

export const usePollReservations = () => {
  const [, getUpcoming] = useGetUpcoming()
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);

  const load = async () => {
    setLoading(true);
    const { error, data } = await getUpcoming();
    if (!error && data) {
      const items = (data.bookings || []).map(item => ({
        ...item, 
        start_time: moment(item.start_time, 'HH:mm:ss').format('HH:mma'),
        end_time: moment(item.end_time, 'HH:mm:ss').format('HH:mma'),
        canNotify: moment(`${item.date} ${item.start_time}`, 'ddd MMM D HH:mm:ss').diff(moment(), 'hour') <= 1
      }))
      setReservations(items);
    }

    setLoading(false);
  };

  useEffect(() => {

    load();
    const interval = setInterval(() => {
      if (getUpcoming) {
        load();
      }
    }, 30000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    reservations,
    load
  }
}

export function useSendNotification(bookingId) {
  return useEventBookingsFetch({
    method: "PATCH",
    url: `eventbooking/${bookingId}/setNotified`,
    manual: true
  });
}

export function useSetArrived(bookingId) {
  return useEventBookingsFetch({
    method: "PATCH",
    url: `eventbooking/${bookingId}/setArrived`,
    manual: true
  });
}

export function useSetCheckedIn(bookingId) {
  return useEventBookingsFetch({
    method: "PATCH",
    url: `eventbooking/${bookingId}/setCheckedIn`,
    manual: true
  });
}
