import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import {
  Typography,
  makeStyles,
  Snackbar,
  Paper,
} from "@material-ui/core";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AccountTab from "../components/StoreDetails/AccountTab";
import ProfileTab from "../components/StoreDetails/ProfileTab";
import FullPageSpinner from "../components/FullPageSpinner";
import {
  useGetAllStoresPerManager,
  useGetAccountDetails,
} from "../hooks/use-fetch";
import Alert from "@material-ui/lab/Alert";
import PerfectlyDistancedScheduler from '../components/Scheduler/scheduler';
import { ChurchProfile } from '../components/Church/profile';
import { SchedulerStoreProvider } from "../context/SchedulerContext";
import { HoWNavBar } from "../components/HoWNavbar";

const stripePromise = loadStripe(
  !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === "development"
    ? "pk_test_4zpggN8fEtWYqXirNkVVEVla00DvwwI0jq"
    : "pk_live_beL1S51JxMyOIyFGtzafI4rA00TGiNcwhi"
);


const useStyles = makeStyles((theme) => ({
  section: {
    "& header": {
      paddingTop: 45,
      backgroundColor: "#ffffff",
    },
    "& .tab-panel": {
      // minHeight: 975,
      height: "100%",
      backgroundColor: theme.palette.surface.lightBlue,
      paddingTop: 50,
      // paddingBottom: 50,
    },
    "& h4": {
      textAlign: "center",
      marginBottom: 16,
    },
  },
  tabContainer: {
    "& .MuiButtonBase-root.MuiTabs-scrollButtons": {
      color: theme.palette.primary.main,
    },
    "& .MuiTabs-flexContainer": {
      "@media(min-width: 900px)": {
        justifyContent: "center",
      },
    },
  },
}));


const useClasses = makeStyles(theme => ({
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#7FDCEB',
    fontSize: 48,
    height: 100
  }
}));

const TitleHeader = ({ title }) => {
  const classes = useClasses();
  return (<Paper className={classes.title} elevation={2} square>
    <Typography variant='h4'>{title}</Typography>
  </Paper>)
}

export default function StoreDetails() {
  const classes = useStyles();
  const [{ loading, data, error },] = useGetAllStoresPerManager();
  const [accountRes, refetchAccountDetails] = useGetAccountDetails();

  if (!loading && data && data.length < 1) {
    return <Redirect to="/error" />;
  }

  return (

    <Elements stripe={stripePromise}>
      <HoWNavBar />
      <section className={classes.section}>
        {loading ? (
          <FullPageSpinner message="" />
        ) : !error ? (
          <div>
            <Switch>
              <Route path='/space/details' exact>
                {data && data.length > 0 && (
                  <ChurchProfile church={data[0]} readonly={false} />
                )}
              </Route>
              <Route path='/space/details/about'>
                {data && data.length > 0 && (
                  <ChurchProfile church={data[0]} readonly={false} />
                )}
              </Route>
              <Route path='/space/details/calendar'>
                {data && data.length > 0 ?
                  <SchedulerStoreProvider store={data[0]}>
                    <TitleHeader title='Schedule' />
                    <PerfectlyDistancedScheduler store={data[0]} />
                  </SchedulerStoreProvider> : null
                }
              </Route>
              <Route path='/space/details/account'>
                <>
                  <TitleHeader title='Account Details' />
                  <AccountTab
                    data={accountRes.data}
                    loading={accountRes.loading}
                    refetch={refetchAccountDetails}
                  />
                </>
              </Route>
              <Route path='/space/details/profile'>
                <>
                  <TitleHeader title='Profile' />
                  <ProfileTab />
                </>
              </Route>
            </Switch>
            {!accountRes.loading && accountRes.data && (
              <Snackbar open={!accountRes.data.brand}>
                <Alert elevation={6} variant="filled" severity="error">
                  Your subscription is currently inactive, please update your
                  billing preferences and resume your subscription to list your
                  business on perfectlydistanced.com
                </Alert>
              </Snackbar>
            )}
          </div>
        ) : (
              <Redirect to="/" />
            )}
      </section>
    </Elements>
  );
}
