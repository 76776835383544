import * as React from 'react';
import {
    Container, Grid, Typography, List, ListItem, ListItemIcon, makeStyles} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import ScheduleIcon from '@material-ui/icons/Schedule';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';

const useClasses = makeStyles(theme => ({
    instructions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignContent: 'center',
        backgroundColor: '#7FDCEB',
    },
    step: {
        display: 'flex'
    },
    white: {
        color: '#ffffff !important'
    }
}));

export const Instructions = () => {
    const classes = useClasses();

    return (<Grid container spacing={2}>
        <Grid item xs={12} className={classes.instructions}>
            <Typography variant='h5'>Reserve your Entry Time Slot</Typography>
            <Container>

                <Grid container>
                    <Grid item xs={12} md={3} className={classes.step}>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <EventIcon className={classes.white} />
                                </ListItemIcon>
                            1. Check our calendar
                        </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <ScheduleIcon className={classes.white} />
                                </ListItemIcon>
                            2. Reserve entry time slot
                        </ListItem>
                        </List>

                    </Grid>
                    <Grid item xs={12} md={3}>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <PhoneAndroidIcon className={classes.white} />
                                </ListItemIcon>
                            3. Notify you have arrived
                        </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <DirectionsWalkIcon className={classes.white} />
                                </ListItemIcon>
                            4. Receive SMS to approach
                        </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    </Grid>);
}