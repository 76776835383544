import * as React from 'react';
import MuiTextField from '@material-ui/core/TextField';
import MuiCheckboxField from '@material-ui/core/Checkbox';
import MuiLabel from '@material-ui/core/FormControlLabel';
import { fieldToTextField, fieldToCheckbox } from 'formik-material-ui';
import MUIRichTextEditor from 'mui-rte'
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js'

export const FullWidthTextField = (props) => {
  return <MuiTextField {...fieldToTextField(props)} variant='outlined' fullWidth={true} />;
}


export const MultilineTextField = (props) => {
  return <MuiTextField {...fieldToTextField(props)} variant='outlined' fullWidth={true} multiline />;
}

export const RichTextEditor = (props) => {
  const defaultValue = React.useMemo(() => {

    let value = props.field.value;
    try {
      JSON.parse(value);
    } catch (error) {
      const contentHTML = convertFromHTML(value)
      const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
      value = JSON.stringify(convertToRaw(state));
    }
    return value;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <MUIRichTextEditor
    {...props}
    style={{border: '1px solid grey'}}
    toolbar={["title", "bold", "italic", "underline", "link", "numberList",
      "bulletList", "quote", "code", "clear",
      "strikethrough", "highlight"]}
    defaultValue={defaultValue} onChange={state => {
      props.form.setFieldValue('description', JSON.stringify(convertToRaw(state.getCurrentContent())))
    }} toolbarButtonSize='small' />;
}

export const CheckboxField = (props) => {

  return <MuiLabel
    control={<MuiCheckboxField {...fieldToCheckbox(props)} />}
    label={props.label}
  />;
}

