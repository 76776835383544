import React, { createContext, useState } from "react";

const UserContext = createContext();
const LocalStateProvider = UserContext.Provider;

function UserProvider({ children }) {
  const [user, setUser] = useState();

  return (
    <LocalStateProvider value={[user, setUser]}>{children}</LocalStateProvider>
  );
}

export { UserContext, UserProvider };
