import React from "react";
import {
  Store as StoreIcon,
  Event as EventIcon,
  PhoneAndroid as PhoneIcon,
  ShoppingCart as ShoppingCartIcon,
} from "@material-ui/icons";
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  Box,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  steps: {
    padding: "45px 0",
    minHeight: "300px",
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.light,
  },
  textCenter: {
    textAlign: "center",
    marginBottom: "28px",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    "& span": {
      whiteSpace: "nowrap",
    },
    "&.disabled": {
      "& h5, & svg": {
        color: theme.palette.text.disabled,
      },
    },
  },
  bold: {
    fontWeight: "bold",
    "&.disabled": {
      color: theme.palette.text.disabled,
    },
  },
  icon: {
    color: "#ffffff",
    position: "absolute",
    left: "-45px",
  },
  container: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  gridItem: {
    "&.MuiGrid-item": {
      paddingLeft: 80,
    },
  },
}));

export default function Steps({ step }) {
  const classes = useStyles();

  return (
    <section className={classes.steps}>
      <Container className={classes.container}>
        <Typography variant="h4" className={classes.textCenter}>
          {step ? "What happens next..." : "How it works (it's super simple)"}
        </Typography>
        <Grid spacing={10} container>
          <Step
            disabled={step >= 1}
            classes={classes}
            icon={<StoreIcon fontSize="large" className={classes.icon} />}
            title="1. Find a business"
            description="Select where you want to go"
          />
          <Step
            disabled={step >= 2}
            classes={classes}
            icon={<EventIcon fontSize="large" className={classes.icon} />}
            title="2. Reserve a time"
            description="Reserve a time slot (you’ll need to sign up or login to continue)"
          />
          <Step
            disabled={step >= 3}
            classes={classes}
            icon={<PhoneIcon fontSize="large" className={classes.icon} />}
            title="3. Receive confirmation"
            description="Receive SMS with confirmation code – present this at the door"
          />
          <Step
            disabled={step >= 4}
            classes={classes}
            icon={
              <ShoppingCartIcon fontSize="large" className={classes.icon} />
            }
            title="4. You're all set"
            description="Show your check out code at the door when you leave"
          />
        </Grid>
      </Container>
    </section>
  );
}

const Step = ({ icon, title, description, classes, disabled }) => (
  <Grid item xs={12} md={6} lg={3} className={classes.gridItem}>
    <div className={`${classes.flex} ${disabled ? "disabled" : ""}`}>
      {icon}
      <Typography variant="h5">
        <Box component="span" fontWeight="fontWeightLight">
          {title}
        </Box>
      </Typography>
    </div>
    <Typography
      variant="h6"
      className={`${classes.bold} ${disabled ? "disabled" : ""}`}
    >
      {description}
    </Typography>
  </Grid>
);
