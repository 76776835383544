import React from "react";
import { makeStyles, Container, Typography, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    padding: "85px 0",
    "& h3, & h5": {
      textAlign: "center",
      marginBottom: 35,
    },
  },
}));

export default function StoreTellCustomers() {
  const classes = useStyles();
  return (
    <section className={classes.section}>
      <Container>
        <Typography variant="h3">Tell your customers all about it!</Typography>
        <Typography variant="h5">
          <Box component="span" fontWeight="fontWeightLight">
            Perfectly Distanced is free for your customers. We want to help you
            get the word out so when you sign up you get access to promotional
            materials, including: posters for entrance doors; graphics for
            social media campaigns; stickers, etc.
          </Box>
        </Typography>
      </Container>
    </section>
  );
}
