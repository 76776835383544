import * as React from 'react';
import { Formik, Form, Field } from 'formik';
import { CurrentUserContext } from '../../context/CurrentUserContext';
import { Grid, Button, CircularProgress, Snackbar } from '@material-ui/core';
import * as Yup from 'yup';
import { FullWidthTextField } from '../../components/common/FormikFields';
import { useChangePassword, useCustomerChangePassword } from '../../hooks/use-fetch';
import { useQueryString } from '../../hooks/query';
import Alert from "@material-ui/lab/Alert";
import { customer } from '../../helpers/use-context';

export const ResetPasswordForm = () => {
  const [, changePassword] = useChangePassword();
  const [, changeCustomerPassword] = useCustomerChangePassword();
  const [snackbar, setSnackbar] = React.useState({})
  const query = useQueryString();
  const loginUrl = React.useMemo(() => {
    return query.userType !== customer ? '/store/login' : '/customer/login';
  }, [query]);

  const handlePasswordChange = async (values) => {
    const method = query.userType && query.userType === 'customer' ? changeCustomerPassword : changePassword
    const params = {
      password: values.password,
      confirmPassword: values.confirmPassword,
      email: query.email,
      guid: query.uid,
      userType: query.userType && query.userType === 'customer' ? query.userType : 'store'
    }

    const { loading, error } = await method({ params });

    if (!loading) {
      setSnackbar({
        open: true,
        severity: error ? "error" : "success",
        message: error ? error.errorMessage
          ? error.errorMessage
          : error.message : "Password updated",
      });
    }
  }

  return (
    <div>
      <CurrentUserContext.Consumer>
        {() => (< Formik
          initialValues={{
            password: '',
            confirmPassword: '',
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string().required('Password is required'),
            confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], "Passwords don't match").required('Confirm Password is required'),
          })}
          onSubmit={async (values) => await handlePasswordChange(values)}
        >
          {({ submitForm, isSubmitting, isValid, dirty }) => (

            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    component={FullWidthTextField}
                    name='password'
                    type='password'
                    label='New Password'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={FullWidthTextField}
                    name='confirmPassword'
                    type='password'
                    label='Confirm Password'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button disabled={!isValid} variant='contained' color='primary' type='submit' fullWidth>
                    Submit&nbsp; {isSubmitting && <CircularProgress size={20} />}
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant='text' color='primary' type='submit' href={loginUrl} fullWidth>
                    Login
                  </Button>
                </Grid>
              </Grid>
            </Form>)}
        </Formik>)

        }
      </CurrentUserContext.Consumer >

      <Snackbar autoHideDuration={6000} onClose={() => setSnackbar({})} open={snackbar.open}>
        <Alert onClose={() => setSnackbar({})} variant="filled" severity={snackbar.severity} elevation={6}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
