import { useContext } from "react"
import { ManagerContext } from "../context/ManagerContext"
import { CurrentUserContext } from "../context/CurrentUserContext";
import { UserContext } from "../context/UserContext";

export const useAuth = () => {
    const managerAuth = useContext(ManagerContext);
    const customerAuth = useContext(CurrentUserContext);
    const [, setUser] = useContext(UserContext);

    const logout = async (userType) => {
        setUser(undefined);
        setUser(null);
        window.localStorage.removeItem("keepMeLoggedIn")
        window.localStorage.clear()
        if (userType === 'customer') {
            return await customerAuth.logout();
        } else {
            await managerAuth.logout();
        }
    }

    return {
        initializing: managerAuth.profileLoading || customerAuth.profileLoading,
        authenticated: !!managerAuth.profile || !!customerAuth.profile,
        isCustomer: !!customerAuth.profile,
        isManager: !!managerAuth.profile,
        logout
    }
}