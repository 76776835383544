import React from "react";
import Header from "../components/Header";
import { Typography, makeStyles, Container } from "@material-ui/core";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  section: {
    minHeight: "calc(100vh - 164px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& h5, & h2": {
      textAlign: "center",
      marginTop: 30,
    },
  },
}));

export default function Verify() {
  const location = useLocation();
  const { email } = location.state;
  const classes = useStyles();

  return (
    <>
      <Header blue />
      <section className={classes.section}>
        <Container maxWidth="md">
          <Typography variant="h2">Sign up successful</Typography>
          <Typography variant="h5">
            Please verify the provided email: {email}
          </Typography>
        </Container>
      </section>
    </>
  );
}
