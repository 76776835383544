import { useState } from "react";

export default function useHandlePhone(defaultValue = "") {
  const [phone, setPhone] = useState(defaultValue);
  return [
    phone,
    function (e) {
      if (e.target.value.replace(/\s+/g, "").length > 10)
        return;

      let value = e.target.value;
      value = e.target.value.replace(/[^0-9+ ]+/g, "");

      setPhone(value);
    },
  ];
}
