import React from "react";
import { makeStyles, Card, CardContent, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.surface.lightBlue,
    height: "100%",
    "& .StripeElement": {
      width: "100%",
      padding: 16,
      backgroundColor: "#ffffff",
      borderRadius: 4,
      border: "1px solid transparent",
      "&.StripeElement--focus": {
        borderColor: theme.palette.secondary.main,
      },
    },
    "& h4": {
      marginBottom: 18,
    },
    "& p": {
      fontSize: "16px",
    },
    "& .MuiInputBase-root.MuiFilledInput-root": {
      marginBottom: 16,
      borderRadius: "4px",
      backgroundColor: "#ffffff",
      border: "1px solid transparent",
      "&:focus-within": {
        borderColor: theme.palette.secondary.main,
      },
    },
    "& .MuiInputBase-input.MuiFilledInput-input": {
      padding: "16px",
    },
    "& .MuiInputBase-root.MuiInput-root.MuiInputBase-fullWidth.MuiInput-fullWidth": {
      marginBottom: 4,
      borderRadius: "4px",
      backgroundColor: "#ffffff",
      "& .MuiSelect-root.MuiSelect-select": {
        padding: 16,
      },
    },
    "& .MuiFormControlLabel-labelPlacementBottom": {
      alignItems: "flex-start",
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
      marginBottom: 16,
      "& .MuiInputBase-formControl": {
        marginBottom: 4,
      },
    },
    "& .fs12": {
      "& .MuiFormControlLabel-label": {
        fontSize: "12px",
      },
    },
    "& .flex": {
      display: "flex",
      "& svg": {
        marginRight: 10,
      },
    },
    "& .items-center": {
      alignItems: "center",
    },
    "& .items-start": {
      alignItems: "start",
    },
    "& .helper-text": {
      fontSize: 12,
      color: theme.palette.text.medium,
      marginBottom: 16,
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
    },
    "& .mt0": {
      marginTop: 0,
    },
    "& .mb0": {
      marginBottom: 0,
      "& .MuiInputBase-root.MuiFilledInput-root": {
        marginBottom: 0,
      },
    },
    "& .mb16": {
      marginBottom: 16,
    },
  },
}));

export default function SignUpCard({ children, title }) {
  const classes = useStyles();
  return (
    <Card className={classes.card} elevation={0}>
      <CardContent>
        <Typography variant="h4">{title}</Typography>
        {children}
      </CardContent>
    </Card>
  );
}
