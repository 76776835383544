import * as React from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid, Button, CircularProgress, Snackbar, Typography } from '@material-ui/core';
import * as Yup from 'yup';
import { FullWidthTextField } from '../../components/common/FormikFields';
import Alert from "@material-ui/lab/Alert";
import { useCustomerForgotPassword, useManagerForgotPassword } from '../../hooks/use-fetch';
import { useRouteParam } from '../../hooks/query';
import { store } from '../../helpers/use-context';

export const ForgotPasswordForm = () => {
    const [, customerForgotPassword] = useCustomerForgotPassword();
    const [, managerForgotPassword] = useManagerForgotPassword();
    const [snackbar, setSnackbar] = React.useState({});
    const [showSuccess, setShowSuccess] = React.useState(false);
    const context = useRouteParam(params => params.context);
    const loginUrl = React.useMemo(() => {
        return context === store ? '/store/login' : '/customer/login';
    }, [context]);

    const sendLink = async (values) => {
        if (context !== store) {
            return await customerForgotPassword({
                url: `customer/reset_password/customer/${values.email}`,
            });
        }

        return  await managerForgotPassword({
            url: `manager/reset_password/store/${values.email}`,
        });
    }

    const handleForgotPassword = async (values) => {
        setShowSuccess(false);
        const { loading, error } = await sendLink(values);

        if (!loading && !error) {
            setShowSuccess(true);
        } else {
            setSnackbar({
                open: true,
                severity: "error",
                message: error.ErrorMessage
                    ? error.ErrorMessage
                    : error.message,
            });
        }
    }

    return (
        <>
            {showSuccess && <Alert variant="filled" severity='success' elevation={6} style={{ marginBottom: 15 }}>
                Please check your inbox and follow the link to reset your password
    </Alert>}
            < Formik
                initialValues={{
                    email: '',
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email().required('Email address is required'),
                })}
                onSubmit={async (values) => await handleForgotPassword(values)}
            >
                {({ isSubmitting, isValid }) => (

                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography>
                                    Please enter your account email address and submit to receive a password reset link
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={FullWidthTextField}
                                    name='email'
                                    type='email'
                                    label='Email Address'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button disabled={!isValid} variant='contained' color='primary' type='submit' fullWidth>
                                    Get Password Reset Link&nbsp; {isSubmitting && <CircularProgress size={20} />}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant='text' color='primary' href={loginUrl} fullWidth>
                                    Login
                                </Button>
                            </Grid>

                        </Grid>

                    </Form>)}

            </Formik>
            <Snackbar autoHideDuration={6000} onClose={() => setSnackbar({})} open={snackbar.open}>
                <Alert onClose={() => setSnackbar({})} variant="filled" severity={snackbar.severity} elevation={6}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
}